import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button, Skeleton } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  CloseRounded as CancelIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppSelectForm } from 'components/AppFormControl';
import {
  ErrorResponse,
  FilteredMeltSettingPresets,
  getMeltSettingPresets,
  MeltSetting,
} from 'api';
import { useSnackbar } from 'hooks';
import AppMeltSettingsForm from 'components/AppMeltSettingsForm';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (settings: MeltSetting) => void;
}

export const AppLoadPresetDialog: FC<Props> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activePreset, setActivePreset] = useState<string>('');

  const { data: presets, isLoading } = useQuery<
    FilteredMeltSettingPresets,
    AxiosError<ErrorResponse>
  >(['melt-setting-presets'], () => getMeltSettingPresets(), {
    keepPreviousData: true,
    staleTime: 0,
    onError: () => {
      enqueueSnackbar({
        key: `get_presets_fail_${Date.now()}`,
        message: t('get_presets_fail'),
        variant: 'error',
      });
    },
  });

  const presetSettings = useMemo<MeltSetting | undefined>(() => {
    const preset = presets?.values.find(({ id }) => id === activePreset);
    if (!preset) return undefined;
    return {
      pointSpreadAlgName: preset.pointSpreadAlgName,
      pointSpreadSettings: preset.pointSpreadSettings,
      dwellTimeAlgName: preset.dwellTimeAlgName,
      dwellTimeSettings: preset.dwellTimeSettings,
      meshSize: preset.meshSize,
      beamPower: preset.beamPower,
      shift: preset.shift,
      spotSize: preset.spotSize,
      seeds: preset.seeds,
    };
  }, [activePreset, presets?.values]);

  const handleClose = useCallback<() => void>(() => {
    setActivePreset('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback<() => void>(() => {
    if (presetSettings) onSubmit(presetSettings);
    handleClose();
  }, [handleClose, onSubmit, presetSettings]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('load_preset')}
      actions={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
            sx={{ flex: 1 }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubmitIcon />}
            onClick={handleSubmit}
            disabled={!activePreset}
            sx={{ flex: 2 }}
          >
            {t('apply')}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2}>
        {isLoading ? (
          <Skeleton sx={{ height: 50 }} />
        ) : (
          <AppSelectForm
            label={t('choose_preset')}
            helperText={t('choose_preset_helper')}
            value={activePreset}
            onChange={(value) => {
              setActivePreset(value as string);
            }}
            options={
              presets?.values.map(({ id, name, material }) => ({
                value: id,
                label: name,
                tooltip: t('details_material', { value: material }),
              })) ?? []
            }
          />
        )}
        {presetSettings && (
          <AppMeltSettingsForm values={presetSettings} disabled />
        )}
      </Stack>
    </AppDialog>
  );
};
