import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  EditRounded as EditIcon,
  ContentCopyRounded as DuplicateIcon,
  DeleteRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  CloseRounded as CancelIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import { DuplicatePresetDialog, DeletePresetDialog } from 'containers/Presets';
import AppDetailsDrawer from 'components/AppDetailsDrawer';
import AppMeltSettingsForm from 'components/AppMeltSettingsForm';
import ProjectMenuPanelGroup from 'components/ProjectMenuPanelGroup';
import { AppTextForm } from 'components/AppFormControl';
import {
  ErrorResponse,
  getMeltSettingPreset,
  MeltSetting,
  MeltSettingPresetWithEtag,
  UpdateMeltSettingPreset,
  updateMeltSettingPreset,
  UserDetailsWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';
import { checkWritePermission } from 'helpers';
// Redux
import { initialMeltSetting } from 'slices/meltSettingsSlice';

type PresetDetails = Pick<UpdateMeltSettingPreset, 'name' | 'material'>;
type PresetSettings = Omit<UpdateMeltSettingPreset, 'name' | 'material'>;

export const AppPresetDetails: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [{ rowId: presetId }, { deleteSearchParam }] = useSearchParams();
  const [details, setDetails] = useState<PresetDetails>({
    name: '',
    material: '',
  });
  const [values, setValues] = useState<PresetSettings>();
  const [inUpdateState, setInUpdateState] = useState(false);
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [validationError, setValidationError] = useState<
    (keyof UpdateMeltSettingPreset)[]
  >([]);
  const refs = useMemo<
    Record<keyof UpdateMeltSettingPreset, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      pointSpreadAlgName: createRef<HTMLInputElement>(),
      pointSpreadSettings: createRef<HTMLInputElement>(),
      dwellTimeAlgName: createRef<HTMLInputElement>(),
      dwellTimeSettings: createRef<HTMLInputElement>(),
      meshSize: createRef<HTMLInputElement>(),
      beamPower: createRef<HTMLInputElement>(),
      shift: createRef<HTMLInputElement>(),
      spotSize: createRef<HTMLInputElement>(),
      seeds: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { data: preset, isLoading } = useQuery(
    ['melt-setting-preset', { presetId }],
    () => {
      if (presetId) return getMeltSettingPreset(presetId);
    },
    {
      enabled: !!presetId,
      onError: () => {
        enqueueSnackbar({
          key: `get_preset_details_fail_${Date.now()}`,
          message: t('get_preset_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const { mutate: onUpdatePreset, isLoading: isUpdating } = useMutation<
    MeltSettingPresetWithEtag,
    AxiosError<ErrorResponse>
  >(
    ['melt-setting-preset', { presetId }],
    () => {
      if (!preset) throw new Error('preset does not exist');
      if (!values) throw new Error('preset values do not exist');
      return updateMeltSettingPreset(
        preset.data.id,
        { ...details, ...values },
        preset.etag,
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `update_preset_success_${Date.now()}`,
          message: t('update_preset_success', {
            name: details.name,
          }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['melt-setting-presets']);
        queryClient.invalidateQueries(['melt-setting-preset', { presetId }]);
        setInUpdateState(false);
        setValidationError([]);
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'update_preset_decrepit',
            message: t('update_preset_decrepit', {
              name: details.name,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient
                    .refetchQueries(['melt-setting-preset', { presetId }])
                    .then(() => {
                      closeSnackbar('update_preset_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `update_preset_fail_${Date.now()}`,
            message: t('update_preset_fail', { name: details.name }),
            variant: 'error',
            persist: true,
          });
          if (response?.status === 400) {
            if (response.data.errors) {
              const errorKeys = Object.keys(response.data.errors)
                .map<keyof UpdateMeltSettingPreset | undefined>((key) => {
                  if (key === 'Name') return 'name';
                  if (key === 'Material') return 'material';
                  if (key === 'pointSpreadAlgName') return 'pointSpreadAlgName';
                  if (key === 'pointSpreadSettings')
                    return 'pointSpreadSettings';
                  if (key === 'dwellTimeAlgName') return 'dwellTimeAlgName';
                  if (key === 'dwellTimeSettings') return 'dwellTimeSettings';
                  if (key === 'meshSize') return 'meshSize';
                  if (key === 'beamPower') return 'beamPower';
                  if (key === 'shift') return 'shift';
                  if (key === 'spotSize') return 'spotSize';
                  if (key === 'seeds') return 'seeds';
                  return undefined;
                })
                .filter((key): key is keyof UpdateMeltSettingPreset => !!key);
              setValidationError(errorKeys);
              refs[errorKeys[0]]?.current?.focus();
            }
          }
        }
      },
    },
  );

  const { data: currentUser } = useQuery<UserDetailsWithEtag>('current-user');

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, preset?.data.createdBy),
    [currentUser?.data, preset?.data.createdBy],
  );

  const resetForm = useCallback<() => void>(() => {
    if (preset) {
      setDetails({
        name: preset.data.name,
        material: preset.data.material,
      });
      setValues({
        pointSpreadAlgName: preset.data.pointSpreadAlgName,
        pointSpreadSettings: preset.data.pointSpreadSettings,
        dwellTimeAlgName: preset.data.dwellTimeAlgName,
        dwellTimeSettings: preset.data.dwellTimeSettings,
        meshSize: preset.data.meshSize,
        beamPower: preset.data.beamPower,
        shift: preset.data.shift,
        spotSize: preset.data.spotSize,
        seeds: preset.data.seeds,
      });
    }
  }, [preset]);

  const onCancelUpdate = useCallback<() => void>(() => {
    resetForm();
    setInUpdateState(false);
    setValidationError([]);
  }, [resetForm]);

  const onClose = useCallback<() => void>(() => {
    setInUpdateState(false);
    setValidationError([]);
    setValues(undefined);
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    setValidationError([]);
  }, [details, values]);

  if (isLoading) return <AppDetailsDrawer open onClose={onClose} isLoading />;

  if (preset)
    return (
      <>
        <AppDetailsDrawer
          open
          onClose={onClose}
          title={preset.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              {inUpdateState ? (
                <>
                  <Button
                    onClick={onCancelUpdate}
                    variant="outlined"
                    color="inherit"
                    startIcon={<CancelIcon />}
                    disabled={isUpdating}
                    sx={{ flex: 1 }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    onClick={() => onUpdatePreset()}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    disabled={isUpdating}
                    sx={{ flex: 2 }}
                  >
                    {t('save')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setInUpdateState(true)}
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    disabled={!userHasWritePermission}
                    fullWidth
                  >
                    {t('update')}
                  </Button>
                  <Button
                    onClick={() => setDuplicateDialog(true)}
                    variant="outlined"
                    color="secondary"
                    startIcon={<DuplicateIcon />}
                    fullWidth
                  >
                    {t('duplicate')}
                  </Button>

                  <Button
                    onClick={() => setDeleteDialog(true)}
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    disabled={!userHasWritePermission}
                    fullWidth
                  >
                    {t('delete')}
                  </Button>
                </>
              )}
            </Stack>
          }
        >
          {inUpdateState ? (
            <ProjectMenuPanelGroup title={t('info')}>
              <Stack spacing={2}>
                <AppTextForm
                  ref={refs.name}
                  label={t('name')}
                  value={details.name}
                  onChange={(name) =>
                    setDetails((prevState) => ({ ...prevState, name }))
                  }
                  helperText={t('preset_info_name_helper')}
                  error={validationError.includes('name')}
                  errorText={t('validations:update_preset.name')}
                />
                <AppTextForm
                  ref={refs.material}
                  label={t('material')}
                  value={details.material}
                  onChange={(material) =>
                    setDetails((prevState) => ({ ...prevState, material }))
                  }
                  helperText={t('preset_info_material_helper')}
                  error={validationError.includes('material')}
                  errorText={t('validations:update_preset.material')}
                />
              </Stack>
            </ProjectMenuPanelGroup>
          ) : (
            <Stack spacing={1}>
              <Typography variant="h5">{t('info')}</Typography>
              <Typography>
                {t('details_material', { value: preset.data.material })}
              </Typography>
              <Typography>
                {t('details_created', {
                  value: preset.data.createdBy
                    ? `${preset.data.createdBy.email} (${t('dateTime', {
                        value: new Date(preset.data.createdDate),
                      })})`
                    : t('dateTime', {
                        value: new Date(preset.data.createdDate),
                      }),
                })}
              </Typography>
              <Typography>
                {t('details_updated', {
                  value: preset.data.updatedBy
                    ? `${preset.data.updatedBy.email} (${t('dateTime', {
                        value: new Date(preset.data.updatedDate),
                      })})`
                    : t('dateTime', {
                        value: new Date(preset.data.updatedDate),
                      }),
                })}
              </Typography>
            </Stack>
          )}
          <Stack spacing={2}>
            <Typography variant="h5">{t('settings')}</Typography>
            <AppMeltSettingsForm
              values={values ?? initialMeltSetting}
              onChangeValues={setValues}
              disabled={!inUpdateState}
              refs={refs}
              errors={validationError.filter(
                (key): key is keyof MeltSetting =>
                  key !== 'name' && key !== 'material',
              )}
            />
          </Stack>
        </AppDetailsDrawer>
        <DuplicatePresetDialog
          open={duplicateDialog}
          onClose={() => setDuplicateDialog(false)}
          presetId={preset.data.id}
        />
        {userHasWritePermission && (
          <DeletePresetDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            presetId={preset.data.id}
          />
        )}
      </>
    );

  return null;
};
