import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  createProject,
  ErrorResponse,
  CreateProject,
  ProjectSettingsResponseWithEtag,
  getProjectSettings,
} from 'api';
import { useSearchParams, useSnackbar } from 'hooks';

type DuplicateProjectForm = Omit<CreateProject, 'settings' | 'cadModelId'>;
const newProjectInit: DuplicateProjectForm = {
  name: '',
  material: '',
  notes: '',
};

interface Props {
  open: boolean;
  projectId: string;
  onClose: () => void;
}

export const DuplicateProjectDialog: FC<Props> = ({
  open,
  projectId,
  onClose,
}) => {
  const { t } = useTranslation();
  const [, { updateSearchParam }] = useSearchParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [newProject, setNewProject] =
    useState<DuplicateProjectForm>(newProjectInit);
  const [validationError, setValidationError] = useState<
    (keyof CreateProject)[]
  >([]);
  const refs = useMemo<
    Record<keyof DuplicateProjectForm, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      notes: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const {
    data: projectSettings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
  } = useQuery(
    ['project-settings', { projectId }],
    () => getProjectSettings(projectId),
    {
      enabled: open,
      onError: () => {
        enqueueSnackbar({
          key: `get_project_settings_fail_${Date.now()}`,
          message: t('get_project_settings_fail'),
          variant: 'error',
        });
        onClose();
      },
    },
  );

  const { mutateAsync: duplicateProject, isLoading: isLoadingDuplicate } =
    useMutation<ProjectSettingsResponseWithEtag, AxiosError<ErrorResponse>>(
      () => {
        if (!projectSettings)
          throw new Error('project settings does not exist');
        return createProject({
          ...newProject,
          settings: projectSettings.data.settings,
          cadModelId: projectSettings.data.cadModel.id,
        });
      },
      {
        onSuccess: ({ data }) => {
          enqueueSnackbar({
            key: `duplicate_project_success_${Date.now()}`,
            message: t('duplicate_project_success', {
              projectName: projectSettings?.data.name,
            }),
            variant: 'success',
          });
          queryClient.invalidateQueries(['projects']);
          updateSearchParam(['rowId', data.id]);
          onClose();
        },
        onError: ({ response }) => {
          enqueueSnackbar({
            key: `duplicate_project_fail_${Date.now()}`,
            message: t('duplicate_project_fail'),
            variant: 'error',
            persist: true,
          });
          if (response?.status === 400) {
            if (response.data.errors) {
              const errorKeys = Object.keys(response.data.errors)
                .map<keyof DuplicateProjectForm | undefined>((key) => {
                  if (key === 'Name') return 'name';
                  if (key === 'Material') return 'material';
                  if (key === 'Notes') return 'notes';
                  return undefined;
                })
                .filter((key): key is keyof DuplicateProjectForm => !!key);
              setValidationError(errorKeys);
              refs[errorKeys[0]]?.current?.focus();
            }
          }
        },
      },
    );

  useEffect(() => {
    if (projectSettings) {
      setNewProject({
        name: t('duplicate_of_project', {
          projectName: projectSettings.data.name,
        }),
        material: projectSettings.data.material,
        notes: projectSettings.data.notes,
      });
    }
  }, [projectSettings, t]);

  useEffect(() => {
    if (open && isSettingsError) {
      onClose();
      enqueueSnackbar({
        key: `duplicate_project_blocked`,
        message: t('duplicate_project_blocked'),
        variant: 'info',
      });
    }
  }, [enqueueSnackbar, isSettingsError, onClose, open, t]);

  useEffect(() => {
    setValidationError([]);
  }, [newProject]);

  useEffect(() => {
    return () => {
      setNewProject(newProjectInit);
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('duplicate_project')}
      actions={
        <Button
          onClick={() => duplicateProject()}
          variant="contained"
          disabled={isLoadingDuplicate || isSettingsLoading}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isLoadingDuplicate || isSettingsLoading ? (
            <CircularProgress />
          ) : (
            t('duplicate')
          )}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={newProject.name}
          onChange={(name) => {
            setNewProject((prevState) => ({ ...prevState, name }));
          }}
          componentProps={{ inputRef: refs.name }}
          helperText={t('project_info_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_project.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={newProject.material}
          onChange={(material) => {
            setNewProject((prevState) => ({ ...prevState, material }));
          }}
          componentProps={{ inputRef: refs.material }}
          helperText={t('project_info_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_project.material')}
        />
        <AppTextForm
          label={t('notes')}
          value={newProject.notes}
          onChange={(notes) => {
            setNewProject((prevState) => ({ ...prevState, notes }));
          }}
          componentProps={{ multiline: true, inputRef: refs.notes }}
          helperText={t('project_info_notes_helper')}
          error={validationError.includes('notes')}
          errorText={t('validations:duplicate_project.notes')}
        />
      </Stack>
    </AppDialog>
  );
};
