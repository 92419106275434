import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { AppDeletionDialog } from 'components/AppDialog';
import { deleteProject, ErrorResponse, getProjectDetails } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  projectId: string;
  onClose: () => void;
}

export const DeleteProjectDialog: FC<Props> = ({
  open,
  projectId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();

  const {
    data: details,
    isLoading: isDetailsLoading,
    isRefetching: isDetailsRefetching,
  } = useQuery(
    ['project', { projectId }],
    () => (projectId ? getProjectDetails(projectId) : undefined),
    {
      onError: () => {
        enqueueSnackbar({
          key: `get_project_details_fail_${Date.now()}`,
          message: t('get_project_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const { mutate: onDeleteProject, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    () => {
      if (!details) throw new Error('project details does not exist');
      return deleteProject(details.data.id, details.etag);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_project_success_${Date.now()}`,
          message: t('delete_project_success', { name: details?.data.name }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['projects']);
        queryClient.removeQueries(['project', { projectId }]);
        queryClient.removeQueries(['projects-settings', { projectId }]);
        deleteSearchParam('rowId');
        onClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'delete_project_decrepit',
            message: t('delete_project_decrepit', {
              name: details?.data.name,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient
                    .refetchQueries(['project', { projectId }])
                    .then(() => {
                      closeSnackbar('delete_project_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `delete_project_fail_${Date.now()}`,
            message: t('delete_project_fail', { name: details?.data.name }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  return (
    <AppDeletionDialog
      open={open}
      onClose={onClose}
      title={t('delete_project')}
      text={t('delete_project_confirmation', { name: details?.data.name })}
      onConfirm={onDeleteProject}
      confirmDisabled={isDeleting || isDetailsLoading || isDetailsRefetching}
    />
  );
};
