import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  createProject,
  ErrorResponse,
  CreateProject,
  ProjectSettingsResponseWithEtag,
} from 'api';
import { useSnackbar } from 'hooks';
import { getReduxProjectSettings } from 'helpers';

type SaveProjectAsForm = Omit<CreateProject, 'settings' | 'cadModelId'>;
const newProjectInit: SaveProjectAsForm = {
  name: '',
  material: '',
  notes: '',
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ProjectSaveAsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [newProject, setNewProject] =
    useState<SaveProjectAsForm>(newProjectInit);

  const [validationError, setValidationError] = useState<
    (keyof CreateProject)[]
  >([]);

  const refs = useMemo<
    Record<keyof SaveProjectAsForm, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      notes: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const { mutateAsync: saveProjectAs, isLoading: isSavingLoading } =
    useMutation<ProjectSettingsResponseWithEtag, AxiosError<ErrorResponse>>(
      () => {
        if (!projectSettings)
          throw new Error('project settings does not exist');
        return createProject({
          ...newProject,
          settings: getReduxProjectSettings(),
          cadModelId: projectSettings?.data.cadModel.id,
        });
      },
      {
        onSuccess: ({ data }) => {
          enqueueSnackbar({
            key: `create_project_success_${Date.now()}`,
            message: t('create_project_success', {
              projectName: data.name,
            }),
            variant: 'success',
          });
          queryClient.invalidateQueries(['project', { projectId }]);
          onClose();
          window.open(
            `${window.location.origin}/projects/${data.id}`,
            '_blank',
          );
        },
        onError: ({ response }) => {
          enqueueSnackbar({
            key: `create_project_fail_${Date.now()}`,
            message: t('create_project_fail'),
            variant: 'error',
            persist: true,
          });
          if (response?.status === 400) {
            if (response.data.errors) {
              const errorKeys = Object.keys(response.data.errors)
                .map<keyof SaveProjectAsForm | undefined>((key) => {
                  if (key === 'Name') return 'name';
                  if (key === 'Material') return 'material';
                  if (key === 'Notes') return 'notes';
                  return undefined;
                })
                .filter((key): key is keyof SaveProjectAsForm => !!key);
              setValidationError(errorKeys);
              refs[errorKeys[0]]?.current?.focus();
            }
          }
        },
      },
    );

  useEffect(() => {
    if (projectSettings) {
      setNewProject({
        ...newProjectInit,
        material: projectSettings.data.material,
        notes: projectSettings.data.notes,
      });
    }
  }, [projectSettings]);

  useEffect(() => {
    setValidationError([]);
  }, [newProject]);

  useEffect(() => {
    return () => {
      setNewProject(newProjectInit);
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('save_project_as')}
      actions={
        <Button
          onClick={() => saveProjectAs()}
          variant="contained"
          disabled={isSavingLoading}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isSavingLoading ? <CircularProgress /> : t('save')}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={newProject.name}
          onChange={(name) => {
            setNewProject((prevState) => ({ ...prevState, name }));
          }}
          componentProps={{ inputRef: refs.name }}
          helperText={t('project_info_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_project.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={newProject.material}
          onChange={(material) => {
            setNewProject((prevState) => ({ ...prevState, material }));
          }}
          componentProps={{ inputRef: refs.material }}
          helperText={t('project_info_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_project.material')}
        />
        <AppTextForm
          label={t('notes')}
          value={newProject.notes}
          onChange={(notes) => {
            setNewProject((prevState) => ({ ...prevState, notes }));
          }}
          componentProps={{ multiline: true, inputRef: refs.notes }}
          helperText={t('project_info_notes_helper')}
          error={validationError.includes('notes')}
          errorText={t('validations:duplicate_project.notes')}
        />
      </Stack>
    </AppDialog>
  );
};
