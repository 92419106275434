import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export interface AppState {
  keyboardShortcuts: boolean;
}

const initialState: AppState = {
  keyboardShortcuts: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setKeyboardShortcuts: (state, { payload }: { payload: boolean }) => {
      state.keyboardShortcuts = payload;
    },
  },
});

export const { setKeyboardShortcuts } = appSlice.actions;

export const selectKeyboardShortcuts = (state: RootState): boolean =>
  state.app.keyboardShortcuts;

export default appSlice.reducer;
