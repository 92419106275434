import { FC, useEffect } from 'react';

import Public from 'pages/Public';
import Private from 'pages/Private';
import AppTransition from 'components/AppTransition';
import { useAuthentication } from 'hooks';
// Redux
import { useSelector } from 'store';
import { selectState } from 'slices/userSlice';

export const App: FC = () => {
  const { getSession } = useAuthentication();
  const { isAuthenticated, isSuccess } = useSelector(selectState);

  useEffect(() => {
    getSession();
  }, [getSession]);

  if (!isAuthenticated) return <AppTransition />;
  if (isSuccess) return <Private />;
  return <Public />;
};
