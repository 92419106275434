import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import 'react-app-polyfill/stable';

import './i18n';
import 'assets/styles';
import { SnackbarProvider, ThemeProvider, QueryProvider } from 'providers';
import { store } from 'store';
import { App } from './App';

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider>
      <CssBaseline enableColorScheme />
      <SnackbarProvider>
        <QueryProvider>
          <App />
        </QueryProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root'),
);
