import { FC, useCallback, useEffect, useState } from 'react';
import { Slider, SliderProps } from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps {
  value: number;
  setValue: (v: number) => void;
  min: number;
  max: number;
  step?: number;
  componentProps?: SliderProps;
}

export const AppSliderForm: FC<Props> = ({
  label,
  value,
  setValue,
  min,
  max,
  step = 1,
  componentProps,
  ...formControlProps
}) => {
  const [state, setState] = useState<{ value: number; hasChanged: boolean }>({
    value,
    hasChanged: false,
  });

  const handleChange = useCallback<(e: Event, v: number | number[]) => void>(
    (_e, v) => {
      if (typeof v === 'number') {
        setState({ value: v, hasChanged: true });
      }
    },
    [setState],
  );

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (state.hasChanged) {
        setValue(state.value);
        setState((prevState) => ({ ...prevState, hasChanged: false }));
      }
    }, 50);
    if (state.value !== value && !state.hasChanged) {
      setState({ value, hasChanged: false });
    }
    return () => {
      clearTimeout(debounce);
    };
  }, [setValue, state, value]);

  return (
    <AppFormControl label={label} {...formControlProps}>
      <Slider
        valueLabelDisplay="auto"
        value={state.value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        sx={{ width: '90%', m: 'auto' }}
        {...componentProps}
      />
    </AppFormControl>
  );
};
