import { ChangeEvent, forwardRef } from 'react';
import { Input, InputProps } from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps {
  value: string;
  onChange?: (v: string) => void;
  componentProps?: InputProps;
}

export const AppTextForm = forwardRef<HTMLInputElement, Props>(
  ({ label, value, onChange, componentProps, ...formControlProps }, ref) => {
    return (
      <AppFormControl label={label} {...formControlProps}>
        <Input
          ref={ref}
          value={value}
          onChange={(
            e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => {
            if (onChange) onChange(e.target.value);
          }}
          fullWidth
          {...componentProps}
        />
      </AppFormControl>
    );
  },
);
AppTextForm.displayName = 'AppTextForm';
