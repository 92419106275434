import { FC, useMemo } from 'react';
import { Input, InputAdornment, InputProps } from '@mui/material';

import { validateNumber, ValidateNumberOptions } from 'helpers';
import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps, ValidateNumberOptions {
  value: string;
  onChange: (v: string) => void;
  unit?: string;
  disabled?: boolean;
  step?: number;
  componentProps?: InputProps;
}

export const AppNumberForm: FC<Props> = ({
  label,
  value,
  onChange,
  unit,
  error,
  disabled,
  min,
  max,
  noDecimal,
  orEqual,
  step,
  componentProps,
  ...formControlProps
}) => {
  const invalidValue = useMemo(
    () => error || !validateNumber(value, { min, max, noDecimal, orEqual }),
    [error, min, max, noDecimal, orEqual, value],
  );

  return (
    <AppFormControl label={label} error={invalidValue} {...formControlProps}>
      <Input
        inputProps={{
          type: 'number',
          min,
          max,
          step,
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end" disableTypography>
            {unit}
          </InputAdornment>
        }
        error={invalidValue}
        disabled={disabled}
        fullWidth
        {...componentProps}
      />
    </AppFormControl>
  );
};
