import { ComponentProps, forwardRef, ReactNode } from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';

export interface Props {
  snackbarKey?: SnackbarKey;
  message: SnackbarMessage;
  severity?: ComponentProps<typeof Alert>['severity'];
  action?: ReactNode;
}

export const AppSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ snackbarKey: key, message, severity, action }, ref) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <Alert
        severity={severity}
        sx={{
          minWidth: { sm: 560 },
          width: { sm: '50vw' },
          color: 'text.secondary',
        }}
        elevation={6}
        ref={ref}
        variant="filled"
        action={
          <>
            {action}
            <IconButton
              sx={{ color: 'text.secondary' }}
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      >
        <Typography>{message}</Typography>
      </Alert>
    );
  },
);
AppSnackbar.displayName = 'AppSnackbar';
