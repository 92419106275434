import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';

import { baseUrl } from 'configs/api';
// Redux
import { useSelector } from 'store';
import { selectAccessToken } from 'slices/userSlice';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

interface Props {
  enableDevtools?: boolean;
}

export const QueryProvider: FC<Props> = ({ enableDevtools, children }) => {
  axios.defaults.baseURL = baseUrl;

  const accessToken = useSelector(selectAccessToken);
  if (accessToken)
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  return (
    <QueryClientProvider client={queryClient}>
      {enableDevtools && <ReactQueryDevtools />}
      {children}
    </QueryClientProvider>
  );
};
