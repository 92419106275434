import { FC } from 'react';

import {
  MeltPanel,
  // HeatPanel,
  BuildPanel,
  SelectionPanel,
  InfoPanel,
} from '.';
import { ReactComponent as MeltIcon } from 'assets/icons/melt.svg';
// import { ReactComponent as HeatIcon } from 'assets/icons/heat.svg';
import { ReactComponent as BuildIcon } from 'assets/icons/build.svg';
import { ReactComponent as SelectionIcon } from 'assets/icons/selection.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Panel = FC<{
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}>;

export type MenuName = 'primary' | 'secondary';
export type MenuItem = {
  name: string;
  Icon: FC;
  Panel: Panel;
  disabled?: boolean;
};
export type Menu = {
  name: MenuName;
  items: MenuItem[];
};

export const primaryMenuItems: MenuItem[] = [
  { name: 'melt', Icon: MeltIcon, Panel: MeltPanel },
  // { name: 'heat', Icon: HeatIcon, Panel: HeatPanel, disabled: true },
  { name: 'build', Icon: BuildIcon, Panel: BuildPanel },
];
export const secondaryMenuItems: MenuItem[] = [
  { name: 'selection', Icon: SelectionIcon, Panel: SelectionPanel },
  { name: 'info', Icon: InfoIcon, Panel: InfoPanel },
];

export const primaryMenu: Menu = {
  name: 'primary',
  items: primaryMenuItems,
};
export const secondaryMenu: Menu = {
  name: 'secondary',
  items: secondaryMenuItems,
};
