import { FC, ReactNode, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogProps,
  SvgIconTypeMap,
  useMediaQuery,
  Theme,
  DialogContentProps,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  CloseRounded as CloseIcon,
  RemoveRounded as MinimizeIcon,
} from '@mui/icons-material';

interface Props {
  open: boolean;
  title: string;
  children: ReactNode;
  actions: ReactNode;
  headerAction?: ReactNode;
  dismissIcon?: 'close' | 'minimize';
  onClose?: () => void;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'children'>;
  dialogContentProps?: Omit<DialogContentProps, 'children'>;
}

export const AppDialog: FC<Props> = ({
  open,
  title,
  children,
  actions,
  headerAction,
  dismissIcon,
  onClose,
  dialogProps,
  dialogContentProps,
}) => {
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.only('xs'),
  );
  const DismissIcon = useMemo<OverridableComponent<SvgIconTypeMap>>(() => {
    if (dismissIcon === 'close') return CloseIcon;
    if (dismissIcon === 'minimize') return MinimizeIcon;
    return CloseIcon;
  }, [dismissIcon]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={smallScreenSize}
      fullWidth
      {...dialogProps}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(2),
          top: (theme) => theme.spacing(2),
        }}
      >
        <DismissIcon />
      </IconButton>
      <DialogTitle sx={{ justifyContent: 'flex-start' }}>
        {title}
        {headerAction}
      </DialogTitle>
      <DialogContent {...dialogContentProps}>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
