import { createRef, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, CircularProgress, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  createMeltSettingPreset,
  CreateMeltSettingPreset,
  ErrorResponse,
  getMeltSettingPreset,
  MeltSettingPresetWithEtag,
} from 'api';
import { useSearchParams, useSnackbar } from 'hooks';

type DuplicatePresetForm = Pick<CreateMeltSettingPreset, 'name' | 'material'>;
const newPresetInit: DuplicatePresetForm = {
  name: '',
  material: '',
};

interface Props {
  open: boolean;
  presetId: string;
  onClose: () => void;
}

export const DuplicatePresetDialog: FC<Props> = ({
  open,
  presetId,
  onClose,
}) => {
  const { t } = useTranslation();
  const [, { updateSearchParam }] = useSearchParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [newPreset, setNewPreset] =
    useState<DuplicatePresetForm>(newPresetInit);
  const [validationError, setValidationError] = useState<
    (keyof CreateMeltSettingPreset)[]
  >([]);
  const refs = useMemo<
    Record<keyof DuplicatePresetForm, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const {
    data: preset,
    isLoading: isPresetLoading,
    isError: isPresetError,
  } = useQuery(
    ['melt-setting-preset', { presetId }],
    () => getMeltSettingPreset(presetId),
    {
      enabled: open,
      onError: () => {
        enqueueSnackbar({
          key: `get_preset_settings_fail_${Date.now()}`,
          message: t('get_preset_settings_fail'),
          variant: 'error',
        });
        onClose();
      },
    },
  );

  const { mutateAsync: duplicatePreset, isLoading: isDuplicating } =
    useMutation<MeltSettingPresetWithEtag, AxiosError<ErrorResponse>>(
      () => {
        if (!preset) throw new Error('preset settings does not exist');
        return createMeltSettingPreset({
          ...newPreset,
          pointSpreadAlgName: preset.data.pointSpreadAlgName,
          pointSpreadSettings: preset.data.pointSpreadSettings,
          dwellTimeAlgName: preset.data.dwellTimeAlgName,
          dwellTimeSettings: preset.data.dwellTimeSettings,
          meshSize: preset.data.meshSize,
          beamPower: preset.data.beamPower,
          shift: preset.data.shift,
          spotSize: preset.data.spotSize,
          seeds: preset.data.seeds,
        });
      },
      {
        onSuccess: ({ data }) => {
          enqueueSnackbar({
            key: `duplicate_preset_success_${Date.now()}`,
            message: t('duplicate_preset_success', { name: preset?.data.name }),
            variant: 'success',
          });
          queryClient.invalidateQueries(['melt-setting-presets']);
          updateSearchParam(['rowId', data.id]);
          onClose();
        },
        onError: ({ response }) => {
          enqueueSnackbar({
            key: `duplicate_preset_fail_${Date.now()}`,
            message: t('duplicate_preset_fail'),
            variant: 'error',
            persist: true,
          });
          if (response?.status === 400) {
            if (response.data.errors) {
              const errorKeys = Object.keys(response.data.errors)
                .map<keyof DuplicatePresetForm | undefined>((key) => {
                  if (key === 'Name') return 'name';
                  if (key === 'Material') return 'material';
                  return undefined;
                })
                .filter((key): key is keyof DuplicatePresetForm => !!key);
              setValidationError(errorKeys);
              refs[errorKeys[0]]?.current?.focus();
            }
          }
        },
      },
    );

  useEffect(() => {
    if (preset) {
      setNewPreset({
        name: t('duplicate_of_preset', { name: preset.data.name }),
        material: preset.data.material,
      });
    }
  }, [preset, t]);

  useEffect(() => {
    if (open && isPresetError) {
      onClose();
      enqueueSnackbar({
        key: `duplicate_preset_blocked`,
        message: t('duplicate_preset_blocked'),
        variant: 'info',
      });
    }
  }, [enqueueSnackbar, isPresetError, onClose, open, t]);

  useEffect(() => {
    return () => {
      setNewPreset(newPresetInit);
      setValidationError([]);
    };
  }, []);

  return (
    <AppDialog
      open={open}
      title={t('duplicate_preset')}
      actions={
        <Button
          onClick={() => duplicatePreset()}
          variant="contained"
          disabled={isDuplicating || isPresetLoading}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {isDuplicating || isPresetLoading ? (
            <CircularProgress />
          ) : (
            t('duplicate')
          )}
        </Button>
      }
      onClose={onClose}
    >
      <Stack spacing={3}>
        <AppTextForm
          ref={refs.name}
          label={t('name')}
          value={newPreset.name}
          onChange={(name) => {
            setNewPreset((prevState) => ({ ...prevState, name }));
          }}
          componentProps={{ inputRef: refs.name }}
          helperText={t('preset_info_name_helper')}
          error={validationError.includes('name')}
          errorText={t('validations:duplicate_preset.name')}
        />
        <AppTextForm
          ref={refs.material}
          label={t('material')}
          value={newPreset.material}
          onChange={(material) => {
            setNewPreset((prevState) => ({ ...prevState, material }));
          }}
          componentProps={{ inputRef: refs.material }}
          helperText={t('preset_info_material_helper')}
          error={validationError.includes('material')}
          errorText={t('validations:duplicate_preset.material')}
        />
      </Stack>
    </AppDialog>
  );
};
