import { useCallback, useEffect } from 'react';
import { Group, Object3D } from 'three';

export interface ModelValidationCallbacks {
  onSuccess?: () => void;
  onSuccessCleanup?: () => void;
  onFail?: () => void;
  onFailCleanup?: () => void;
}

interface UseValidate3DModelParams extends ModelValidationCallbacks {
  model: Group;
}

export const useValidate3DModel = ({
  model,
  onSuccess,
  onSuccessCleanup,
  onFail,
  onFailCleanup,
}: UseValidate3DModelParams): void => {
  const hasMesh: (group: Object3D) => boolean = useCallback((group) => {
    if (!group.children.length) return false;
    return group.children.every((child) => {
      if (child.type === 'Group') return hasMesh(child);
      if (child.type === 'Mesh') return true;
      return false;
    });
  }, []);

  useEffect(() => {
    if (hasMesh(model)) {
      if (onSuccess) onSuccess();
      return () => {
        if (onSuccessCleanup) onSuccessCleanup();
      };
    } else {
      if (onFail) onFail();
      return () => {
        if (onFailCleanup) onFailCleanup();
      };
    }
  }, [hasMesh, model, onFail, onFailCleanup, onSuccess, onSuccessCleanup]);
};
