import { FC, useCallback, useEffect, useMemo } from 'react';
import { Pagination, Stack, Typography } from '@mui/material';

import AppSelect from 'components/AppSelect';
import { useSearchParams } from 'hooks';

const OPTIONS = [10, 25, 50];

export interface Props {
  pageSize?: number;
  totalCount?: number;
  currentPage?: number;
  onChangeCurrentPage?: (arg: number) => void;
  onChangePageSize?: (arg: number) => void;
}

export const AppPagination: FC<Props> = ({
  currentPage,
  pageSize,
  totalCount,
  onChangeCurrentPage,
  onChangePageSize,
}) => {
  const [, { updateSearchParam, updateSearchParams }] = useSearchParams();

  const totalPages = useMemo(
    () => totalCount && pageSize && Math.ceil(totalCount / pageSize),
    [pageSize, totalCount],
  );

  const options = useMemo<{ value: number; label: string }[]>(
    () =>
      (totalCount
        ? [...OPTIONS.filter((value) => value !== totalCount), totalCount]
        : OPTIONS
      )
        .map((value) => ({
          value,
          label: `${value}`,
        }))
        .sort(({ value: a }, { value: b }) => a - b),
    [totalCount],
  );

  const handleChangeCurrentPage = useCallback<(value: number) => void>(
    (value) => {
      if (onChangeCurrentPage) {
        onChangeCurrentPage(value);
      } else {
        updateSearchParam(['currentPage', value.toString()]);
      }
    },
    [onChangeCurrentPage, updateSearchParam],
  );

  const handleChangePageSize = useCallback<(value: number) => void>(
    (value) => {
      if (onChangePageSize) {
        onChangePageSize(value);
        updateSearchParam(['currentPage', '1']);
      } else {
        updateSearchParams([
          ['pageSize', value.toString()],
          ['currentPage', '1'],
        ]);
      }
    },
    [onChangePageSize, updateSearchParam, updateSearchParams],
  );

  useEffect(() => {
    if (
      currentPage !== undefined &&
      totalPages !== undefined &&
      currentPage > 1 &&
      currentPage > totalPages
    ) {
      handleChangeCurrentPage(1);
    }
  }, [currentPage, handleChangeCurrentPage, totalPages]);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {pageSize && totalCount && (
        <>
          <AppSelect
            label="test"
            value={
              !options.some(({ value }) => value === pageSize)
                ? totalCount
                : pageSize
            }
            onChange={(rowsPerPage) =>
              handleChangePageSize(rowsPerPage as number)
            }
            options={options}
            sx={{ fontSize: (theme) => theme.typography.body2.fontSize }}
          />
          <Typography variant="body2" whiteSpace="nowrap">
            / {totalCount}
          </Typography>
          {currentPage && (
            <Pagination
              shape="rounded"
              size="small"
              variant="outlined"
              siblingCount={0}
              boundaryCount={1}
              count={totalPages}
              page={currentPage}
              onChange={(_event, page) => handleChangeCurrentPage(page)}
            />
          )}
        </>
      )}
    </Stack>
  );
};
