import { FC, useState } from 'react';
import { Stack, useMediaQuery, Theme } from '@mui/material';

import AppHeader from 'components/AppHeader';
import AppSidebar from 'components/AppSidebar';

export const Home: FC = ({ children }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(
    useMediaQuery<Theme>(({ breakpoints }) => breakpoints.up('md'), {
      noSsr: true,
    }),
  );

  return (
    <Stack
      sx={{
        backgroundColor: 'background.default',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AppHeader
        sidebarExpanded={sidebarExpanded}
        onMenuClick={() => setSidebarExpanded((prevState) => !prevState)}
      />
      <Stack direction="row" flex={1} overflow="hidden">
        <AppSidebar expanded={sidebarExpanded} />
        <Stack flex={1} overflow="hidden">
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
