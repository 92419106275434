import { ChangeEvent, forwardRef, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import {
  Tooltip,
  Button,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  UploadFileRounded as UploadIcon,
  FileOpenRounded as ExistingIcon,
} from '@mui/icons-material';

import { ExistingModelsDialog } from 'components/AppModelForm';
import { useSnackbar } from 'hooks';
import { dataUrlToBase64 } from 'helpers';
import {
  createModel,
  CreateModel,
  ErrorResponse,
  ModelDetails,
  ModelDetailsWithEtag,
} from 'api';
// Redux
import { useDispatch } from 'store';
import { setPartitions } from 'slices/partitionSettingsSlice';

interface Props {
  data: ModelDetails | undefined;
  onChangeModelId: (id: string) => void;
  error?: boolean;
  errorText?: string;
}

export const AppModelForm = forwardRef<HTMLButtonElement, Props>(
  ({ data, onChangeModelId, error, errorText }, ref) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const inputRef = useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [selectDialog, setSelectDialog] = useState(false);

    const handleSelectModel = useCallback(
      (id) => {
        onChangeModelId(id);
        setSelectDialog(false);
      },
      [onChangeModelId],
    );

    const { mutateAsync: onUpload, isLoading } = useMutation<
      ModelDetailsWithEtag,
      AxiosError<ErrorResponse>,
      CreateModel
    >(createModel, {
      onSuccess: async (data) => {
        enqueueSnackbar({
          key: `create_model_success_${Date.now()}`,
          message: t('create_model_success', { name: data.data.name }),
          variant: 'success',
        });
        onChangeModelId(data.data.id);
        queryClient.invalidateQueries(['models']);
        queryClient.setQueriesData(['model', { modelId: data.data.id }], data);
      },
      onError: () => {
        dispatch(setPartitions([]));
        enqueueSnackbar({
          key: `create_model_fail_${Date.now()}`,
          message: t('create_model_fail'),
          variant: 'error',
          persist: true,
        });
      },
    });

    return (
      <>
        <input
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          accept=".3mf"
          multiple={false}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const files: FileList | null = event.target.files;
            if (files && files.length) {
              const reader = new FileReader();
              reader.onloadend = (): void => {
                const result = reader.result as string;
                try {
                  const base64 = dataUrlToBase64(result, '3mf');
                  onUpload({
                    name: files[0].name.replace('.3mf', ''),
                    data: base64,
                  }).catch(() => {
                    if (inputRef.current) inputRef.current.value = '';
                  });
                } catch {
                  enqueueSnackbar({
                    key: `load_model_fail_${Date.now()}`,
                    message: t('load_model_fail'),
                    variant: 'error',
                    persist: true,
                  });
                }
              };
              reader.onerror = (error) => {
                console.error(error);
                enqueueSnackbar({
                  key: `load_model_fail_${Date.now()}`,
                  message: t('load_model_fail'),
                  variant: 'error',
                  persist: true,
                });
              };
              reader.readAsDataURL(files[0]);
            }
          }}
        />
        <Stack>
          <Stack direction="row" alignContent="center" spacing={2}>
            <Tooltip
              title={t('upload_model_helper') as string}
              placement="top"
              arrow
            >
              <Stack
                sx={{
                  flex: data ? 1 : 2,
                  transition: 'flex 0.3s ease-in-out',
                }}
              >
                <Button
                  ref={ref}
                  startIcon={
                    data ? undefined : isLoading ? (
                      <CircularProgress />
                    ) : (
                      <UploadIcon />
                    )
                  }
                  variant="outlined"
                  color={error ? 'error' : 'inherit'}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  disabled={isLoading}
                >
                  {data ? (
                    isLoading ? (
                      <CircularProgress />
                    ) : (
                      <UploadIcon />
                    )
                  ) : (
                    t('upload')
                  )}
                </Button>
              </Stack>
            </Tooltip>
            <Tooltip
              title={t('existing_model_helper') as string}
              placement="top"
              arrow
            >
              <Stack
                sx={{
                  flex: data ? 1 : 2,
                  transition: 'flex 0.3s ease-in-out',
                }}
              >
                <Button
                  ref={ref}
                  startIcon={data ? undefined : <ExistingIcon />}
                  variant="outlined"
                  color={error ? 'error' : 'inherit'}
                  onClick={() => setSelectDialog(true)}
                  disabled={isLoading}
                >
                  {data ? <ExistingIcon /> : t('existing')}
                </Button>
              </Stack>
            </Tooltip>
            <Stack
              flex={4}
              display={data ? 'flex' : 'none'}
              justifyContent="center"
            >
              {data && (
                <Typography variant="body2" noWrap>
                  {data.name}
                </Typography>
              )}
            </Stack>
          </Stack>
          {error && errorText && (
            <Typography variant="body2" color="error">
              {errorText}
            </Typography>
          )}
        </Stack>
        <ExistingModelsDialog
          open={selectDialog}
          onClose={() => setSelectDialog(false)}
          onSubmit={handleSelectModel}
          disabled={isLoading}
        />
      </>
    );
  },
);
AppModelForm.displayName = 'AppModelForm';
