import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import {
  Partition,
  PartitionSettings,
  SelectionType,
  TransformCoordination,
} from 'api';
import { EntitySelectionMode } from 'helpers';

export interface SelectPartitionPayload {
  target: string | number;
  mode: EntitySelectionMode;
}

const initialState: PartitionSettings = {
  selection: 'model',
  partitions: [],
  selectedPartitions: [],
  objectMeltOrder: [],
  meltAllObjectsSimultaneously: false,
  transform: { x: 0, y: 0 },
};

const partitionSettingsSlice = createSlice({
  name: 'partitionSettings',
  initialState,
  reducers: {
    loadPartitionSettings: (
      state,
      { payload }: { payload: PartitionSettings },
    ) => {
      state.selection = payload.selection;
      state.partitions = payload.partitions;
      state.selectedPartitions = payload.selectedPartitions;
      state.objectMeltOrder = payload.objectMeltOrder;
      state.meltAllObjectsSimultaneously = payload.meltAllObjectsSimultaneously;
      state.transform = payload.transform;
    },
    resetPartitionSettings: (state) => {
      state.selection = initialState.selection;
      state.partitions = initialState.partitions;
      state.selectedPartitions = initialState.selectedPartitions;
      state.objectMeltOrder = initialState.objectMeltOrder;
      state.meltAllObjectsSimultaneously =
        initialState.meltAllObjectsSimultaneously;
      state.transform = initialState.transform;
    },
    setSelection: (state, { payload }: { payload: SelectionType }) => {
      state.selection = payload;
    },
    setPartitions: (state, { payload }: { payload: Partition[] }) => {
      state.partitions = payload;
      state.selectedPartitions = [];
    },
    setSelectedPartitions: (
      state,
      {
        payload,
      }: {
        payload: SelectPartitionPayload;
      },
    ) => {
      if (payload.mode === 'single') {
        if (typeof payload.target === 'string') {
          state.selectedPartitions = [payload.target];
        }
        if (typeof payload.target === 'number') {
          if (payload.target < 0) {
            state.selectedPartitions = state.partitions.map(
              ({ partitionID }) => partitionID,
            );
          } else {
            state.selectedPartitions = state.partitions
              .filter(({ objectID }) => payload.target === objectID)
              .map(({ partitionID }) => partitionID);
          }
        }
      }
      if (payload.mode === 'multiple') {
        if (typeof payload.target === 'string') {
          state.selectedPartitions = state.selectedPartitions.includes(
            payload.target,
          )
            ? state.selectedPartitions.filter((id) => id !== payload.target)
            : [...state.selectedPartitions, payload.target];
        } else {
          const list: string[] = state.partitions
            .filter(
              ({ objectID }) =>
                payload.target === -1 || payload.target === objectID,
            )
            .map(({ partitionID }) => partitionID);
          const filteredState: string[] = state.selectedPartitions.filter(
            (id) => !list.includes(id),
          );
          state.selectedPartitions = list.every((id) =>
            state.selectedPartitions.includes(id),
          )
            ? filteredState
            : [...filteredState, ...list];
        }
      }
    },
    setObjectMeltOrder: (state, { payload }: { payload: number[] }) => {
      state.objectMeltOrder = payload;
    },
    setMeltAllObjectsSimultaneously: (
      state,
      { payload }: { payload: boolean },
    ) => {
      state.meltAllObjectsSimultaneously = payload;
    },
    setTransform: (state, { payload }: { payload: TransformCoordination }) => {
      state.transform = payload;
    },
  },
});

export const {
  loadPartitionSettings,
  resetPartitionSettings,
  setSelection,
  setPartitions,
  setSelectedPartitions,
  setObjectMeltOrder,
  setMeltAllObjectsSimultaneously,
  setTransform,
} = partitionSettingsSlice.actions;

export const selectSelection = (state: RootState): SelectionType =>
  state.partitionSettings.selection;
export const selectPartitions = (state: RootState): Partition[] =>
  state.partitionSettings.partitions;
export const selectSelectedPartitions = (state: RootState): string[] =>
  state.partitionSettings.selectedPartitions;
export const selectObjectMeltOrder = (state: RootState): number[] =>
  state.partitionSettings.objectMeltOrder;
export const selectMeltAllObjectsSimultaneously = (state: RootState): boolean =>
  state.partitionSettings.meltAllObjectsSimultaneously;
export const selectTransform = (state: RootState): boolean =>
  state.partitionSettings.transform.x !== 0 &&
  state.partitionSettings.transform.y !== 0;
export const selectTransformValue = (state: RootState): TransformCoordination =>
  state.partitionSettings.transform;

export default partitionSettingsSlice.reducer;
