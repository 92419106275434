import { FC, SVGAttributes, useCallback, useEffect, useState } from 'react';
import { Stack, useTheme } from '@mui/material';

type PathStyle = SVGAttributes<SVGPathElement>['style'];

const colors = [
  '#009d6e',
  '#d0d250',
  '#77ba80',
  '#41bbd9',
  '#abcc80',
  '#009849',
];

export const AppTransition: FC = () => {
  const { transitions } = useTheme();
  const [iteration, setIteration] = useState<boolean>(false);
  const [target, setTarget] = useState<number>(0);

  const getPathStyle = useCallback<(index: number) => PathStyle>(
    (index) => ({
      fillRule: 'evenodd',
      fill: colors[index],
      filter: `grayscale(${
        target <= index ? (iteration ? 1 : 0) : iteration ? 0 : 1
      })`,
      transition: transitions.create(['filter'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    }),
    [iteration, target, transitions],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTarget((prevTarget) => {
        if (prevTarget === 5) {
          setIteration((prevIteration) => !prevIteration);
          return 0;
        }
        return prevTarget + 1;
      });
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Stack height="100vh" justifyContent="center" alignItems="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60.78 51.24"
        style={{ width: '15%' }}
      >
        <g data-name="logo">
          <path
            d="M11.13,28.23a11.48,11.48,0,0,1,8.4,3.17,11.14,11.14,0,0,1,3.57,7.91A11,11,0,0,1,20,47.66a11.41,11.41,0,0,1-7.9,3.58A11.39,11.39,0,0,1,3.62,48.1,11.08,11.08,0,0,1,0,40.24,11.44,11.44,0,0,1,3.14,31.8,11.32,11.32,0,0,1,11.13,28.23Z"
            style={getPathStyle(0)}
          />
          <path
            d="M25.54,38.84a14.07,14.07,0,0,0-6.68-11.1,11.64,11.64,0,0,1,3.37-7.58,11.85,11.85,0,0,1,7.7-3.33A11.57,11.57,0,0,1,35.41,18a11.56,11.56,0,0,1,.7,20.45C33.46,39.91,28.71,40.56,25.54,38.84Z"
            style={getPathStyle(1)}
          />
          <path
            d="M44.38,27.48A14.08,14.08,0,0,0,37.7,16.39a11.19,11.19,0,0,1,3.37-7.54,11.54,11.54,0,0,1,7.66-3.37,11.26,11.26,0,0,1,9.21,3.95,11.61,11.61,0,0,1,2.58,10A11.26,11.26,0,0,1,55,27a12.19,12.19,0,0,1-5.05,1.52A11.83,11.83,0,0,1,44.38,27.48Z"
            style={getPathStyle(2)}
          />
          <path
            d="M41.54,5.36a13.73,13.73,0,0,0-6.23,9.92,14.24,14.24,0,0,0-9.72,0c-.66-.57-1.26-1.21-2-1.72.39-6.07,4.26-10,10.06-10.67A10.44,10.44,0,0,1,41.54,5.36Z"
            style={getPathStyle(3)}
          />
          <path
            d="M26.8,2.87a13.25,13.25,0,0,0-5.58,9.28A14.62,14.62,0,0,0,15.29,11a14.36,14.36,0,0,0-5.61,1.39A10.23,10.23,0,0,1,19,0,10,10,0,0,1,26.8,2.87Z"
            style={getPathStyle(4)}
          />
          <path
            d="M23.19,16.35A14,14,0,0,0,16.5,26.63a14.6,14.6,0,0,0-11.38.67A11,11,0,0,1,23.19,16.35Z"
            style={getPathStyle(5)}
          />
        </g>
      </svg>
    </Stack>
  );
};
