import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

import GridLines from 'assets/images/grid-lines.svg';

type MouseMoveEvent = React.MouseEvent<
  HTMLImageElement | HTMLDivElement,
  MouseEvent
>;
type TouchMoveEvent = React.TouchEvent<HTMLImageElement | HTMLDivElement>;
type MoveEvent = MouseMoveEvent | TouchMoveEvent;

const isMouseEvent = (e: MoveEvent): e is MouseMoveEvent =>
  e.type.includes('mouse');
const isTouchEvent = (e: MoveEvent): e is TouchMoveEvent =>
  e.type.includes('touch');

interface Props {
  src: string | undefined;
  zoom: number;
  size: number;
  gridLines: boolean;
  isLoading?: boolean;
}

export const AppImageMagnifier: FC<Props> = ({
  src,
  zoom,
  size,
  gridLines,
  isLoading,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const glass = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const [containerSize, setContainerSize] =
    useState<{ width: number; height: number }>();

  const imageSize = useMemo<number>(
    () =>
      containerSize ? Math.min(containerSize.height, containerSize.width) : 0,
    [containerSize],
  );

  const getCursorPosition = useCallback<
    (event: MoveEvent) => { x: number; y: number }
  >((event) => {
    let x = 0;
    let y = 0;
    if (image.current) {
      event = event || window.event;
      const { left, top } = image.current.getBoundingClientRect();
      if (isMouseEvent(event)) {
        event.preventDefault();
        x = event.pageX - left;
        y = event.pageY - top;
      }
      if (isTouchEvent(event)) {
        x = event.touches[0].pageX - left;
        y = event.touches[0].pageY - top;
      }
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
    }
    return { x, y };
  }, []);

  const handleMove = useCallback<(event: MoveEvent) => void>(
    (event) => {
      if (image.current && glass.current) {
        const offset = size / 2;
        const { offsetLeft, offsetTop } = image.current;
        const { x, y } = getCursorPosition(event);
        glass.current.style.left = `${x - size + offsetLeft + offset}px`;
        glass.current.style.top = `${y - size + offsetTop + offset}px`;
        glass.current.style.backgroundPosition = `${
          (x * zoom - size + offset) * -1
        }px ${(y * zoom - size + offset) * -1}px`;
      }
    },
    [getCursorPosition, size, zoom],
  );

  useEffect(() => {
    if (!container.current) return;
    const { current: element } = container;
    const observer = new ResizeObserver((entries) => {
      entries.forEach(({ contentRect }) => {
        setContainerSize({
          width: contentRect.width,
          height: contentRect.height,
        });
      });
    });
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, []);

  return (
    <Stack
      ref={container}
      height={1}
      width={1}
      position="relative"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
    >
      {gridLines && (
        <img
          src={GridLines}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.3,
          }}
          alt="grid-lines"
        />
      )}
      {isLoading ? (
        <Skeleton
          variant="circular"
          sx={{ width: 'auto', height: 1, aspectRatio: '1' }}
        />
      ) : (
        <img
          src={src}
          ref={image}
          onMouseMove={handleMove}
          onTouchMove={handleMove}
          height={imageSize}
          width={imageSize}
          alt="selected-layer"
        />
      )}
      {zoom > 1 && (
        <Box
          ref={glass}
          onMouseMove={handleMove}
          onTouchMove={handleMove}
          sx={{
            width: size,
            height: size,
            position: 'absolute',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${src})`,
            backgroundSize: `${imageSize * zoom}px ${imageSize * zoom}px`,
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: 'background.paper',
            borderRadius: '50%',
            cursor: 'none',
          }}
        />
      )}
    </Stack>
  );
};
