import { forwardRef } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps {
  value: string | number;
  onChange: (value: string | number) => void;
  buttons: { value: string | number; text: string }[];
  componentProps?: ToggleButtonGroupProps;
}

export const AppToggleButtonForm = forwardRef<HTMLInputElement, Props>(
  (
    { label, value, onChange, buttons, componentProps, ...formControlProps },
    ref,
  ) => {
    return (
      <AppFormControl label={label} {...formControlProps}>
        <ToggleButtonGroup
          ref={ref}
          value={value}
          onChange={(_, value: string | number) => {
            onChange(value);
          }}
          exclusive
          size="small"
          sx={{ mt: 2 }}
          {...componentProps}
        >
          {buttons.map(({ value, text }, key) => (
            <ToggleButton key={key} value={value} sx={{ minWidth: 120 }}>
              {text}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </AppFormControl>
    );
  },
);
AppToggleButtonForm.displayName = 'AppToggleButtonForm';
