import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dialog, Stack, useMediaQuery, Theme, Button } from '@mui/material';

import { ReactComponent as FreemeltPixelmeltLogo } from 'assets/logo/freemelt-pixelmelt.svg';
import { AppTextForm } from 'components/AppFormControl';
import { useAuthentication, useSearchParams, useSnackbar } from 'hooks';
import { validateNewPassword } from 'helpers';
// Redux
import { useSelector } from 'store';
import { selectState } from 'slices/userSlice';

type SetPasswordKeys = 'currentPassword' | 'newPassword' | 'confirmPassword';

export const SetPassword: FC = () => {
  const { t } = useTranslation();
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'), {
    noSsr: true,
  });
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { isLoading } = useSelector(selectState);
  const { setPassword } = useAuthentication();
  const [{ email }] = useSearchParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [validationError, setValidationError] = useState<SetPasswordKeys[]>([]);
  const refs = useMemo<Record<SetPasswordKeys, RefObject<HTMLInputElement>>>(
    () => ({
      currentPassword: createRef<HTMLInputElement>(),
      newPassword: createRef<HTMLInputElement>(),
      confirmPassword: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const handleConfirm = useCallback<() => void>(() => {
    if (!validateNewPassword(newPassword)) {
      setValidationError(['newPassword']);
      refs.newPassword.current?.focus();
    } else if (newPassword !== confirmPassword) {
      setValidationError(['confirmPassword']);
      refs.confirmPassword.current?.focus();
    } else if (email) {
      setPassword(email, currentPassword, newPassword)
        .then(() => {
          closeSnackbar('confirm_user_success');
          enqueueSnackbar({
            key: 'create_password_success',
            message: t('create_password_success'),
            variant: 'success',
          });
        })
        .catch((e) => {
          closeSnackbar('confirm_user_success');
          if (e.type === 'change-password-failed') {
            enqueueSnackbar({
              key: 'change_password_fail',
              message: t('change_password_fail'),
              variant: 'error',
            });
          } else {
            enqueueSnackbar({
              key: `create_password_fail_${Date.now()}`,
              message: t('create_password_fail'),
              variant: 'error',
            });
          }
        });
    }
  }, [
    newPassword,
    confirmPassword,
    email,
    enqueueSnackbar,
    t,
    refs.newPassword,
    refs.confirmPassword,
    setPassword,
    currentPassword,
    closeSnackbar,
  ]);

  useEffect(() => {
    setValidationError([]);
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (!email) navigate('/', { replace: true });
  }, [enqueueSnackbar, navigate, t, email]);

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={xs}>
      <Stack
        height={1}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        py={10}
      >
        <Stack />
        <Stack width={0.6} spacing={2}>
          <AppTextForm
            label={t('current_password')}
            helperText={t('current_password_helper')}
            value={currentPassword}
            onChange={setCurrentPassword}
            componentProps={{
              type: 'password',
              autoComplete: 'current-password',
              inputRef: refs.currentPassword,
            }}
            error={validationError.includes('currentPassword')}
            errorText={t('validations:create_password.currentPassword')}
          />
          <AppTextForm
            label={t('new_password')}
            helperText={t('new_password_helper')}
            value={newPassword}
            onChange={setNewPassword}
            componentProps={{
              type: 'password',
              autoComplete: 'new-password',
              inputRef: refs.newPassword,
            }}
            error={validationError.includes('newPassword')}
            errorText={t('validations:create_password.password')}
          />
          <AppTextForm
            label={t('confirm_password')}
            helperText={t('confirm_password_helper')}
            value={confirmPassword}
            onChange={setConfirmPassword}
            componentProps={{
              type: 'password',
              autoComplete: 'new-password',
              inputRef: refs.confirmPassword,
            }}
            error={validationError.includes('confirmPassword')}
            errorText={t('validations:create_password.confirmPassword')}
          />
          <Button
            onClick={handleConfirm}
            variant="contained"
            disabled={isLoading}
            fullWidth
          >
            {t('create_password')}
          </Button>
        </Stack>
        <FreemeltPixelmeltLogo style={{ width: '50%', alignSelf: 'center' }} />
      </Stack>
    </Dialog>
  );
};
