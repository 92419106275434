export type ValidateNumberOptions = {
  min?: number;
  max?: number;
  orEqual?: boolean;
  noDecimal?: boolean;
};

export const validateNumber = (
  value: string | number,
  options: ValidateNumberOptions | undefined = {},
): boolean => {
  const n: number = typeof value === 'number' ? value : parseFloat(value);
  return (
    !isNaN(n) &&
    isFinite(n) &&
    (options.min === undefined ||
      isNaN(options.min) ||
      (options.orEqual !== false ? n >= options.min : n > options.min)) &&
    (options.max === undefined ||
      isNaN(options.max) ||
      (options.orEqual !== false ? n <= options.max : n < options.max)) &&
    (!options.noDecimal || n % 1 === 0)
  );
};
