import { FC, useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button, Typography, IconButton } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import { deleteUser, ErrorResponse, UserDetailsWithEtag } from 'api';
import { useAuthentication, useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DeleteAccountDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const passwordRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<boolean>(false);
  const { authenticateCurrentUser } = useAuthentication();

  const handleClose = useCallback<() => void>(() => {
    setPassword('');
    onClose();
  }, [onClose]);

  const { data: currentUser } = useQuery<UserDetailsWithEtag>('current-user');

  const { mutate: onDeleteAccount, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    'current-user',
    () => {
      if (!currentUser) throw new Error('current user does not exist');
      return deleteUser(currentUser.data.id, currentUser.etag);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_account_success_${Date.now()}`,
          message: t('delete_account_success'),
          variant: 'success',
        });
        handleClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'delete_account_decrepit',
            message: t('delete_account_decrepit', {
              email: currentUser?.data.email,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient.refetchQueries('current-user').then(() => {
                    closeSnackbar('delete_user_decrepit');
                  });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `delete_account_fail_${Date.now()}`,
            message: t('delete_account_fail', {
              email: currentUser?.data.email,
            }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  const handleSubmit = useCallback<() => void>(() => {
    authenticateCurrentUser(password)
      .then(() => onDeleteAccount())
      .catch(() => {
        setValidationError(true);
        enqueueSnackbar({
          key: `delete_account_fail_${Date.now()}`,
          message: t('delete_account_fail', {
            email: currentUser?.data.email,
          }),
          variant: 'error',
          persist: true,
        });
      });
  }, [
    authenticateCurrentUser,
    password,
    onDeleteAccount,
    enqueueSnackbar,
    t,
    currentUser?.data.email,
  ]);

  useEffect(() => {
    setValidationError(false);
  }, [password]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('delete_account')}
      actions={
        <Button
          onClick={handleSubmit}
          disabled={isDeleting}
          color="error"
          variant="contained"
          startIcon={<DeleteIcon />}
          fullWidth
        >
          {t('delete_account')}
        </Button>
      }
    >
      <Stack spacing={2}>
        <Typography>{t('delete_account_confirmation')}</Typography>
        <AppTextForm
          label={t('password')}
          value={password}
          onChange={setPassword}
          componentProps={{
            type: 'password',
            autoComplete: 'current-password',
            inputRef: passwordRef,
          }}
          error={validationError}
          errorText={t('validations:delete_account.password')}
        />
      </Stack>
    </AppDialog>
  );
};
