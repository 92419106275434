import { FC, MutableRefObject, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Tooltip, IconButton } from '@mui/material';
import { SettingsRounded as OptionsIcon } from '@mui/icons-material';

interface Props {
  anchor: MutableRefObject<HTMLDivElement | null>;
  children: ReactNode;
}

export const ProjectPanelOptions: FC<Props> = ({ anchor, children }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Tooltip title={t('open_panel_options') || ''}>
        <IconButton
          color={anchorEl ? 'primary' : 'default'}
          onClick={() => {
            setAnchorEl(anchor.current);
          }}
        >
          <OptionsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: ({ spacing }) => `calc(300px - ${spacing(4)})`,
            p: 2,
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
};
