import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { AddRounded as AddIcon } from '@mui/icons-material';

export interface Props extends ButtonProps {
  tooltip: string;
}

export const AddActionButton = forwardRef<HTMLButtonElement, Props>(
  ({ tooltip, ...props }, ref) => {
    const { t } = useTranslation();

    return (
      <Tooltip title={tooltip}>
        <Button
          ref={ref}
          size="small"
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          {...props}
        >
          {t('new')}
        </Button>
      </Tooltip>
    );
  },
);
AddActionButton.displayName = 'AddActionButton';
