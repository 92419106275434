interface ConvertedValue {
  value: number;
  unit: string;
}
/**
 * Convert kilobytes to a more presentable unit
 * @param {number} value
 * @returns {ConvertedValue}
 */
export const convertKiloBytesToSize = (value: number): ConvertedValue => {
  const sizes = [
    'kilobyte',
    'megabyte',
    'gigabyte',
    'terabyte',
    'petabyte',
    'exabyte',
    'zettabyte',
    'yottabyte',
  ];

  const i = Math.floor(Math.log(value) / Math.log(1000));

  return i > 0
    ? {
        value: parseFloat((value / Math.pow(1000, i)).toFixed(2)),
        unit: sizes[i],
      }
    : { value, unit: sizes[0] };
};
