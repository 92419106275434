import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Tooltip,
  IconButton,
  TableRow,
  TableCell,
  Stack,
  Divider,
} from '@mui/material';
import {
  ArrowCircleUpRounded as MoveUpIcon,
  ArrowCircleDownRounded as MoveDownIcon,
} from '@mui/icons-material';

import ProjectAddPartitionForm from 'components/ProjectAddPartitionForm';
import {
  addWithDecimals,
  subtractWithDecimals,
  countDecimals,
  getSelectionMode,
} from 'helpers';
import { ModelObjectInfo, Partition } from 'api';
// Redux
import { SelectPartitionPayload } from 'slices/partitionSettingsSlice';

const ROW_HEIGHT = 52;

interface Props extends ModelObjectInfo {
  partitions: Partition[];
  objectMeltOrder: number[];
  selected?: boolean;
  onAddPartition?: (
    breakpoint: [number, number],
    objectID?: number | undefined,
  ) => void;
  onSelectPartition?: (payload: SelectPartitionPayload) => void;
  onChangeObjectOrder?: (id: number, steps: number) => void;
  onChangeMeltSettingsPreset?: () => void;
}

export const TreeItemObject: FC<Props> = ({
  objectID,
  name,
  startZ,
  endZ,
  layerThickness,
  partitions,
  objectMeltOrder,
  selected,
  onAddPartition,
  onSelectPartition,
  onChangeObjectOrder,
  onChangeMeltSettingsPreset,
  children,
}) => {
  const { t } = useTranslation();

  const numberOfDecimals = useMemo<number>(
    () => Math.max(...[startZ, endZ].map((n) => countDecimals(n))),
    [startZ, endZ],
  );

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = startZ;
    while (i < endZ) {
      result.push(i);
      i = addWithDecimals(numberOfDecimals, i, layerThickness);
    }
    return result;
  }, [startZ, endZ, numberOfDecimals, layerThickness]);

  const handleAddPartition = useCallback<(breakpoint: number) => void>(
    (breakpoint: number) => {
      if (onAddPartition) {
        onAddPartition(
          [
            breakpoint,
            addWithDecimals(numberOfDecimals, breakpoint, layerThickness),
          ],
          objectID,
        );
      }
    },
    [onAddPartition, numberOfDecimals, layerThickness, objectID],
  );

  const handleSelectPartition = useCallback<
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  >(
    (e) => {
      e.stopPropagation();
      if (onSelectPartition) {
        onSelectPartition({ target: objectID, mode: getSelectionMode(e) });
      }
    },
    [onSelectPartition, objectID],
  );

  const handleChangeOrder = useCallback<(steps: number) => () => void>(
    (steps) => () => {
      if (onChangeObjectOrder) onChangeObjectOrder(objectID, steps);
    },
    [onChangeObjectOrder, objectID],
  );

  return (
    <>
      <TableRow
        hover={!!onSelectPartition}
        sx={{
          cursor: onSelectPartition ? 'pointer' : 'default',
          border: 'none',
          height: ROW_HEIGHT,
        }}
        onClick={handleSelectPartition}
        selected={selected}
      >
        <TableCell colSpan={2} sx={{ py: 0 }}>
          <Stack direction="row" alignItems="center" spacing={2} pl={2}>
            <Divider orientation="vertical" sx={{ height: ROW_HEIGHT }} />
            <Tooltip
              title={
                name ? (t('object_{{id}}', { id: objectID }) as string) : ''
              }
              placement="left"
              arrow
            >
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {name ?? t('object_{{id}}', { id: objectID })}
              </Typography>
            </Tooltip>
            {onChangeObjectOrder && (
              <Stack direction="row" spacing={1}>
                <Tooltip title={t('change_object_melt_order_up') as string}>
                  <span>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={handleChangeOrder(-1)}
                      disabled={objectMeltOrder.indexOf(objectID) < 1}
                    >
                      <MoveUpIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={t('change_object_melt_order_down') as string}>
                  <span>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={handleChangeOrder(+1)}
                      disabled={
                        objectMeltOrder.indexOf(objectID) >=
                        objectMeltOrder.length - 1
                      }
                    >
                      <MoveDownIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {subtractWithDecimals(numberOfDecimals, startZ, layerThickness)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{endZ}</Typography>
        </TableCell>
        {onChangeMeltSettingsPreset && <TableCell />}
        <TableCell align="right">
          {onAddPartition && (
            <ProjectAddPartitionForm
              iconButtonProps={{ disabled: !onAddPartition }}
              onAddPartition={handleAddPartition}
              options={options}
              getOptionDisabled={(option) =>
                partitions.map(({ endZ }) => endZ).includes(option)
              }
              getOptionLabel={(option) => String(option)}
            />
          )}
        </TableCell>
      </TableRow>
      {children}
    </>
  );
};
