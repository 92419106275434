import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Button, Grid, Stack, Typography } from '@mui/material';
import {
  LaunchRounded as OpenIcon,
  ContentCopyRounded as DuplicateIcon,
  DeleteRounded as DeleteIcon,
  DownloadRounded as DownloadIcon,
} from '@mui/icons-material';

import AppDetailsDrawer from 'components/AppDetailsDrawer';
import {
  DuplicateProjectDialog,
  DeleteProjectDialog,
} from 'containers/Projects';
import Project3DView from 'components/Project3DView';
import {
  getProjectBuildFileLink,
  getProjectDetails,
  ProjectState,
  UserDetailsWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';
import {
  checkWritePermission,
  convertKiloBytesToSize,
  countDecimals,
  subtractWithDecimals,
} from 'helpers';

export const AppProjectDetails: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [{ rowId: projectId }, { deleteSearchParam }] = useSearchParams();

  const { data: details, isLoading } = useQuery(
    ['project', { projectId }],
    () => {
      if (projectId) return getProjectDetails(projectId);
    },
    {
      enabled: !!projectId,
      onError: () => {
        enqueueSnackbar({
          key: `get_project_details_fail_${Date.now()}`,
          message: t('get_project_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const detailsStorageSizeArguments = useMemo(() => {
    const { value, unit } = convertKiloBytesToSize(
      details?.data.totalS3StorageSize || 0,
    );
    return { value, unit: t(`unit:${unit}`) };
  }, [details?.data.totalS3StorageSize, t]);

  const { data: currentUser } = useQuery<UserDetailsWithEtag>('current-user');

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, details?.data.createdBy),
    [currentUser?.data, details?.data.createdBy],
  );

  const { mutate: downloadBuildFile, isLoading: isPendingDownloadLink } =
    useMutation({
      mutationFn: (id: string) => getProjectBuildFileLink(id),
      onSuccess: (link) => {
        const anchor = document.createElement('a');
        anchor.href = link;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      },
      onError: () => {
        enqueueSnackbar({
          key: `download_build_files_fail_${Date.now()}`,
          message: t('download_build_files_fail'),
          variant: 'error',
        });
      },
    });

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  if (isLoading) return <AppDetailsDrawer open onClose={onClose} isLoading />;

  if (details)
    return (
      <>
        <AppDetailsDrawer
          open
          onClose={onClose}
          title={details.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => navigate(details.data.id)}
                variant="outlined"
                startIcon={<OpenIcon />}
                fullWidth
              >
                {t('open')}
              </Button>
              <Button
                onClick={() => setDuplicateDialog(true)}
                variant="outlined"
                color="secondary"
                startIcon={<DuplicateIcon />}
                fullWidth
              >
                {t('duplicate')}
              </Button>
              <Button
                onClick={() => setDeleteDialog(true)}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                disabled={!userHasWritePermission}
                fullWidth
              >
                {t('delete')}
              </Button>
            </Stack>
          }
        >
          <Stack spacing={1} flex={1}>
            <Typography variant="h5">{t('model')}</Typography>
            <Grid container flex={1}>
              <Grid
                item
                xs={12}
                lg={5}
                pr={{ xs: 0, lg: 2 }}
                pb={{ xs: 2, lg: 0 }}
              >
                <Typography flex={1}>
                  {t('details_fileName', {
                    value: details.data.cadModel.name,
                  })}
                </Typography>
                <Typography flex={1}>
                  {t('details_layerThickness', {
                    value: details.data.cadModel.fileInfo.layerThickness,
                  })}
                </Typography>
                <Typography flex={1}>
                  {t('details_height', {
                    value:
                      details.data.cadModel.fileInfo.endZ -
                      subtractWithDecimals(
                        Math.max(
                          ...[
                            details.data.cadModel.fileInfo.startZ,
                            details.data.cadModel.fileInfo.endZ,
                            details.data.cadModel.fileInfo.layerThickness,
                          ].map((n) => countDecimals(n)),
                        ),
                        details.data.cadModel.fileInfo.startZ,
                        details.data.cadModel.fileInfo.layerThickness,
                      ),
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={7}
                sx={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'text.primary',
                  borderRadius: ({ shape }) => `${shape.borderRadius}px`,
                  boxSizing: 'border-box',
                }}
              >
                <Project3DView model={details.data.cadModel} />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5">{t('project_info')}</Typography>
            <Typography>
              {t('details_material', { value: details.data.material })}
            </Typography>
            <Typography>
              {t('details_notes', { value: details.data.notes })}
            </Typography>
            <Typography>
              {t('details_storage_size', detailsStorageSizeArguments)}
            </Typography>
            <Typography>
              {t('details_created', {
                value: details.data.createdBy
                  ? `${details.data.createdBy.email} (${t('dateTime', {
                      value: new Date(details.data.createdDate),
                    })})`
                  : t('dateTime', {
                      value: new Date(details.data.createdDate),
                    }),
              })}
            </Typography>
            <Typography>
              {t('details_updated', {
                value: details.data.updatedBy
                  ? `${details.data.updatedBy.email} (${t('dateTime', {
                      value: new Date(details.data.updatedDate),
                    })})`
                  : t('dateTime', {
                      value: new Date(details.data.updatedDate),
                    }),
              })}
            </Typography>
          </Stack>
          <Stack spacing={1} flex={1}>
            <Typography variant="h5">{t('build_files')}</Typography>
            <Typography>
              {t('details_status', {
                value:
                  t(`projectState:${details.data.state}`) +
                  (details.data.state === ProjectState.Generating
                    ? ` (${details.data.progressPercentage}%)`
                    : ''),
              })}
            </Typography>
            <Button
              onClick={() => {
                if (projectId) downloadBuildFile(projectId);
              }}
              color="inherit"
              variant="outlined"
              startIcon={<DownloadIcon />}
              disabled={
                !projectId ||
                isPendingDownloadLink ||
                details.data.state !== ProjectState.GeneratedSuccessfully
              }
              fullWidth
            >
              {t('download_build_files')}
            </Button>
          </Stack>
        </AppDetailsDrawer>
        <DuplicateProjectDialog
          open={duplicateDialog}
          onClose={() => setDuplicateDialog(false)}
          projectId={details.data.id}
        />
        {userHasWritePermission && (
          <DeleteProjectDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            projectId={details.data.id}
          />
        )}
      </>
    );

  return null;
};
