import { useState, useRef, FC, MutableRefObject } from 'react';
import {
  Popover,
  Button,
  IconButton,
  ButtonProps,
  IconButtonProps,
} from '@mui/material';
import { RemoveCircleOutlineRounded as RemoveIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  iconButtonProps: IconButtonProps;
  onRemovePartition: (effect: 'before' | 'after') => void;
  disabled: { before: boolean; after: boolean };
}

export const ProjectRemovePartitionForm: FC<Props> = ({
  iconButtonProps,
  onRemovePartition,
  disabled,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const anchorEl = useRef() as MutableRefObject<HTMLButtonElement>;

  return (
    <>
      <IconButton
        color="error"
        onClick={() => {
          setOpen(true);
        }}
        ref={anchorEl}
        {...iconButtonProps}
      >
        <RemoveIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
      >
        <PopoverButton
          onClick={() => onRemovePartition('before')}
          disabled={disabled.before}
        >
          {t('merge_before')}
        </PopoverButton>
        <PopoverButton
          onClick={() => onRemovePartition('after')}
          disabled={disabled.after}
        >
          {t('merge_after')}
        </PopoverButton>
      </Popover>
    </>
  );
};

const PopoverButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      color="error"
      sx={{
        borderRadius: 0,
        color: 'text.primary',
        '&:hover': {
          color: 'error.main',
        },
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
