import { FC, ReactNode, useEffect, useMemo } from 'react';
import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { PromptProvider } from 'providers';
import AppTransition from 'components/AppTransition';
import {
  ErrorResponse,
  getCurrentUser,
  UserDetailsWithEtag,
  UserRole,
} from 'api';
import { useSnackbar, useAuthentication } from 'hooks';
// Containers
import Home from 'containers/Home';
import Projects from 'containers/Projects';
import Presets from 'containers/Presets';
import Models from 'containers/Models';
import Project from 'containers/Project';
import UserGuide from 'containers/UserGuide';
import ReleaseNotes from 'containers/ReleaseNotes';
import Admin from 'containers/Admin';
import Account from 'containers/Account';
import Organizations from 'containers/Organizations';

export interface HomeRoute {
  path: string;
  element: ReactNode;
  title: string;
  claims: UserRole[];
  group: 'management' | 'help' | 'settings';
}

export const homeRoutes: HomeRoute[] = [
  {
    path: '/projects',
    element: <Projects />,
    title: 'projects',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'management',
  },
  {
    path: '/presets',
    element: <Presets />,
    title: 'presets',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'management',
  },
  {
    path: '/models',
    element: <Models />,
    title: 'models',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'management',
  },
  {
    path: '/user-guide',
    element: <UserGuide />,
    title: 'user-guide',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'help',
  },
  {
    path: '/release-notes',
    element: <ReleaseNotes />,
    title: 'release-notes',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'help',
  },
  {
    path: '/account',
    element: <Account />,
    title: 'account',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin, UserRole.User],
    group: 'settings',
  },
  {
    path: '/admin',
    element: <Admin />,
    title: 'admin',
    claims: [UserRole.SuperAdmin, UserRole.OrganizationAdmin],
    group: 'settings',
  },
  {
    path: '/organizations',
    element: <Organizations />,
    title: 'organizations',
    claims: [UserRole.SuperAdmin],
    group: 'settings',
  },
];

export const Private: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { logout, refreshSession } = useAuthentication();

  const { data: currentUser, isLoading } = useQuery<
    UserDetailsWithEtag,
    AxiosError<ErrorResponse>
  >('current-user', getCurrentUser, {
    staleTime: 0,
    onError: () => {
      enqueueSnackbar({
        key: `get_current_user_fail_${Date.now()}`,
        message: t('get_current_user_fail'),
        variant: 'error',
      });
      logout();
    },
  });

  const filteredHomeRoutes = useMemo<HomeRoute[]>(
    () =>
      homeRoutes.filter(
        ({ claims }) => currentUser && claims.includes(currentUser.data.type),
      ),
    [currentUser],
  );

  useEffect(() => {
    const interval = refreshSession();
    return () => {
      clearInterval(interval);
    };
  }, [refreshSession]);

  if (isLoading) return <AppTransition />;

  return (
    <>
      <BrowserRouter>
        <ReactRoutes>
          <Route
            path="/projects/:projectId"
            element={
              <PromptProvider>
                <Project />
              </PromptProvider>
            }
          />
          <Route
            path="/*"
            element={
              <Home>
                <ReactRoutes>
                  {filteredHomeRoutes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                  ))}
                  <Route
                    path="*"
                    element={<Navigate to="/projects" replace />}
                  />
                </ReactRoutes>
              </Home>
            }
          />
        </ReactRoutes>
      </BrowserRouter>
    </>
  );
};
