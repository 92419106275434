import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export interface User {
  id: string;
  email: string;
  verified: boolean;
}

export interface UserState {
  user: User | null; // user object
  accessToken: string | undefined; // access token in jwt string format
  isAuthenticated: boolean; // application has tried to authenticate user initially
  isLoading: boolean; // application is authenticating user
  isSuccess: boolean; // authentication is successful and user is logged in
  isError: boolean; // authentication caught error
}

const initialState: UserState = {
  user: null,
  accessToken: undefined,
  isAuthenticated: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initAuth: (state) => {
      state.user = null;
      state.accessToken = undefined;
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    },
    onAuthSuccess: (
      state,
      { payload }: { payload: { user: User | null; accessToken?: string } },
    ) => {
      state.user = payload.user;
      state.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.isSuccess = !!payload.user;
      state.isError = false;
    },
    onAuthFail: (state) => {
      state.user = null;
      state.accessToken = undefined;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    },
  },
});

export const { initAuth, onAuthSuccess, onAuthFail } = userSlice.actions;

export const selectUser = (state: RootState): User | null => state.user.user;
export const selectAccessToken = (state: RootState): string | undefined =>
  state.user.accessToken;
export const selectState = (
  state: RootState,
): Omit<UserState, 'user' | 'accessToken'> => ({
  isAuthenticated: state.user.isAuthenticated,
  isLoading: state.user.isLoading,
  isSuccess: state.user.isSuccess,
  isError: state.user.isError,
});

export default userSlice.reducer;
