import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Paper } from '@mui/material';

import ProjectMenuPanel from 'components/ProjectMenuPanel';
import ProjectTreeView from 'components/ProjectTreeView';
import ProjectPartitionForm from 'components/ProjectPartitionForm';
import { ModelDetails, ProjectSettingsResponseWithEtag } from 'api';
import { useKeyboardShortcut } from 'hooks';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  setSelectedPartitions,
  selectPartitions,
  selectSelection,
  selectSelectedPartitions,
  selectObjectMeltOrder,
} from 'slices/partitionSettingsSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const SelectionPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const dispatch = useDispatch();
  const partitions = useSelector(selectPartitions);
  const selection = useSelector(selectSelection);
  const selectedPartitions = useSelector(selectSelectedPartitions);
  const objectMeltOrder = useSelector(selectObjectMeltOrder);

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const model = useMemo<ModelDetails | undefined>(
    () => projectSettings?.data.cadModel,
    [projectSettings?.data.cadModel],
  );

  useKeyboardShortcut({ key: 'a', ctrl: true }, () => {
    dispatch(setSelectedPartitions({ target: -1, mode: 'multiple' }));
  });

  return (
    <ProjectMenuPanel
      title={t('selection')}
      options={
        <ProjectPartitionForm
          modelInfo={model?.fileInfo}
          modelName={model?.name}
          disabled={!userHasWritePermission || generateHasInitiated}
        />
      }
    >
      <Paper
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(2)})`,
          pl: 2,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <ProjectTreeView
          modelInfo={model?.fileInfo}
          modelName={model?.name}
          partitions={partitions}
          selection={selection}
          selectedPartitions={selectedPartitions}
          objectMeltOrder={objectMeltOrder}
          onSelectPartition={(payload) => {
            dispatch(setSelectedPartitions(payload));
          }}
        />
      </Paper>
    </ProjectMenuPanel>
  );
};
