import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps {
  componentProps?: ButtonProps;
}

export const AppButtonForm: FC<Props> = ({
  label,
  componentProps,
  ...formControlProps
}) => {
  return (
    <AppFormControl label={label} {...formControlProps} hideFormLabel>
      <Button variant="outlined" color="inherit" fullWidth {...componentProps}>
        {label}
      </Button>
    </AppFormControl>
  );
};
