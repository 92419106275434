import { FC } from 'react';
import { Object3D, Color } from 'three';

import { useMaterial } from 'hooks';

interface Props {
  mesh: Object3D;
  height: { startZ: number; endZ: number };
  highlights: { startZ: number[]; endZ: number[]; size: number };
}

export const ProjectMesh: FC<Props> = ({ mesh, height, highlights }) => {
  const material = useMaterial({
    color: new Color(0.7, 0.7, 0.7),
    onBeforeCompile: highlights.size
      ? (shader) => {
          shader.uniforms.startZ = { value: highlights.startZ };
          shader.uniforms.endZ = { value: highlights.endZ };
          shader.uniforms.size = { value: highlights.size };
          shader.uniforms.objectStart = { value: height.startZ };
          shader.uniforms.objectEnd = { value: height.endZ };
          shader.vertexShader = `varying vec3 vPos;
${shader.vertexShader}`.replace(
            `#include <begin_vertex>`,
            `
    #include <begin_vertex>
    vPos = vec3(position);`,
          );
          shader.fragmentShader = `
uniform float startZ[450];
uniform float endZ[450];
uniform int size;
uniform float objectStart;
uniform float objectEnd;
varying vec3 vPos;
${shader.fragmentShader}`.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
            `
  vec3 color = vec3(0.7, 0.7, 0.7);
  for (int i = 0; i < size; i++) {
    if (vPos.z >= startZ[i] && vPos.z <= endZ[i]) {
      color = vec3(0.35, 0.7, 0.45);
    };
  };
  if (vPos.z < objectStart || vPos.z > objectEnd) {
    color = vec3(1, 0.04, 0.04);
  };
  vec4 diffuseColor = vec4( color, opacity );
`,
          );
        }
      : (shader) => {
          shader.uniforms.objectStart = { value: height.startZ };
          shader.uniforms.objectEnd = { value: height.endZ };
          shader.vertexShader = `varying vec3 vPos;
${shader.vertexShader}`.replace(
            `#include <begin_vertex>`,
            `
  #include <begin_vertex>
  vPos = vec3(position);`,
          );
          shader.fragmentShader = `
uniform float objectStart;
uniform float objectEnd;
varying vec3 vPos;
${shader.fragmentShader}`.replace(
            `vec4 diffuseColor = vec4( diffuse, opacity );`,
            `
vec3 color = vec3(0.7, 0.7, 0.7);
if (vPos.z < objectStart || vPos.z > objectEnd) {
  color = vec3(1, 0.04, 0.04);
};
vec4 diffuseColor = vec4( color, opacity );
`,
          );
        },
  });

  return <mesh {...mesh} material={material} />;
};
