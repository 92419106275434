import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  EditRounded as UpdateIcon,
  ForwardToInboxRounded as ResendIcon,
} from '@mui/icons-material';

import AppTable, { TableColumn, TableData } from 'components/AppTable';
import {
  CreateUserDialog,
  UpdateUserDialog,
  DeleteUserDialog,
} from 'containers/Admin';
import {
  ErrorResponse,
  getUsers,
  FilteredUsers,
  UserDetailsWithEtag,
  UserRole,
} from 'api';
import { useSnackbar, useSearchParams, useAuthentication } from 'hooks';

const tableColumns: TableColumn[] = [
  { name: 'email', label: 'email' },
  { name: 'type', label: 'role' },
  { name: 'cognitoStatus', label: 'status' },
  { name: '', label: null, sortDisabled: true },
];

const isColumnName = (sortBy: string | undefined): sortBy is string =>
  tableColumns.some(({ name }) => name === sortBy);
const isDirection = (sortDir: string | undefined): sortDir is 'asc' | 'desc' =>
  sortDir === 'asc' || sortDir === 'desc';

export const Admin: FC = () => {
  const { t } = useTranslation();
  const [
    { pageSize, currentPage, sortBy, sortDir, search },
    { updateSearchParam, deleteSearchParam },
  ] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const { resendConfirmationCode } = useAuthentication();
  const [userToUpdate, setUserToUpdate] = useState<string>();
  const [userToDelete, setUserToDelete] = useState<string>();

  const [activeFilters, setActiveFilters] = useState<
    Partial<Record<'type' | 'cognitoStatus', string[]>>
  >({});

  const params = useMemo(
    () => ({
      size: pageSize ? +pageSize : 25,
      page: currentPage ? +currentPage : 1,
      sort:
        isColumnName(sortBy) && isDirection(sortDir)
          ? { columnName: sortBy, direction: sortDir }
          : null,
      search: search || undefined,
      filters: activeFilters,
    }),
    [activeFilters, currentPage, pageSize, search, sortBy, sortDir],
  );

  const {
    data: users,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery<FilteredUsers, AxiosError<ErrorResponse>>(
    ['users', params],
    () =>
      getUsers({
        top: params.size,
        skip: (params.page - 1) * params.size,
        orderBy: params.sort
          ? `${params.sort.columnName} ${params.sort.direction}`
          : undefined,
        filter: {
          'tolower(name)': params.search
            ? { contains: params.search.toLowerCase() }
            : undefined,
          ...Object.entries(params.filters).reduce(
            (prevValue, [key, value]) =>
              value.length
                ? Object.assign(prevValue, { [key]: { in: value } })
                : prevValue,
            {},
          ),
        },
      }),
    {
      keepPreviousData: true,
      staleTime: 0,
      onError: () => {
        enqueueSnackbar({
          key: `get_users_fail_${Date.now()}`,
          message: t('get_users_fail'),
          variant: 'error',
        });
      },
    },
  );

  const { data: currentUser } = useQuery<UserDetailsWithEtag>('current-user');

  const handleResendVerificationCode = useCallback<(email: string) => void>(
    (email) => {
      resendConfirmationCode(email)
        .then(() => {
          enqueueSnackbar({
            key: `resend_verification_email_success_${Date.now()}`,
            message: t('resend_verification_email_success', { email }),
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar({
            key: `resend_verification_email_fail_${Date.now()}`,
            message: t('resend_verification_email_fail', { email }),
            variant: 'error',
          });
        });
    },
    [enqueueSnackbar, resendConfirmationCode, t],
  );

  const tableData = useMemo<TableData>(
    () => ({
      rows:
        (users?.values ?? []).map((user) => ({
          id: user.id,
          hover: true,
          values: [
            { value: user.email },
            { value: t(`userRole:${user.type}`) },
            { value: t(`userStatus:${user.cognitoStatus}`) },
            {
              value: (
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  {user.cognitoStatus !== 'CONFIRMED' && (
                    <Button
                      onClick={() => handleResendVerificationCode(user.email)}
                      color="secondary"
                      startIcon={<ResendIcon />}
                      sx={{ mr: 2 }}
                    >
                      {t('resend_request')}
                    </Button>
                  )}
                  {currentUser?.data.id !== user.id && (
                    <>
                      <Button
                        onClick={() => setUserToUpdate(user.id)}
                        variant="outlined"
                        color="inherit"
                        startIcon={<UpdateIcon />}
                      >
                        {t('reassign')}
                      </Button>
                      <Button
                        onClick={() => setUserToDelete(user.id)}
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                      >
                        {t('delete')}
                      </Button>
                    </>
                  )}
                </Stack>
              ),
              sx: {
                width: 450,
                height: 70,
              },
            },
          ],
        })) ?? [],
    }),
    [currentUser?.data.id, handleResendVerificationCode, t, users?.values],
  );

  return (
    <>
      <Stack direction="row" height={1} overflow="hidden" spacing={2} m={2}>
        <AppTable
          columns={tableColumns}
          data={tableData}
          name={t('users')}
          noDataText={
            isError || isRefetchError
              ? t('users_table_no_data_error')
              : t('users_table_no_data')
          }
          sx={{ flex: 2 }}
          tableProps={{ stickyHeader: true }}
          headerProps={{
            add: {
              tooltip: t('new_user'),
              onClick: () => setCreateUserDialog(true),
            },
            filter: {
              filters: [
                {
                  title: 'type',
                  label: 'role',
                  options: Object.values(UserRole).map((value) => ({
                    value,
                    label: t(`userRole:${value}`),
                  })),
                },
              ],
              activeFilters,
              onFilter: setActiveFilters,
            },
            search: {
              searchPhrase: params.search ?? '',
              onSearch: (value) =>
                value
                  ? updateSearchParam(['search', value])
                  : deleteSearchParam('search'),
            },
          }}
          pagination={{
            pageSize: params.size,
            currentPage: params.page,
            totalCount: users?.count,
          }}
          sort={params.sort}
          fetchDate={new Date(dataUpdatedAt)}
          isError={isError}
          isRefetchError={isRefetchError}
          isLoading={isLoading}
          isRefetching={isRefetching}
          refetchData={refetch}
        />
      </Stack>
      <CreateUserDialog
        open={createUserDialog}
        onClose={() => setCreateUserDialog(false)}
      />
      <UpdateUserDialog
        userId={userToUpdate}
        onClose={() => setUserToUpdate(undefined)}
      />
      <DeleteUserDialog
        userId={userToDelete}
        onClose={() => setUserToDelete(undefined)}
      />
    </>
  );
};
