import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Button, Typography } from '@mui/material';
import { DeleteRounded as DeleteIcon } from '@mui/icons-material';

import AppDialog, { AppDeletionDialog } from 'components/AppDialog';
import { deleteBuildFiles, ErrorResponse } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const GenerateResetDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { mutate: onDeleteBuildFiles, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    () => {
      if (!projectId) throw new Error('project id does not exist');
      return deleteBuildFiles(projectId);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_build_files_success_${Date.now()}`,
          message: t('delete_build_files_success'),
          variant: 'success',
        });
        queryClient.invalidateQueries(['projects']);
        queryClient.invalidateQueries(['project', { projectId }]);
        queryClient.invalidateQueries(['projects-settings', { projectId }]);
        queryClient.invalidateQueries(['generate', { projectId }]);
        setDeleteDialog(false);
      },
      onError: () => {
        enqueueSnackbar({
          key: `delete_build_files_fail_${Date.now()}`,
          message: t('delete_build_files_fail'),
          variant: 'error',
          persist: true,
        });
      },
    },
  );

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={t('build_files')}
        actions={
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteDialog(true)}
            color="error"
            fullWidth
          >
            {t('delete')}
          </Button>
        }
        dismissIcon="minimize"
        dialogProps={{ maxWidth: 'md' }}
      >
        <Typography paragraph>{t('generate_reset_actions')}</Typography>
      </AppDialog>
      <AppDeletionDialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        title={t('delete_build_files')}
        text={t('delete_build_files_confirmation')}
        onConfirm={onDeleteBuildFiles}
        confirmDisabled={isDeleting}
      />
    </>
  );
};
