import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import {
  RadioButtonUncheckedRounded as UnsavedIcon,
  CheckCircleOutlineRounded as SavedIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// Redux
import { PartitionMeltSettings } from 'slices/meltSettingsSlice';

interface Props extends PartitionMeltSettings {
  sx?: SxProps<Theme>;
}

export const ProjectPartitionStatus: FC<Props> = ({ sx, draft, saved }) => {
  const { t } = useTranslation();
  return saved ? (
    <Tooltip
      title={
        t(
          draft
            ? 'partition_status_saved_draft'
            : 'partition_status_saved_nodraft',
        ) as string
      }
    >
      <SavedIcon sx={sx} color={draft ? 'warning' : 'primary'} />
    </Tooltip>
  ) : (
    <Tooltip
      title={
        t(
          draft
            ? 'partition_status_unsaved_draft'
            : 'partition_status_unsaved_nodraft',
        ) as string
      }
    >
      <UnsavedIcon sx={sx} color={draft ? 'warning' : 'inherit'} />
    </Tooltip>
  );
};
