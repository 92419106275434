import { FC, MouseEventHandler } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  CheckBoxOutlineBlankRounded as CheckboxOutlineIcon,
  CheckBoxRounded as CheckboxIcon,
} from '@mui/icons-material';

interface Props {
  text: string;
  checked: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  helperText?: string;
  disabled?: boolean;
}

export const AppCheckboxListItem: FC<Props> = ({
  text,
  checked,
  onClick,
  helperText,
  disabled,
}) => {
  const theme = useTheme();
  const smallScreenSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Tooltip
      title={helperText ?? ''}
      placement={smallScreenSize ? 'top' : 'left'}
      arrow
    >
      <ListItem disablePadding>
        <ListItemButton onClick={onClick} disabled={disabled}>
          <ListItemIcon>
            {checked ? (
              <CheckboxIcon color="primary" />
            ) : (
              <CheckboxOutlineIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
