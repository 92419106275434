import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Button, Stack } from '@mui/material';
import {
  PersonAddRounded as AddUserIcon,
  EditRounded as EditIcon,
  DeleteRounded as DeleteIcon,
} from '@mui/icons-material';

import {
  UpdateOrganizationDialog,
  DeleteOrganizationDialog,
  AddUserToOrganizationDialog,
} from 'containers/Organizations';
import AppDetailsDrawer from 'components/AppDetailsDrawer';
import {
  ErrorResponse,
  getOrganizationDetails,
  OrganizationWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

export const AppOrganizationDetails: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [{ rowId: organizationId }, { deleteSearchParam }] = useSearchParams();
  const [addUserDialog, setAddUserDialog] = useState(false);
  const [updateDialog, setUpdateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { data: details, isLoading } = useQuery<
    OrganizationWithEtag | void,
    AxiosError<ErrorResponse>
  >(
    ['organization', { organizationId }],
    () => {
      if (organizationId) return getOrganizationDetails(organizationId);
    },
    {
      enabled: !!organizationId,
      onError: () => {
        enqueueSnackbar({
          key: `get_organization_details_fail_${Date.now()}`,
          message: t('get_organization_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  if (isLoading) return <AppDetailsDrawer open onClose={onClose} isLoading />;

  if (details)
    return (
      <>
        <AppDetailsDrawer
          open
          onClose={onClose}
          title={details.data.name}
          actions={
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => setUpdateDialog(true)}
                variant="outlined"
                color="inherit"
                startIcon={<EditIcon />}
                fullWidth
              >
                {t('update')}
              </Button>
              <Button
                onClick={() => setDeleteDialog(true)}
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                fullWidth
              >
                {t('delete')}
              </Button>
            </Stack>
          }
        >
          <Button
            onClick={() => setAddUserDialog(true)}
            variant="contained"
            color="primary"
            startIcon={<AddUserIcon />}
            fullWidth
          >
            {t('add_user_to_organization')}
          </Button>
        </AppDetailsDrawer>
        <UpdateOrganizationDialog
          organization={updateDialog ? details : undefined}
          onClose={() => setUpdateDialog(false)}
        />
        <DeleteOrganizationDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          organizationId={details.data.id}
        />
        <AddUserToOrganizationDialog
          open={addUserDialog}
          onClose={() => setAddUserDialog(false)}
          organizationId={details.data.id}
        />
      </>
    );

  return null;
};
