import axios, { AxiosResponse } from 'axios';
import buildQuery from 'odata-query';

import {
  CreateMeltSettingPreset,
  CreateModel,
  CreateOrganization,
  CreateProject,
  CreateUser,
  FilteredMeltSettingPresets,
  FilteredModels,
  FilteredOrganizations,
  FilteredProjects,
  FilteredUsers,
  GenerateState,
  MeltSettingPreset,
  MeltSettingPresetRequestParams,
  MeltSettingPresetWithEtag,
  Model,
  ModelDetails,
  ModelDetailsWithEtag,
  ModelRequestParams,
  ODataMeltSettingPresets,
  ODataModels,
  ODataOrganizations,
  ODataProjects,
  ODataUsers,
  Organization,
  OrganizationRequestParams,
  OrganizationWithEtag,
  PartitionEnergyRequest,
  PartitionEnergyResponse,
  ProjectDetails,
  ProjectDetailsWithEtag,
  ProjectRequestParams,
  ProjectSettingsResponse,
  ProjectSettingsResponseWithEtag,
  SpotSpreadAlgorithms,
  UpdateMeltSettingPreset,
  UpdateOrganization,
  UpdateProject,
  UpdateUser,
  UserDetails,
  UserDetailsWithEtag,
  UserRequestParams,
} from 'api/types';

// USERS
export const getUsers = async (
  params?: UserRequestParams,
): Promise<FilteredUsers> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataUsers>(`users${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getCurrentUser = async (): Promise<UserDetailsWithEtag> => {
  const { headers, data } = await axios.get<UserDetails>(`users/currentuser`);
  return { etag: headers.etag, data };
};

export const getUserDetails = async (
  id: string,
): Promise<UserDetailsWithEtag> => {
  const { headers, data } = await axios.get<UserDetails>(`users/${id}`);
  return { etag: headers.etag, data };
};

export const createUser = async (
  body: CreateUser,
): Promise<UserDetailsWithEtag> => {
  const { headers, data } = await axios.post<
    UserDetails,
    AxiosResponse<UserDetails>,
    CreateUser
  >('users', body);
  return { etag: headers.etag, data };
};

export const addUserToOrganization = async (
  organizationId: string,
  body: CreateUser,
): Promise<UserDetailsWithEtag> => {
  const { headers, data } = await axios.post<
    UserDetails,
    AxiosResponse<UserDetails>,
    CreateUser
  >(`users/${organizationId}`, body);
  return { etag: headers.etag, data };
};

export const updateUser = async (
  body: UpdateUser,
  etag: string,
): Promise<void> => {
  const { data } = await axios.put<void, AxiosResponse<void>, UpdateUser>(
    `users`,
    body,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

export const deleteUser = async (id: string, etag: string): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `users/${id}`,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

// ORGANIZATIONS
export const getOrganizations = async (
  params?: OrganizationRequestParams,
): Promise<FilteredOrganizations> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataOrganizations>(`organizations${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getOrganizationDetails = async (
  id: string,
): Promise<OrganizationWithEtag> => {
  const { headers, data } = await axios.get<Organization>(
    `organizations/${id}`,
  );
  return { etag: headers.etag, data };
};

export const createOrganization = async (
  body: CreateOrganization,
): Promise<OrganizationWithEtag> => {
  const { headers, data } = await axios.post<
    Organization,
    AxiosResponse<Organization>,
    CreateOrganization
  >('organizations', body);
  return { etag: headers.etag, data };
};

export const updateOrganization = async (
  id: string,
  body: UpdateOrganization,
  etag: string,
): Promise<void> => {
  const { data } = await axios.put<
    void,
    AxiosResponse<void>,
    UpdateOrganization
  >(`organizations/${id}`, body, { headers: { 'If-Match': etag } });
  return data;
};

export const deleteOrganization = async (
  id: string,
  etag: string,
): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `organizations/${id}`,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

// MODELS
export const getModels = async (
  params?: ModelRequestParams,
): Promise<FilteredModels> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataModels>(`cadmodels${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getUnusedModels = async (): Promise<Model[]> => {
  const { data } = await axios.get<Model[]>(`cadmodels/unused`);
  return data;
};

export const getModelDetails = async (
  id: string,
): Promise<ModelDetailsWithEtag> => {
  const { data, headers } = await axios.get<ModelDetails>(`cadmodels/${id}`);
  return { etag: headers.etag, data };
};

export const createModel = async (
  body: CreateModel,
): Promise<ModelDetailsWithEtag> => {
  const { data, headers } = await axios.post<
    ModelDetails,
    AxiosResponse<ModelDetails>,
    CreateModel
  >('cadmodels', body);
  return { etag: headers.etag, data };
};

export const deleteModel = async (id: string, etag: string): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `cadmodels/${id}`,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

// PROJECTS
export const getProjects = async (
  params?: ProjectRequestParams,
): Promise<FilteredProjects> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataProjects>(`projects${query}`);
  return { count: data['@odata.count'], values: data.value };
};

export const getProjectDetails = async (
  id: string,
): Promise<ProjectDetailsWithEtag> => {
  const { headers, data } = await axios.get<ProjectDetails>(
    `projects/${id}/details`,
  );
  return { etag: headers.etag, data };
};

export const getProjectSettings = async (
  id: string,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.get<ProjectSettingsResponse>(
    `projects/${id}/settings`,
  );
  return { etag: headers.etag, data };
};

export const createProject = async (
  body: CreateProject,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.post<
    ProjectSettingsResponse,
    AxiosResponse<ProjectSettingsResponse>,
    CreateProject
  >('projects', body);
  return { etag: headers.etag, data };
};

export const deleteProject = async (
  id: string,
  etag: string,
): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `projects/${id}`,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

export const updateProjectSettings = async (
  id: string,
  body: UpdateProject,
  etag: string,
): Promise<ProjectSettingsResponseWithEtag> => {
  const { headers, data } = await axios.put<
    ProjectSettingsResponse,
    AxiosResponse<ProjectSettingsResponse>,
    UpdateProject
  >(`projects/${id}/settings`, body, { headers: { 'If-Match': etag } });
  return { etag: headers.etag, data };
};

export const getProjectLayerImage = async (
  id: string,
  params: { layer: number },
): Promise<string> => {
  const { data } = await axios.get<string>(`projects/${id}/layer-image`, {
    params,
  });
  return data;
};

export const getProjectBuildFileLink = async (id: string): Promise<string> => {
  const { data } = await axios.get<string>(`projects/${id}/build-file-link`);
  return data;
};

// PRESETS
export const getMeltSettingPresets = async (
  params?: MeltSettingPresetRequestParams,
): Promise<FilteredMeltSettingPresets> => {
  const query = buildQuery({ count: true, ...params });
  const { data } = await axios.get<ODataMeltSettingPresets>(
    `meltsettingpresets${query}`,
  );
  return { count: data['@odata.count'], values: data.value };
};

export const getMeltSettingPreset = async (
  id: string,
): Promise<MeltSettingPresetWithEtag> => {
  const { headers, data } = await axios.get<MeltSettingPreset>(
    `meltsettingpresets/${id}`,
  );
  return { etag: headers.etag, data };
};

export const createMeltSettingPreset = async (
  body: CreateMeltSettingPreset,
): Promise<MeltSettingPresetWithEtag> => {
  const { headers, data } = await axios.post<
    MeltSettingPreset,
    AxiosResponse<MeltSettingPreset>,
    CreateMeltSettingPreset
  >('meltsettingpresets', body);
  return { etag: headers.etag, data };
};

export const deleteMeltSettingPreset = async (
  id: string,
  etag: string,
): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `meltsettingpresets/${id}`,
    { headers: { 'If-Match': etag } },
  );
  return data;
};

export const updateMeltSettingPreset = async (
  id: string,
  body: UpdateMeltSettingPreset,
  etag: string,
): Promise<MeltSettingPresetWithEtag> => {
  const { headers, data } = await axios.put<
    MeltSettingPreset,
    AxiosResponse<MeltSettingPreset>,
    UpdateMeltSettingPreset
  >(`meltsettingpresets/${id}`, body, {
    headers: { 'If-Match': etag },
  });
  return { etag: headers.etag, data };
};

// OPERATIONS
export const getAlgorithms = async (): Promise<SpotSpreadAlgorithms> => {
  const { data } = await axios.get<SpotSpreadAlgorithms>(
    'spotspreadoperations/algorithms',
  );
  return data;
};

export const getEnergiesPerAreas = async (
  body: PartitionEnergyRequest[],
): Promise<PartitionEnergyResponse[]> => {
  const { data } = await axios.post<
    PartitionEnergyResponse[],
    AxiosResponse<PartitionEnergyResponse[]>,
    PartitionEnergyRequest[]
  >('spotspreadoperations/energyperarea', body);
  return data;
};

// GENERATE
export const getGenerateState = async (
  projectId: string,
): Promise<GenerateState> => {
  const { data } = await axios.get<GenerateState>(
    `generate/projectstate/${projectId}`,
  );
  return data;
};

export const startGenerate = async (projectId: string): Promise<void> => {
  const { data } = await axios.post<void, AxiosResponse<void>>(
    `generate/start/${projectId}`,
  );
  return data;
};

export const stopGenerate = async (projectId: string): Promise<void> => {
  const { data } = await axios.post<void, AxiosResponse<void>>(
    `generate/stop/${projectId}`,
  );
  return data;
};

export const deleteBuildFiles = async (projectId: string): Promise<void> => {
  const { data } = await axios.delete<void, AxiosResponse<void>>(
    `generate/deletebuildfiles/${projectId}`,
  );
  return data;
};
