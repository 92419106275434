import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  deleteOrganization,
  ErrorResponse,
  getOrganizationDetails,
  OrganizationWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

export const DeleteOrganizationDialog: FC<Props> = ({
  open,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');
  const [incorrectName, setIncorrectName] = useState<boolean>(false);

  const {
    data: details,
    isLoading: isDetailsLoading,
    isRefetching: isDetailsRefetching,
  } = useQuery<OrganizationWithEtag | void, AxiosError<ErrorResponse>>(
    ['organization', { organizationId }],
    () => (organizationId ? getOrganizationDetails(organizationId) : undefined),
    {
      onError: () => {
        enqueueSnackbar({
          key: `get_organization_details_fail_${Date.now()}`,
          message: t('get_organization_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const { mutate: onDeleteOrganization, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    () => {
      if (!details) throw new Error('organization details does not exist');
      return deleteOrganization(details.data.id, details.etag);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_organization_success_${Date.now()}`,
          message: t('delete_organization_success', {
            name: details?.data.name,
          }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['organizations']);
        queryClient.removeQueries(['organization', { organizationId }]);
        deleteSearchParam('rowId');
        onClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'delete_organization_decrepit',
            message: t('delete_organization_decrepit', {
              name: details?.data.name,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient
                    .refetchQueries(['organization', { organizationId }])
                    .then(() => {
                      closeSnackbar('delete_organization_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `delete_organization_fail_${Date.now()}`,
            message: t('delete_organization_fail', {
              name: details?.data.name,
            }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  const handleSubmit = useCallback<() => void>(() => {
    if (name !== details?.data.name) {
      setIncorrectName(true);
    } else {
      onDeleteOrganization();
    }
  }, [details?.data.name, name, onDeleteOrganization]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('delete_organization')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          disabled={isDeleting || isDetailsLoading || isDetailsRefetching}
          fullWidth
        >
          {t('delete')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography>
          {t('delete_organization_confirmation', { name: details?.data.name })}
        </Typography>
        <AppTextForm
          ref={nameRef}
          label={t('confirm_name')}
          value={name}
          onChange={setName}
          helperText={t('delete_organization_name_helper')}
          error={incorrectName}
          errorText={t('delete_organization_name_not_match')}
        />
      </Stack>
    </AppDialog>
  );
};
