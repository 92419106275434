import { FC, RefObject, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Stack } from '@mui/material';

import {
  AppSliderForm,
  AppNumberForm,
  AppTextForm,
  AppSelectForm,
} from 'components/AppFormControl';
import ProjectAlgorithmSetting from 'components/ProjectAlgorithmSetting';
import ProjectMenuPanelGroup from 'components/ProjectMenuPanelGroup';
import { Algorithm, AlgorithmSetting, getAlgorithms, MeltSetting } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  values: MeltSetting;
  disabled?: boolean;
  refs?: Record<keyof MeltSetting, RefObject<HTMLInputElement>>;
  errors?: (keyof MeltSetting)[];
  onChangeValues?: (newValues: MeltSetting) => void;
  isVarying?: (key: keyof MeltSetting, positionInArray?: number) => boolean;
}

export const AppMeltSettingsForm: FC<Props> = ({
  values,
  disabled,
  refs,
  errors,
  onChangeValues,
  isVarying = () => false,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: algorithms = { dwellTimeAlgorithms: [], pointSpreadAlgorithms: [] },
    isLoading: isAlgorithmsLoading,
  } = useQuery('algorithms', () => getAlgorithms(), {
    onError: () => {
      enqueueSnackbar({
        key: `algorithms_not_found_${Date.now()}`,
        message: t('algorithms_not_found'),
        variant: 'error',
        persist: true,
      });
      navigate('/projects');
    },
  });

  const getAlgorithmSettingsProps = useCallback<
    (algs: Algorithm[], algName: string) => AlgorithmSetting[]
  >(
    (algs, algName) =>
      algs.find(({ name }) => name === algName)?.algorithmSettings ?? [],
    [],
  );

  const pointSpreadSettingsProps = useMemo<AlgorithmSetting[]>(
    () =>
      getAlgorithmSettingsProps(
        algorithms.pointSpreadAlgorithms,
        values.pointSpreadAlgName,
      ),
    [
      getAlgorithmSettingsProps,
      algorithms.pointSpreadAlgorithms,
      values.pointSpreadAlgName,
    ],
  );

  const dwellTimeSettingsProps = useMemo<AlgorithmSetting[]>(
    () =>
      getAlgorithmSettingsProps(
        algorithms.dwellTimeAlgorithms,
        values.dwellTimeAlgName,
      ),
    [
      algorithms.dwellTimeAlgorithms,
      getAlgorithmSettingsProps,
      values.dwellTimeAlgName,
    ],
  );

  type HandleChangeValue = <T>(key: keyof MeltSetting, value: T) => void;
  const handleChangeValue = useCallback<HandleChangeValue>(
    (key, value) => {
      if (onChangeValues) {
        const newValue: Partial<MeltSetting> = {
          [key]: value,
        };
        if (key.includes('AlgName')) {
          if (key.replace('AlgName', '') === 'pointSpread') {
            newValue['pointSpreadSettings'] = getAlgorithmSettingsProps(
              algorithms.pointSpreadAlgorithms,
              String(value),
            ).map(({ defaultValue }) => defaultValue ?? '');
          }
          if (key.replace('AlgName', '') === 'dwellTime') {
            newValue['dwellTimeSettings'] = getAlgorithmSettingsProps(
              algorithms.dwellTimeAlgorithms,
              String(value),
            ).map(({ defaultValue }) => defaultValue ?? '');
          }
        }
        onChangeValues({
          ...values,
          ...newValue,
        });
      }
    },
    [
      algorithms.dwellTimeAlgorithms,
      algorithms.pointSpreadAlgorithms,
      getAlgorithmSettingsProps,
      onChangeValues,
      values,
    ],
  );

  return (
    <Stack spacing={2}>
      <ProjectMenuPanelGroup title={t('beam')}>
        <Stack spacing={2}>
          <AppSliderForm
            label={t('spot_size')}
            helperText={t('spot_size_helper')}
            error={errors?.includes('spotSize')}
            errorText={t('validations:melt_settings.spotSize')}
            value={Number(values.spotSize)}
            setValue={(value) => {
              handleChangeValue<MeltSetting['spotSize']>(
                'spotSize',
                String(value),
              );
            }}
            min={1}
            max={100}
            componentProps={{ disabled, ref: refs?.spotSize }}
            varies={isVarying('spotSize')}
          />
          <AppNumberForm
            label={t('beam_power')}
            helperText={t('beam_power_helper')}
            error={errors?.includes('beamPower')}
            errorText={t('validations:melt_settings.beamPower')}
            value={values.beamPower}
            onChange={(value) => {
              handleChangeValue<MeltSetting['beamPower']>('beamPower', value);
            }}
            unit={t('unit:watt')}
            min={0}
            max={6000}
            componentProps={{ disabled, inputRef: refs?.beamPower }}
            varies={isVarying('beamPower')}
          />
        </Stack>
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup title={t('mesh')}>
        <Stack spacing={2}>
          <AppNumberForm
            label={t('mesh_size')}
            helperText={t('mesh_size_helper')}
            error={errors?.includes('meshSize')}
            errorText={t('validations:melt_settings.meshSize')}
            value={values.meshSize}
            onChange={(value) => {
              handleChangeValue<MeltSetting['meshSize']>('meshSize', value);
            }}
            min={0.0001}
            max={1}
            step={0.1}
            unit={t('unit:millimeter')}
            componentProps={{ disabled, inputRef: refs?.meshSize }}
            varies={isVarying('meshSize')}
          />
          <AppNumberForm
            label={t('mesh_offset')}
            helperText={t('mesh_offset_helper')}
            error={errors?.includes('shift')}
            errorText={t('validations:melt_settings.shift')}
            value={values.shift}
            onChange={(value) => {
              handleChangeValue<MeltSetting['shift']>('shift', value);
            }}
            min={0}
            max={1}
            step={0.1}
            unit={t('unit:millimeter')}
            componentProps={{ disabled }}
            varies={isVarying('shift')}
          />
        </Stack>
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup
        title={t('spot_spread')}
        loading={isAlgorithmsLoading}
      >
        <Stack spacing={2}>
          <AppSelectForm
            label={t('method')}
            helperText={
              values.pointSpreadAlgName &&
              t(`algorithms:pointSpread.${values.pointSpreadAlgName}_helper`)
            }
            error={errors?.includes('pointSpreadAlgName')}
            errorText={t('validations:melt_settings.pointSpreadAlgName')}
            value={values.pointSpreadAlgName}
            onChange={(value) => {
              handleChangeValue<MeltSetting['pointSpreadAlgName']>(
                'pointSpreadAlgName',
                value as MeltSetting['pointSpreadAlgName'],
              );
            }}
            options={
              algorithms.pointSpreadAlgorithms
                .map(({ name }) =>
                  name
                    ? {
                        value: name,
                        label: t(`algorithms:pointSpread.${name}`),
                        tooltip: t(`algorithms:pointSpread.${name}_helper`),
                      }
                    : undefined,
                )
                .filter((value) => value) as {
                value: string;
                label: string;
              }[]
            }
            componentProps={{ disabled, inputRef: refs?.pointSpreadAlgName }}
            varies={isVarying('pointSpreadAlgName')}
          />
          {pointSpreadSettingsProps.map((props, index) => (
            <ProjectAlgorithmSetting
              key={`${values.pointSpreadAlgName}.${props.settingsName}`}
              {...props}
              value={values.pointSpreadSettings.at(index)}
              setValue={(v: string) => {
                handleChangeValue<MeltSetting['pointSpreadSettings']>(
                  'pointSpreadSettings',
                  pointSpreadSettingsProps.map(({ defaultValue }, i) =>
                    index === i
                      ? v
                      : values.pointSpreadSettings[i] || (defaultValue ?? ''),
                  ),
                );
              }}
              method="pointSpread"
              error={errors?.includes('pointSpreadSettings')}
              disabled={disabled}
              varies={isVarying('pointSpreadSettings', index)}
            />
          ))}
          <AppTextForm
            label={t('seeds')}
            helperText={t('seeds_helper')}
            error={errors?.includes('seeds')}
            errorText={t('validations:melt_settings.seeds')}
            value={values.seeds.length ? values.seeds.join(', ') : ''}
            onChange={(value) => {
              const result = value.replace(/[^0-9,]/g, '').split(',');
              if (result.at(-2) === '') result.pop();
              handleChangeValue<MeltSetting['seeds']>('seeds', result);
            }}
            componentProps={{
              disabled,
              inputRef: refs?.seeds,
              placeholder: t('for_example', {
                example: '1, 2, 3',
              }),
            }}
            varies={isVarying('seeds')}
          />
        </Stack>
      </ProjectMenuPanelGroup>
      <ProjectMenuPanelGroup
        title={t('dwell_time')}
        loading={isAlgorithmsLoading}
      >
        <Stack spacing={2}>
          <AppSelectForm
            label={t('method')}
            helperText={
              values.dwellTimeAlgName &&
              t(`algorithms:dwellTime.${values.dwellTimeAlgName}_helper`)
            }
            error={errors?.includes('dwellTimeAlgName')}
            errorText={t('validations:melt_settings.dwellTimeAlgName')}
            value={values.dwellTimeAlgName}
            onChange={(value) => {
              handleChangeValue<MeltSetting['dwellTimeAlgName']>(
                'dwellTimeAlgName',
                value as MeltSetting['dwellTimeAlgName'],
              );
            }}
            options={
              algorithms.dwellTimeAlgorithms
                .map(({ name }) =>
                  name
                    ? {
                        value: name,
                        label: t(`algorithms:dwellTime.${name}`),
                        tooltip: t(`algorithms:dwellTime.${name}_helper`),
                      }
                    : undefined,
                )
                .filter((value) => value) as {
                value: string;
                label: string;
              }[]
            }
            componentProps={{ disabled, inputRef: refs?.dwellTimeAlgName }}
            varies={isVarying('dwellTimeAlgName')}
          />
          {dwellTimeSettingsProps.map((props, index) => (
            <ProjectAlgorithmSetting
              key={`${values.dwellTimeAlgName}.${props.settingsName}`}
              {...props}
              value={values.dwellTimeSettings.at(index)}
              setValue={(v: string) => {
                handleChangeValue<MeltSetting['dwellTimeSettings']>(
                  'dwellTimeSettings',
                  dwellTimeSettingsProps.map(({ defaultValue }, i) =>
                    index === i
                      ? v
                      : values.dwellTimeSettings[i] || (defaultValue ?? ''),
                  ),
                );
              }}
              method="dwellTime"
              error={errors?.includes('dwellTimeSettings')}
              disabled={disabled}
              varies={isVarying('dwellTimeSettings', index)}
            />
          ))}
        </Stack>
      </ProjectMenuPanelGroup>
    </Stack>
  );
};
