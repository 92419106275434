import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Button, Stack } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import AppTable, { TableColumn, TableData } from 'components/AppTable';
import { ErrorResponse, FilteredModels, getModels } from 'api';
import { useSnackbar } from 'hooks';

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 25;

const tableColumns: TableColumn[] = [{ name: 'name', label: 'name' }];

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: string) => void;
  disabled?: boolean;
}

export const ExistingModelsDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>();
  const [search, setSearch] = useState<string>();
  const [modelId, setModelId] = useState<string>();

  const {
    data: models,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery<FilteredModels, AxiosError<ErrorResponse>>(
    ['models', { currentPage, pageSize, sortDir, search }],
    () =>
      getModels({
        top: pageSize,
        skip: (currentPage - 1) * pageSize,
        orderBy: sortDir ? `name ${sortDir}` : undefined,
        filter: {
          'tolower(name)': { contains: search?.toLowerCase() ?? '' },
        },
      }),
    {
      keepPreviousData: true,
      staleTime: 0,
      onError: () => {
        enqueueSnackbar({
          key: `get_models_fail_${Date.now()}`,
          message: t('get_models_fail'),
          variant: 'error',
        });
      },
    },
  );

  const tableData = useMemo<TableData>(
    () => ({
      rows:
        (models?.values ?? []).map(({ id, name }) => ({
          id: id,
          hover: true,
          selected: modelId === id,
          onClick: () => setModelId(id),
          onDoubleClick: () => onSubmit(id),
          sx: { cursor: 'pointer' },
          values: [{ value: name }],
        })) ?? [],
    }),
    [models, modelId, onSubmit],
  );

  const submitDisabled = useMemo<boolean>(
    () => disabled || isLoading || isRefetching,
    [disabled, isLoading, isRefetching],
  );

  const handleSubmit = useCallback<() => void>(() => {
    if (modelId) onSubmit(modelId);
  }, [modelId, onSubmit]);

  const handleResetForm = useCallback<() => void>(() => {
    setModelId(undefined);
    setCurrentPage(DEFAULT_CURRENT_PAGE);
    setPageSize(DEFAULT_PAGE_SIZE);
  }, []);

  useEffect(() => {
    if (!open) handleResetForm();
  }, [handleResetForm, open]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('select_existing_model')}
      actions={
        <Stack direction="row" spacing={2} width={1}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<CloseIcon />}
            onClick={onClose}
            fullWidth
            sx={{ flex: 1 }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SubmitIcon />}
            onClick={handleSubmit}
            disabled={submitDisabled}
            fullWidth
            sx={{ flex: 2 }}
          >
            {t('select')}
          </Button>
        </Stack>
      }
      dialogProps={{
        PaperProps: {
          sx: { height: 1, minHeight: '80vh', overflow: 'hidden' },
        },
        maxWidth: 'lg',
      }}
      dialogContentProps={{ sx: { overflow: 'hidden' } }}
    >
      <AppTable
        columns={tableColumns}
        data={tableData}
        name={t('models')}
        noDataText={
          isError || isRefetchError
            ? t('models_table_no_data_error')
            : t('models_table_no_data')
        }
        sx={{ height: 1 }}
        tableProps={{ stickyHeader: true }}
        headerProps={{
          search: { searchPhrase: search, onSearch: setSearch },
        }}
        pagination={{
          pageSize,
          currentPage,
          totalCount: models?.count,
          onChangeCurrentPage: setCurrentPage,
          onChangePageSize: setPageSize,
        }}
        sort={sortDir ? { columnName: 'name', direction: sortDir } : null}
        onSort={(value) => setSortDir(value ? value.direction : undefined)}
        fetchDate={new Date(dataUpdatedAt)}
        isError={isError}
        isRefetchError={isRefetchError}
        isLoading={isLoading}
        isRefetching={isRefetching}
        refetchData={refetch}
      />
    </AppDialog>
  );
};
