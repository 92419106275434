import { store, RootState } from 'store';

import { ProjectSettings } from 'api';

/**
 * Returns current state of the project
 * @returns {Project}
 */
export const getReduxProjectSettings = (): ProjectSettings => {
  const { partitionSettings, meltSettings, buildSettings }: RootState =
    store.getState();
  return {
    partitionSettings,
    meltSettings,
    buildSettings,
    buildPlateSize: 100,
    plateShape: 'circle',
  };
};
