import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import ProjectMenuPanel from 'components/ProjectMenuPanel';
import ProjectMenuPanelGroup from 'components/ProjectMenuPanelGroup';
import { AppNumberForm, AppTextForm } from 'components/AppFormControl';
import { ProjectSettingsResponseWithEtag } from 'api';
import { useSnackbar } from 'hooks';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  buildSettingsValidations,
  selectBuildSettings,
  setStartHeatTemp,
  setPreheatRepetitions,
  setPostheatRepetitions,
  setAbsolutePath,
  setBuildPistonDistance,
  setPowderPistonDistance,
  setRecoaterSpeed,
} from 'slices/buildSettingsSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const BuildPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const buildSettings = useSelector(selectBuildSettings);
  const {
    absolutePath,
    startHeatTemp,
    preheatRepetitions,
    postheatRepetitions,
    buildPistonDistance,
    powderPistonDistance,
    recoaterSpeed,
  } = buildSettings;
  const dispatch = useDispatch();

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const disabled = useMemo<boolean>(
    () => !userHasWritePermission || generateHasInitiated,
    [generateHasInitiated, userHasWritePermission],
  );

  return (
    <ProjectMenuPanel
      title={t('build_settings')}
      rootProps={{
        onClick: () => {
          if (!userHasWritePermission) {
            enqueueSnackbar({
              key: 'no_write_permission',
              message: t('no_write_permission'),
              variant: 'info',
            });
          } else if (generateHasInitiated) {
            enqueueSnackbar({
              key: 'project_settings_blocked',
              message: t('project_settings_blocked'),
              variant: 'info',
            });
          }
        },
      }}
    >
      <Stack spacing={2}>
        <ProjectMenuPanelGroup title={t('melt_files')}>
          <Stack spacing={2}>
            <Typography variant="h6">{t('path_to_files')}</Typography>
            <AppTextForm
              label={t('path')}
              helperText={t('absolute_path_helper')}
              value={absolutePath}
              onChange={(v) => dispatch(setAbsolutePath(v))}
              error={!absolutePath.length}
              errorText={t('validations:build_settings.absolutePath')}
              componentProps={{
                placeholder: t('absolute_path_placeholder', {
                  projectName: projectSettings?.data.name ?? '',
                }),
                disabled,
              }}
            />
          </Stack>
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('heat_files')}>
          <Stack spacing={2}>
            <Typography variant="h6">{t('start_heat')}</Typography>
            <AppNumberForm
              label={t('target_temperature')}
              helperText={t('start_heat_temp_helper')}
              value={startHeatTemp}
              onChange={(v) => dispatch(setStartHeatTemp(v))}
              errorText={t(
                'validations:build_settings.startHeatTemp',
                buildSettingsValidations.startHeatTemp,
              )}
              unit={t('unit:degree_celsius')}
              {...buildSettingsValidations.startHeatTemp}
              disabled={disabled}
            />
            <Typography variant="h6">{t('preheat')}</Typography>
            <AppNumberForm
              label={t('repetitions')}
              helperText={t('preheat_repetitions_helper')}
              value={preheatRepetitions}
              onChange={(v) => dispatch(setPreheatRepetitions(v))}
              errorText={t(
                'validations:build_settings.preheatRepetitions',
                buildSettingsValidations.preheatRepetitions,
              )}
              {...buildSettingsValidations.preheatRepetitions}
              disabled={disabled}
            />
            <Typography variant="h6">{t('postheat')}</Typography>
            <AppNumberForm
              label={t('repetitions')}
              helperText={t('postheat_repetitions_helper')}
              value={postheatRepetitions}
              onChange={(v) => dispatch(setPostheatRepetitions(v))}
              errorText={t(
                'validations:build_settings.postheatRepetitions',
                buildSettingsValidations.postheatRepetitions,
              )}
              {...buildSettingsValidations.postheatRepetitions}
              disabled={disabled}
            />
          </Stack>
        </ProjectMenuPanelGroup>
        <ProjectMenuPanelGroup title={t('layerfeed')}>
          <Stack spacing={2}>
            <AppNumberForm
              label={t('build_piston_distance')}
              helperText={t('build_piston_distance_helper')}
              value={buildPistonDistance}
              onChange={(v) => dispatch(setBuildPistonDistance(v))}
              errorText={t(
                'validations:build_settings.buildPistonDistance',
                buildSettingsValidations.buildPistonDistance,
              )}
              {...buildSettingsValidations.buildPistonDistance}
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
            <AppNumberForm
              label={t('powder_piston_distance')}
              helperText={t('powder_piston_distance_helper')}
              value={powderPistonDistance}
              onChange={(v) => dispatch(setPowderPistonDistance(v))}
              errorText={t(
                'validations:build_settings.powderPistonDistance',
                buildSettingsValidations.powderPistonDistance,
              )}
              {...buildSettingsValidations.powderPistonDistance}
              unit={t('unit:millimeter')}
              disabled={disabled}
            />
            <AppNumberForm
              label={t('recoater_speed')}
              helperText={t('recoater_speed_helper')}
              value={recoaterSpeed}
              onChange={(v) => dispatch(setRecoaterSpeed(v))}
              errorText={t(
                'validations:build_settings.recoaterSpeed',
                buildSettingsValidations.recoaterSpeed,
              )}
              {...buildSettingsValidations.recoaterSpeed}
              unit={t('unit:millimeter_per_second')}
              disabled={disabled}
            />
          </Stack>
        </ProjectMenuPanelGroup>
      </Stack>
    </ProjectMenuPanel>
  );
};
