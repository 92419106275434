import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Button,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { SaveRounded as SaveIcon } from '@mui/icons-material';

import { AppTextForm, AppCheckboxForm } from 'components/AppFormControl';
import ProjectPartitionForm from 'components/ProjectPartitionForm';
import ProjectMeltOrder from 'components/ProjectMeltOrder';
import Project3DView from 'components/Project3DView';
import { ModelDetails, Partition, ProjectSettingsResponseWithEtag } from 'api';
import { countDecimals, subtractWithDecimals } from 'helpers';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  selectMaterial,
  selectNotes,
  setMaterial,
  setNotes,
} from 'slices/projectSlice';
import {
  setMeltAllObjectsSimultaneously,
  setTransform,
  selectMeltAllObjectsSimultaneously,
  selectTransform,
} from 'slices/partitionSettingsSlice';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ProjectSettingsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.only('xs'),
  );
  const { projectId = '' } = useParams<'projectId'>();
  const dispatch = useDispatch();
  const material = useSelector(selectMaterial);
  const notes = useSelector(selectNotes);
  const meltAllObjectsSimultaneously = useSelector(
    selectMeltAllObjectsSimultaneously,
  );
  const transform = useSelector(selectTransform);
  const [highlights, setHighlights] =
    useState<Omit<Partition, 'partitionID'>[]>();

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const model = useMemo<ModelDetails | undefined>(
    () => projectSettings?.data.cadModel,
    [projectSettings?.data.cadModel],
  );

  const handleHighlightObject = useCallback<(objectID?: number) => void>(
    (objectID) => {
      const targetObject = model?.fileInfo.objects.find(
        (object) => object.objectID === objectID,
      );
      setHighlights(
        targetObject && objectID !== undefined
          ? [
              {
                startZ: subtractWithDecimals(
                  countDecimals(targetObject.layerThickness),
                  targetObject.startZ,
                  targetObject.layerThickness,
                ),
                endZ: targetObject.endZ,
                objectID: objectID,
              },
            ]
          : undefined,
      );
    },
    [model?.fileInfo.objects],
  );

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: { height: 1, maxHeight: '80vh', overflow: 'hidden' },
        }}
        fullScreen={smallScreenSize}
        maxWidth="lg"
        fullWidth
      >
        <Grid container sx={{ height: 1 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              height: 1,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              py: 4,
              px: 2,
            }}
          >
            <Stack height={1} justifyContent="space-between">
              <Stack spacing={3} sx={{ pl: 2, overflowY: 'scroll' }}>
                <Typography variant="h4">{t('project_settings')}</Typography>
                <Typography variant="h5">{t('project_info')}</Typography>
                <AppTextForm
                  label={t('material')}
                  value={material}
                  onChange={(value) => dispatch(setMaterial(value))}
                  helperText={t('project_info_material_helper')}
                />
                <AppTextForm
                  label={t('notes')}
                  value={notes}
                  onChange={(value) => dispatch(setNotes(value))}
                  componentProps={{ multiline: true }}
                  helperText={t('project_info_notes_helper')}
                />
                <Typography variant="h5">{t('model')}</Typography>
                <ProjectPartitionForm
                  modelInfo={model?.fileInfo}
                  modelName={model?.name}
                />
                <AppCheckboxForm
                  label={t('transform_model')}
                  helperText={t('transform_model_helper', { transform: 50 })}
                  value={transform}
                  setValue={(v) => {
                    dispatch(
                      setTransform(v ? { x: 50, y: 50 } : { x: 0, y: 0 }),
                    );
                  }}
                />
                <AppCheckboxForm
                  label={t('simultaneous_melting')}
                  helperText={t('simultaneous_melting_helper')}
                  value={meltAllObjectsSimultaneously}
                  setValue={(v) => {
                    dispatch(setMeltAllObjectsSimultaneously(v));
                  }}
                />
                <ProjectMeltOrder
                  modelInfo={model?.fileInfo}
                  onHighlightObject={handleHighlightObject}
                />
              </Stack>
              <Tooltip title={t('save_project_settings') as string}>
                <Stack sx={{ mx: 2, mt: 2 }}>
                  <Button
                    onClick={onClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    fullWidth
                  >
                    {t('save_settings')}
                  </Button>
                </Stack>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item sm={6} md={8}>
            <Project3DView
              model={model}
              highlights={highlights}
              settings={
                smallScreenSize
                  ? undefined
                  : {
                      settingsAnchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      settingsTransformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      settingsFabStyle: {
                        right: (theme) => theme.spacing(2),
                      },
                    }
              }
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
