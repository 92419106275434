import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Stack } from '@mui/material';

import { BuildPreviewDetails } from 'components/ProjectBuildPreview';
import AppImageMagnifier from 'components/AppImageMagnifier';
import { GenerateState, getProjectLayerImage, LayerInfo } from 'api';
import { useKeyboardShortcut, useSnackbar } from 'hooks';
// REDUX
import { useSelector, useDispatch } from 'store';
import {
  selectBuildPreviewSettings,
  setBuildPreviewSetting,
} from 'slices/projectSlice';

const PREFETCH_RANGE = 5;

export const ProjectBuildPreview: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const drawerRef = useRef<HTMLDivElement>(null);
  const drawerEdgeRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { drawerExtended, gridLines } = useSelector(selectBuildPreviewSettings);
  const [targetLayerIndex, setTargetLayerIndex] = useState<number>(1);
  const [zoom, setZoom] = useState<number>(1);

  const { data: generateState } = useQuery<GenerateState>([
    'generate',
    { projectId },
  ]);

  const targetLayer = useMemo<LayerInfo | undefined>(
    () => generateState?.layers.at(targetLayerIndex - 1),
    [generateState, targetLayerIndex],
  );

  const { data: layerImage, isLoading: isLoadingLayerImage } = useQuery(
    ['layer-image', { layer: targetLayerIndex, projectId }],
    () => {
      if (projectId)
        return getProjectLayerImage(projectId, {
          layer: targetLayerIndex,
        });
    },
    {
      enabled: !!projectId,
      onSuccess: () => {
        closeSnackbar('get_layer_image_fail');
      },
      onError: () => {
        enqueueSnackbar({
          key: 'get_layer_image_fail',
          message: t('get_layer_image_fail'),
          variant: 'error',
        });
      },
    },
  );

  const prefetchLayerImage = useCallback(
    (layer: number): Promise<void> =>
      queryClient.prefetchQuery({
        queryKey: ['layer-image', { layer, projectId }],
        queryFn: () => {
          if (projectId) return getProjectLayerImage(projectId, { layer });
        },
      }),
    [projectId, queryClient],
  );

  useEffect(() => {
    for (
      let index = Math.max(targetLayerIndex - PREFETCH_RANGE, 1);
      index <=
      Math.min(
        generateState?.layers.length ?? 1,
        targetLayerIndex + PREFETCH_RANGE,
      );
      index++
    ) {
      if (index !== targetLayerIndex)
        prefetchLayerImage(index).catch(() => undefined);
    }
  }, [generateState?.layers.length, prefetchLayerImage, targetLayerIndex]);

  const onToggleDrawerExtended = useCallback<() => void>(() => {
    dispatch(
      setBuildPreviewSetting({ key: 'drawerExtended', value: !drawerExtended }),
    );
  }, [dispatch, drawerExtended]);

  const onToggleGridLines = useCallback<() => void>(() => {
    dispatch(setBuildPreviewSetting({ key: 'gridLines', value: !gridLines }));
  }, [dispatch, gridLines]);

  useKeyboardShortcut({ key: 't', ctrl: true }, onToggleDrawerExtended);

  return (
    <Stack height={1} width={1} direction="row">
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          p: 4,
          pt: ({ spacing }) => `calc(${spacing(4)} + 55px)`,
          pr: ({ spacing }) =>
            `calc(${spacing(4)} + ${
              drawerEdgeRef.current?.clientWidth ?? 0
            }px)`,
          mr: drawerExtended
            ? 0
            : `${(drawerRef.current?.clientWidth ?? 0) * -1}px`,
          transition: ({ transitions }) =>
            transitions.create('margin', {
              easing: drawerExtended
                ? transitions.easing.easeOut
                : transitions.easing.sharp,
              duration: drawerExtended
                ? transitions.duration.enteringScreen
                : transitions.duration.leavingScreen,
            }),
        }}
      >
        <AppImageMagnifier
          src={`data:image/png;base64,${layerImage}`}
          zoom={zoom}
          size={200}
          isLoading={isLoadingLayerImage}
          gridLines={gridLines}
        />
      </Stack>
      <BuildPreviewDetails
        ref={drawerRef}
        drawerEdgeRef={drawerEdgeRef}
        open={drawerExtended}
        onToggle={onToggleDrawerExtended}
        layer={targetLayer}
        totalLayers={generateState?.layers.length}
        layerIndex={targetLayerIndex}
        onLayerIndexChange={setTargetLayerIndex}
        zoom={zoom}
        onZoomChange={setZoom}
        gridLines={gridLines}
        onToggleGridLines={onToggleGridLines}
      />
    </Stack>
  );
};
