import { ComponentProps, FC, ReactNode, useRef } from 'react';
import { Paper, Grid, Box, Typography, Tooltip, Button } from '@mui/material';

import ProjectPanelOptions from 'components/ProjectPanelOptions';

type ActionProps = {
  title: string;
  desc: string;
  icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
};
interface Props {
  title: string;
  children: ReactNode;
  action?: {
    primary?: ActionProps;
    secondary?: ActionProps;
  };
  options?: ReactNode;
  flexShrink?: number;
  rootProps?: ComponentProps<typeof Paper>;
}

export const ProjectMenuPanel: FC<Props> = ({
  title,
  children,
  action,
  options,
  flexShrink = 1,
  rootProps = {},
}) => {
  const contentEl = useRef<HTMLDivElement | null>(null);

  return (
    <Paper
      sx={{
        flexShrink,
        flexBasis: '100%',
        minHeight: 0,
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        m: 1,
        zIndex: ({ zIndex }) => zIndex.drawer,
      }}
    >
      <Paper
        sx={{
          marginBottom: 2,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant="h4" align="center" sx={{ p: 2 }}>
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {options && (
              <ProjectPanelOptions anchor={contentEl}>
                {options}
              </ProjectPanelOptions>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Box
        ref={contentEl}
        sx={{
          pl: 2,
          overflowX: 'hidden',
          overflowY: 'scroll',
          flex: 1,
        }}
        {...rootProps}
      >
        {children}
      </Box>
      {action && (
        <Box sx={{ m: 2, display: 'flex' }}>
          {action.secondary && (
            <Tooltip title={action.secondary.desc}>
              <Box sx={{ flex: 1, mr: 1 }}>
                <Button
                  onClick={action.secondary.onClick}
                  startIcon={action.secondary.icon}
                  variant="outlined"
                  color="inherit"
                  disabled={action.secondary.disabled}
                  fullWidth
                >
                  {action.secondary.title}
                </Button>
              </Box>
            </Tooltip>
          )}
          {action.primary && (
            <Tooltip title={action.primary.desc}>
              <Box sx={{ flex: 2, ml: 1 }}>
                <Button
                  onClick={action.primary.onClick}
                  startIcon={action.primary.icon}
                  variant="contained"
                  color="primary"
                  disabled={action.primary.disabled}
                  fullWidth
                >
                  {action.primary.title}
                </Button>
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
    </Paper>
  );
};
