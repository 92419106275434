import { FC } from 'react';
import { Paper, Divider, Stack } from '@mui/material';

import ProjectMenuItem from 'components/ProjectMenuItem';
import { MenuItem, MenuName } from 'components/ProjectMenuPanel';

interface Props {
  align?: 'right';
  menuGroups: { items: MenuItem[]; name: MenuName }[];
  activeItems: MenuItem[];
  onMenuItemClick: (item: string, menu: MenuName) => void;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectMenu: FC<Props> = ({
  align,
  menuGroups,
  activeItems,
  onMenuItemClick,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  return (
    <Stack
      width={1}
      height={1}
      position="absolute"
      direction={align === 'right' ? 'row-reverse' : 'row'}
      right={align === 'right' ? 0 : undefined}
      alignItems="center"
    >
      <Stack>
        {menuGroups.map(({ items, name }, groupIndex) => (
          <Paper
            key={groupIndex}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              zIndex: ({ zIndex }) => zIndex.drawer - 1,
              width: 55,
              borderTopLeftRadius: groupIndex > 0 ? 0 : undefined,
              borderTopRightRadius: groupIndex > 0 ? 0 : undefined,
              borderBottomLeftRadius:
                menuGroups.length !== groupIndex + 1 ? 0 : undefined,
              borderBottomRightRadius:
                menuGroups.length !== groupIndex + 1 ? 0 : undefined,
            }}
          >
            {items.map((props, key) => (
              <ProjectMenuItem
                key={key}
                active={activeItems.some(
                  (activeItem) => activeItem.name === props.name,
                )}
                onClick={() => {
                  onMenuItemClick(props.name, name);
                }}
                {...props}
              />
            ))}
            {menuGroups.length > groupIndex + 1 && <Divider />}
          </Paper>
        ))}
      </Stack>
      <Stack
        sx={{
          height: 1,
          width: { xs: 1, sm: 350, lg: 400 },
          transition: 'all 0.5s',
        }}
      >
        {activeItems.map(({ Panel }, key) => (
          <Panel
            key={key}
            userHasWritePermission={userHasWritePermission}
            generateHasInitiated={generateHasInitiated}
          />
        ))}
      </Stack>
    </Stack>
  );
};
