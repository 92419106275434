import {
  ComponentProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PerspectiveCamera } from 'three';
import { Canvas } from '@react-three/fiber';
import {
  OrbitControls,
  OrbitControlsProps,
} from '@react-three/drei/core/OrbitControls';
import useMeasure from 'react-use-measure';

import ProjectBuildTank from 'components/ProjectBuildTank';
import ProjectStartPlate from 'components/ProjectStartPlate';
import Project3DObject from 'components/Project3DObject';
import ErrorBoundary from 'components/ErrorBoundary';
import { ModelObjectInfo, Partition } from 'api';
import { ModelValidationCallbacks } from 'hooks';
// Redux
import { useSelector } from 'store';
import { selectTransformValue } from 'slices/partitionSettingsSlice';
import {
  selectModelPreviewSettings,
  ModelPreviewSettings,
} from 'slices/projectSlice';

interface Props {
  data: string | undefined;
  objects: ModelObjectInfo[] | undefined;
  highlights: Omit<Partition, 'partitionID'>[] | undefined;
  modelValidationCallbacks: ModelValidationCallbacks;
  defaultSettings: boolean;
}
interface Ref {
  resetCamera: () => void;
}

const defaultModelPreviewSettings: ModelPreviewSettings = {
  axesHelper: false,
  buildTank: false,
  highlightSelected: false,
  highlightUnsliced: false,
  startPlate: false,
};

export const Project3DCanvas = forwardRef<Ref, Props>(
  (
    {
      data,
      objects,
      highlights = [],
      modelValidationCallbacks,
      defaultSettings,
    },
    ref,
  ) => {
    const controlRef = useRef<OrbitControlsProps>(null);
    const transformValue = useSelector(selectTransformValue);
    const projectModelPreviewSettings = useSelector(selectModelPreviewSettings);
    const [canvasRef, bounds] = useMeasure();
    const [aspect, setAspect] = useState<number>(1);

    const {
      axesHelper,
      buildTank,
      highlightSelected,
      highlightUnsliced,
      startPlate,
    } = useMemo<ModelPreviewSettings>(
      () =>
        defaultSettings
          ? defaultModelPreviewSettings
          : projectModelPreviewSettings,
      [defaultSettings, projectModelPreviewSettings],
    );

    const camera = useMemo<PerspectiveCamera>(() => {
      const result = new PerspectiveCamera(75, aspect, 0.1, 1000);
      result.position.set(0, -200, 50);
      result.up.set(0, 0, 1);
      return result;
    }, [aspect]);

    useEffect(() => {
      setAspect(bounds.width / bounds.height);
    }, [bounds.height, bounds.width, camera]);

    useImperativeHandle<Ref, Ref>(
      ref,
      () => ({
        resetCamera: () => {
          if (controlRef.current && controlRef.current.reset)
            controlRef.current.reset();
        },
      }),
      [],
    );

    return (
      <Canvas
        flat
        dpr={[1, 2]}
        camera={{ ...camera, aspect } as PerspectiveCamera}
        ref={canvasRef}
      >
        <ambientLight intensity={0.6} />
        <directionalLight position={[-50, 50, 200]} intensity={0.2} />
        <directionalLight position={[50, -50, 100]} intensity={1} />
        {axesHelper && <axesHelper args={[250]} />}
        <OrbitControls
          enableDamping={false}
          maxPolarAngle={Math.PI / 2}
          minAzimuthAngle={Math.PI / 2}
          // https://github.com/pmndrs/drei/issues/730
          ref={controlRef as ComponentProps<typeof OrbitControls>['ref']}
        />
        {buildTank && <ProjectBuildTank />}
        {startPlate && <ProjectStartPlate />}
        <ErrorBoundary>
          {!!data && (
            <Project3DObject
              data={data}
              transformValue={Object.values(transformValue) as [number, number]}
              objects={objects}
              selectedLayers={highlights}
              highlightSelected={highlightSelected}
              highlightUnsliced={highlightUnsliced}
              modelValidationCallbacks={modelValidationCallbacks}
            />
          )}
        </ErrorBoundary>
      </Canvas>
    );
  },
);
Project3DCanvas.displayName = 'Project3DCanvas';
