import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { MeltSettings, MeltSetting, MeltSettingWithPartitionId } from 'api';

export interface PartitionMeltSettings {
  draft?: MeltSettingWithPartitionId;
  saved?: MeltSettingWithPartitionId;
}

export const initialMeltSetting: MeltSetting = {
  spotSize: '1',
  beamPower: '600',
  meshSize: '0.1',
  shift: '0',
  seeds: [],
  pointSpreadAlgName: '',
  dwellTimeAlgName: '',
  pointSpreadSettings: [],
  dwellTimeSettings: [],
};

export const initialState: MeltSettings = {
  draft: [],
  saved: [],
};

const meltSettingsSlice = createSlice({
  name: 'meltSettings',
  initialState,
  reducers: {
    loadMeltSettings: (state, { payload }: { payload: MeltSettings }) => {
      state.draft = payload.draft;
      state.saved = payload.saved;
    },
    resetMeltSettings: (state) => {
      state.draft = initialState.draft;
      state.saved = initialState.saved;
    },
    setDraft: (
      state,
      { payload }: { payload: { partitions: string[]; settings: MeltSetting } },
    ) => {
      for (const id of payload.partitions) {
        state.draft = [
          ...state.draft.filter(({ partitionID }) => partitionID !== id),
          { ...payload.settings, partitionID: id },
        ];
      }
    },
    saveDraft: (state, { payload }: { payload: string[] }) => {
      for (const id of payload) {
        const partitionValues: MeltSettingWithPartitionId | undefined =
          state.draft.find(({ partitionID }) => partitionID === id);
        state.draft = state.draft.filter(
          ({ partitionID }) => partitionID !== id,
        );
        if (partitionValues) {
          state.saved = [
            ...state.saved.filter(({ partitionID }) => partitionID !== id),
            partitionValues,
          ];
        }
      }
    },
    discardDraft: (state, { payload }: { payload: string[] }) => {
      state.draft = state.draft.filter(
        ({ partitionID }) => payload.findIndex((id) => id === partitionID) < 0,
      );
    },
  },
});

export const {
  loadMeltSettings,
  resetMeltSettings,
  setDraft,
  saveDraft,
  discardDraft,
} = meltSettingsSlice.actions;

export const selectSavedValues = (
  state: RootState,
  partitionIds?: string[],
): MeltSettingWithPartitionId[] =>
  partitionIds
    ? state.meltSettings.saved.filter(({ partitionID }) =>
        partitionIds.includes(partitionID),
      )
    : state.meltSettings.saved;
export const selectDraftValues = (
  state: RootState,
  partitionIds?: string[],
): MeltSettingWithPartitionId[] =>
  partitionIds
    ? state.meltSettings.draft.filter(({ partitionID }) =>
        partitionIds.includes(partitionID),
      )
    : state.meltSettings.draft;
export const selectPartitionMeltSettings = (
  state: RootState,
  partitionId: string,
): PartitionMeltSettings => {
  const draft: MeltSettingWithPartitionId | undefined =
    state.meltSettings.draft.find(
      ({ partitionID }) => partitionID === partitionId,
    );
  const saved: MeltSettingWithPartitionId | undefined =
    state.meltSettings.saved.find(
      ({ partitionID }) => partitionID === partitionId,
    );
  return { draft, saved };
};

export default meltSettingsSlice.reducer;
