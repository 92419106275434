import { isDesktop, isMacOs } from 'react-device-detect';

export type EntitySelectionMode = 'single' | 'multiple' | 'extended';

export const getSelectionMode = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
): EntitySelectionMode => {
  if (isDesktop) {
    // if (e.shiftKey) return 'extended' // TODO: Implement extended selection type
    if ((isMacOs && e.metaKey) || (!isMacOs && e.ctrlKey)) return 'multiple';
  }
  return 'single';
};
