import { User, UserDetails, UserRole } from 'api';

/**
 * Decides if user can delete or edit the project or not
 * @description User is admin or is the creator of the project
 * @param {UserDetails | undefined} user
 * @param  {User | undefined} target
 * @returns {boolean}
 */
export const checkWritePermission = (
  user?: UserDetails,
  target?: User,
): boolean => {
  if (user) {
    if (
      user.type === UserRole.OrganizationAdmin ||
      user.type === UserRole.SuperAdmin
    )
      return true;
    if (target && user.id === target.id) return true;
  }
  return false;
};
