import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export enum View {
  ModelPreview,
  BuildPreview,
}
export type PrimaryMenuTabs = 'melt' | 'heat' | 'build';
export type PrimaryMenu = { window: PrimaryMenuTabs; open: boolean };
export type SecondaryMenuTabs = 'selection' | 'info';
export type SecondaryMenu = { windows: SecondaryMenuTabs[]; open: boolean };
export type ModelPreviewSettings = {
  startPlate: boolean;
  buildTank: boolean;
  highlightSelected: boolean;
  highlightUnsliced: boolean;
  axesHelper: boolean;
};
export type BuildPreviewSettings = {
  drawerExtended: boolean;
  gridLines: boolean;
};

export interface ProjectState {
  material: string;
  notes: string;
  view: View;
  primaryMenu: PrimaryMenu;
  secondaryMenu: SecondaryMenu;
  modelPreviewSettings: ModelPreviewSettings;
  buildPreviewSettings: BuildPreviewSettings;
}

const initialState: ProjectState = {
  material: '',
  notes: '',
  view: View.ModelPreview,
  primaryMenu: { window: 'melt', open: true },
  secondaryMenu: { windows: ['selection', 'info'], open: true },
  modelPreviewSettings: {
    startPlate: true,
    buildTank: false,
    highlightSelected: true,
    highlightUnsliced: false,
    axesHelper: false,
  },
  buildPreviewSettings: {
    drawerExtended: true,
    gridLines: false,
  },
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    resetProject: (state) => {
      state.material = initialState.material;
      state.notes = initialState.notes;
      state.view = initialState.view;
      state.primaryMenu = initialState.primaryMenu;
      state.secondaryMenu = initialState.secondaryMenu;
      state.modelPreviewSettings = initialState.modelPreviewSettings;
    },
    setMaterial: (state, { payload }: { payload: string }) => {
      state.material = payload;
    },
    setNotes: (state, { payload }: { payload: string }) => {
      state.notes = payload;
    },
    setView: (
      state,
      { payload }: { payload: { view: View; smallScreenSize?: boolean } },
    ) => {
      state.view = payload.view;
    },
    setPrimaryMenu: (
      state,
      { payload }: { payload: { open?: boolean; tab?: PrimaryMenuTabs } },
    ) => {
      if (payload.open !== undefined) {
        state.primaryMenu.open = payload.open;
      }
      if (payload.tab !== undefined) {
        if (state.primaryMenu.window === payload.tab) {
          state.primaryMenu.open = !state.primaryMenu.open;
        } else {
          state.primaryMenu = { window: payload.tab, open: true };
        }
      }
    },
    setSecondaryMenu: (
      state,
      { payload }: { payload: { open?: boolean; tab?: SecondaryMenuTabs } },
    ) => {
      if (payload.open !== undefined) {
        state.secondaryMenu.open = payload.open;
      }
      if (payload.tab !== undefined) {
        if (state.secondaryMenu.windows.includes(payload.tab)) {
          if (state.secondaryMenu.open) {
            state.secondaryMenu.windows = state.secondaryMenu.windows.filter(
              (tab) => tab !== payload.tab,
            );
          } else {
            state.secondaryMenu = { windows: [payload.tab], open: true };
          }
        } else {
          state.secondaryMenu = {
            windows: [...state.secondaryMenu.windows, payload.tab],
            open: true,
          };
        }
      }
    },
    setModelPreviewSetting: (
      state,
      {
        payload,
      }: { payload: { key: keyof ModelPreviewSettings; value: boolean } },
    ) => {
      state.modelPreviewSettings[payload.key] = payload.value;
    },
    setBuildPreviewSetting: (
      state,
      {
        payload,
      }: { payload: { key: keyof BuildPreviewSettings; value: boolean } },
    ) => {
      state.buildPreviewSettings[payload.key] = payload.value;
    },
  },
});

export const {
  resetProject,
  setMaterial,
  setNotes,
  setView,
  setPrimaryMenu,
  setSecondaryMenu,
  setModelPreviewSetting,
  setBuildPreviewSetting,
} = projectSlice.actions;

export const selectMaterial = (state: RootState): string =>
  state.project.material;
export const selectNotes = (state: RootState): string => state.project.notes;
export const selectView = (state: RootState): View => state.project.view;
export const selectPrimaryMenu = (state: RootState): PrimaryMenu =>
  state.project.primaryMenu;
export const selectSecondaryMenu = (state: RootState): SecondaryMenu =>
  state.project.secondaryMenu;
export const selectModelPreviewSettings = (
  state: RootState,
): ModelPreviewSettings => state.project.modelPreviewSettings;
export const selectBuildPreviewSettings = (
  state: RootState,
): BuildPreviewSettings => state.project.buildPreviewSettings;

export default projectSlice.reducer;
