import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, ListItemProps, Tooltip } from '@mui/material';

interface Props {
  listId: string;
  index: number;
  listItemProps?: ListItemProps;
  tooltip?: string;
  disabled?: boolean;
}

export const AppDraggableListItem: FC<Props> = ({
  listId,
  index,
  listItemProps,
  tooltip,
  disabled,
  children,
}) => {
  return (
    <Draggable
      draggableId={`${listId}-${index}`}
      index={index}
      isDragDisabled={disabled}
    >
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <Tooltip title={tooltip ?? ''} placement="right" arrow>
          <ListItem
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            {...listItemProps}
          >
            {children}
          </ListItem>
        </Tooltip>
      )}
    </Draggable>
  );
};
