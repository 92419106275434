import { FC, useCallback, useEffect, useMemo } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import ProjectMenu from 'components/ProjectMenu';
import {
  MenuItem,
  MenuName,
  primaryMenu,
  secondaryMenu,
} from 'components/ProjectMenuPanel';
import { useKeyboardShortcut } from 'hooks';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  PrimaryMenuTabs,
  SecondaryMenuTabs,
  selectPrimaryMenu,
  selectSecondaryMenu,
  setPrimaryMenu,
  setSecondaryMenu,
} from 'slices/projectSlice';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const PanelMenus: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  );
  const dispatch = useDispatch();
  const activePrimaryMenu = useSelector(selectPrimaryMenu);
  const activeSecondaryMenu = useSelector(selectSecondaryMenu);

  const activeMenu = useMemo<{ primary: MenuItem[]; secondary: MenuItem[] }>(
    () => ({
      primary: primaryMenu.items.filter(
        ({ name }) =>
          activePrimaryMenu.open && activePrimaryMenu.window === name,
      ),
      secondary: secondaryMenu.items.filter(
        ({ name }) =>
          activeSecondaryMenu.open &&
          activeSecondaryMenu.windows.includes(name as SecondaryMenuTabs),
      ),
    }),
    [activePrimaryMenu, activeSecondaryMenu],
  );

  const handleSetPrimaryMenu = useCallback<
    (item: string, menu: MenuName) => void
  >(
    (item, menu) => {
      dispatch(
        setPrimaryMenu(
          menu === 'primary'
            ? { tab: item as PrimaryMenuTabs }
            : { open: false },
        ),
      );
    },
    [dispatch],
  );

  const handleSetSecondaryMenu = useCallback<
    (item: string, menu: MenuName) => void
  >(
    (item, menu) => {
      dispatch(
        setSecondaryMenu(
          menu === 'secondary'
            ? { tab: item as SecondaryMenuTabs }
            : { open: false },
        ),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (smallScreenSize) {
      dispatch(setSecondaryMenu({ open: false }));
    }
  }, [dispatch, smallScreenSize]);

  useKeyboardShortcut({ key: '3', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: 'melt' }));
    if (smallScreenSize) {
      dispatch(setSecondaryMenu({ open: false }));
    }
  });
  useKeyboardShortcut({ key: '4', ctrl: true }, () => {
    dispatch(setPrimaryMenu({ tab: 'build' }));
    if (smallScreenSize) {
      dispatch(setSecondaryMenu({ open: false }));
    }
  });
  useKeyboardShortcut({ key: '5', ctrl: true }, () => {
    dispatch(setSecondaryMenu({ tab: 'selection' }));
    if (smallScreenSize) {
      dispatch(setPrimaryMenu({ open: false }));
    }
  });
  useKeyboardShortcut({ key: '6', ctrl: true }, () => {
    dispatch(setSecondaryMenu({ tab: 'info' }));
    if (smallScreenSize) {
      dispatch(setPrimaryMenu({ open: false }));
    }
  });
  useKeyboardShortcut({ key: 't', ctrl: true }, () => {
    dispatch(
      setPrimaryMenu({
        open:
          !activePrimaryMenu.open &&
          !activeSecondaryMenu.open &&
          !smallScreenSize,
      }),
    );
    dispatch(
      setSecondaryMenu({
        open:
          (!activePrimaryMenu.open || smallScreenSize) &&
          !activeSecondaryMenu.open,
      }),
    );
  });

  return smallScreenSize ? (
    <ProjectMenu
      menuGroups={[primaryMenu, secondaryMenu]}
      activeItems={[...activeMenu.primary, ...activeMenu.secondary]}
      onMenuItemClick={(...args) => {
        handleSetPrimaryMenu(...args);
        handleSetSecondaryMenu(...args);
      }}
      userHasWritePermission={userHasWritePermission}
      generateHasInitiated={generateHasInitiated}
    />
  ) : (
    <>
      <ProjectMenu
        menuGroups={[primaryMenu]}
        activeItems={activeMenu.primary}
        onMenuItemClick={handleSetPrimaryMenu}
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
      <ProjectMenu
        menuGroups={[secondaryMenu]}
        activeItems={activeMenu.secondary}
        onMenuItemClick={handleSetSecondaryMenu}
        align="right"
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
    </>
  );
};
