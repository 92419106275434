import { FC, MutableRefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Popover, Typography, List } from '@mui/material';
import {
  MoreVertRounded as MoreIcon,
  SaveRounded as SaveIcon,
  SaveAsRounded as SaveAsIcon,
  SettingsRounded as SettingsIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';

import ProjectSaveAsDialog from 'components/ProjectSaveAsDialog';
import ProjectSettingsDialog from 'components/ProjectSettingsDialog';
import AppButton from 'components/AppButton';
import { AppButtonListItem, AppDividerListItem } from 'components/AppListItem';
import { useKeyboardShortcut, useSaveProject, useSnackbar } from 'hooks';
import { ProjectSettingsResponseWithEtag } from 'api';

interface Props {
  anchor: MutableRefObject<HTMLDivElement | null>;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectQuickSettings: FC<Props> = ({
  anchor,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [projectSettingsDialog, setProjectSettingsDialog] = useState(false);
  const [saveProjectAsDialog, setSaveProjectAsDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { onSaveProject, isLoading: isSavingProject } = useSaveProject();

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const handleOpenProjectSettings = useCallback<() => void>(() => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else {
      setProjectSettingsDialog(true);
      setAnchorEl(null);
    }
  }, [enqueueSnackbar, generateHasInitiated, t, userHasWritePermission]);

  const handleSaveProject = useCallback<() => void>(() => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else {
      onSaveProject();
      setAnchorEl(null);
    }
  }, [
    enqueueSnackbar,
    generateHasInitiated,
    onSaveProject,
    t,
    userHasWritePermission,
  ]);

  const handleOpenSaveProjectAs = useCallback<() => void>(() => {
    setSaveProjectAsDialog(true);
    setAnchorEl(null);
  }, []);

  const handleOpenCloseProject = useCallback<() => void>(() => {
    setAnchorEl(null);
    navigate(projectId ? `/projects?rowId=${projectId}` : '/projects');
  }, [navigate, projectId]);

  useKeyboardShortcut({ key: 'p', ctrl: true }, handleOpenProjectSettings);
  useKeyboardShortcut({ key: 's', ctrl: true }, handleSaveProject);
  useKeyboardShortcut(
    { key: 's', ctrl: true, shift: true },
    handleOpenSaveProjectAs,
  );
  useKeyboardShortcut({ key: 'q', ctrl: true }, handleOpenCloseProject);

  return (
    <>
      <AppButton
        title={t('options')}
        buttonProps={{
          onClick: () => setAnchorEl(anchor.current),
        }}
      >
        <MoreIcon />
      </AppButton>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { minWidth: 250 } }}
        sx={{ mt: 2 }}
      >
        <List>
          <Typography
            align="center"
            component="li"
            sx={{ p: 3, fontWeight: 'bold', textTransform: 'uppercase' }}
          >
            {projectSettings?.data.name ?? ''}
          </Typography>
          <AppDividerListItem />
          <AppButtonListItem
            onClick={handleOpenProjectSettings}
            icon={<SettingsIcon />}
            text={t('project_settings')}
          />
          <AppButtonListItem
            onClick={handleSaveProject}
            icon={<SaveIcon />}
            text={t('save_project')}
            disabled={isSavingProject}
          />
          <AppButtonListItem
            onClick={handleOpenSaveProjectAs}
            icon={<SaveAsIcon />}
            text={t('save_project_as')}
          />
          <AppButtonListItem
            onClick={handleOpenCloseProject}
            icon={<CloseIcon />}
            text={t('close_project')}
          />
        </List>
      </Popover>
      <ProjectSettingsDialog
        open={projectSettingsDialog}
        onClose={() => setProjectSettingsDialog(false)}
      />
      <ProjectSaveAsDialog
        open={saveProjectAsDialog}
        onClose={() => setSaveProjectAsDialog(false)}
      />
    </>
  );
};
