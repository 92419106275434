import { FC, useCallback } from 'react';
import {
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableProps,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  AppTableHeader,
  AppTableFooter,
  TableColumn,
  TableData,
  TableActionsProps,
  TableQueryProps,
  TableSort,
} from 'components/AppTable';
import { useSearchParams } from 'hooks';

interface Props extends TableQueryProps {
  columns: Omit<TableColumn, 'sortDirection'>[];
  data: TableData;
  name: string;
  noDataText: string;
  sort?: TableSort;
  onSort?: (arg: TableSort) => void;
  sx?: SxProps<Theme>;
  containerProps?: TableContainerProps;
  tableProps?: TableProps;
  headerProps?: TableActionsProps;
}

export const AppTable: FC<Props> = ({
  columns,
  data,
  name,
  noDataText,
  sort,
  onSort,
  sx,
  containerProps,
  tableProps,
  headerProps,
  ...queryProps
}) => {
  const { t } = useTranslation();
  const [, { updateSearchParams, deleteSearchParams }] = useSearchParams();

  const handleSortColumn = useCallback<(columnName: string) => void>(
    (columnName) => {
      if (sort !== undefined) {
        let value: TableSort = null;
        if (sort === null || sort.columnName !== columnName)
          value = { columnName, direction: 'asc' };
        else if (sort?.direction === 'asc')
          value = { columnName, direction: 'desc' };

        if (onSort) onSort(value);
        else {
          if (value)
            updateSearchParams([
              ['sortBy', value.columnName],
              ['sortDir', value.direction],
            ]);
          else deleteSearchParams(['sortBy', 'sortDir']);
        }
      }
    },
    [deleteSearchParams, onSort, sort, updateSearchParams],
  );

  return (
    <Stack component={Paper} flex={1} overflow="hidden" sx={sx}>
      <AppTableHeader tableName={name ?? ''} {...headerProps} />
      <TableContainer {...containerProps}>
        <Table {...tableProps}>
          <TableHead>
            <TableRow>
              {columns.map(({ name, label, sortDisabled, ...column }, key) => (
                <TableCell
                  key={key}
                  {...column}
                  sortDirection={sort?.direction}
                >
                  {sort !== undefined ? (
                    <TableSortLabel
                      onClick={() => handleSortColumn(name)}
                      active={sort?.columnName === name}
                      direction={sort?.direction}
                      disabled={sortDisabled}
                    >
                      {label && t(label)}
                    </TableSortLabel>
                  ) : (
                    label && t(label)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!queryProps.isLoading && !data.rows.length ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {noDataText}
                </TableCell>
              </TableRow>
            ) : (
              data.rows.map(({ id, values, ...row }) => (
                <TableRow key={id} {...row}>
                  {values.map(({ value, ...props }, key) => (
                    <TableCell key={key} {...props}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AppTableFooter {...queryProps} />
    </Stack>
  );
};
