import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  LinearProgress,
  Typography,
  Stack,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import {
  StopRounded as StopIcon,
  BrokenImageRounded as BrokenImageIcon,
} from '@mui/icons-material';

import AppDialog, { AppConfirmationDialog } from 'components/AppDialog';
import ProjectLayerImageMap from 'components/ProjectLayerImageMap';
import StockLayerImage from 'assets/images/stock-layer-image.png';
import { useSnackbar } from 'hooks';
import {
  GenerateState,
  getProjectLayerImage,
  LayerInfo,
  ProjectState,
  stopGenerate,
} from 'api';

const UPDATE_WINDOW = 1.5; // in seconds

interface Props {
  open: boolean;
  generateState: GenerateState;
  onClose: () => void;
  isLoading?: boolean;
}

export const GeneratingDialog: FC<Props> = ({
  open,
  generateState,
  onClose,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancelDialog, setCancelDialog] = useState(false);

  const [currentLayerImage, setCurrentLayerImage] = useState<{
    data: string;
    index: number | null;
  }>({ data: StockLayerImage, index: null });

  const [isWithinUpdateWindow, setIsWithinUpdateWindow] =
    useState<boolean>(true);

  const currentLayer = useMemo<LayerInfo | undefined>(
    () =>
      generateState.layers.length
        ? generateState.layers[generateState.layers.length - 1]
        : undefined,
    [generateState],
  );

  const { data: layerImage, isError: isErrorLayerImage } = useQuery(
    ['layer-image', { layer: generateState.layers.length, projectId }],
    () => {
      if (projectId)
        return getProjectLayerImage(projectId, {
          layer: generateState.layers.length,
        });
    },
    {
      onSuccess: () => {
        closeSnackbar('get_layer_image_fail');
      },
      onError: () => {
        enqueueSnackbar({
          key: 'get_layer_image_fail',
          message: t('get_layer_image_fail'),
          variant: 'error',
        });
      },
      enabled: !!generateState.layers.length && !!projectId,
    },
  );

  useEffect(() => {
    if (layerImage)
      setCurrentLayerImage({
        index: generateState.layers.length,
        data: `data:image/png;base64,${layerImage}`,
      });
  }, [generateState.layers.length, layerImage]);

  const layerInfo = useMemo<{ name: string; value: number | undefined }[]>(
    () => [
      { name: 'layer_number', value: generateState.layers.length },
      {
        name: 'build_height',
        value: currentLayer?.layerZTop,
      },
      {
        name: 'energy_of_layer',
        value: currentLayer
          ? Math.floor(currentLayer.energyOfLayer * 10e2) / 10e2
          : undefined,
      },
      {
        name: 'melt_time',
        value: currentLayer?.timeOfLayer,
      },
      {
        name: 'melt_time_total',
        value: currentLayer?.timeAccumulated,
      },
    ],
    [currentLayer, generateState],
  );

  const { mutate: onStopGenerate, isLoading: isStoppingGenerate } = useMutation(
    (id: string) => stopGenerate(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['project', { projectId }]);
        queryClient.invalidateQueries(['project-settings', { projectId }]);
        queryClient.invalidateQueries(['generate', { projectId }]);
        enqueueSnackbar({
          key: `stop_generate_success_${Date.now()}`,
          message: t('stop_generate_success'),
          variant: 'success',
          persist: true,
        });
        setCancelDialog(false);
      },
      onError: () => {
        enqueueSnackbar({
          key: `stop_generate_fail_${Date.now()}`,
          message: t('stop_generate_fail'),
          variant: 'error',
          persist: true,
        });
      },
    },
  );

  const stopDisabled = useMemo<boolean>(
    () =>
      isStoppingGenerate ||
      generateState.projectState !== ProjectState.Generating,
    [generateState, isStoppingGenerate],
  );

  const progressVariant = useMemo<
    ComponentProps<typeof LinearProgress>['variant']
  >(() => {
    if (isLoading) return 'query';
    if (generateState.projectState === ProjectState.GenerationQueued)
      return 'indeterminate';
    return 'determinate';
  }, [generateState.projectState, isLoading]);

  const progressColor = useMemo<
    ComponentProps<typeof LinearProgress>['color']
  >(() => {
    if (generateState.projectState === ProjectState.GenerationQueued)
      return 'secondary';
    if (
      generateState.projectState === ProjectState.GenerationCanceled ||
      isStoppingGenerate
    )
      return 'warning';
    if (generateState.projectState === ProjectState.GenerationFailed)
      return 'error';
    return 'primary';
  }, [generateState.projectState, isStoppingGenerate]);

  useEffect(() => {
    if (
      generateState.layers.length &&
      generateState.layers.length !== currentLayerImage.index
    ) {
      const timeout = setTimeout(() => {
        setIsWithinUpdateWindow(false);
      }, UPDATE_WINDOW * 1000);
      return () => {
        clearTimeout(timeout);
      };
    } else if (!isWithinUpdateWindow) setIsWithinUpdateWindow(true);
  }, [
    currentLayerImage.index,
    generateState.layers.length,
    isWithinUpdateWindow,
  ]);

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={t('generating_build_files')}
        actions={
          <Button
            variant="contained"
            startIcon={<StopIcon />}
            color="error"
            onClick={() => setCancelDialog(true)}
            fullWidth
            disabled={stopDisabled}
          >
            {t('cancel')}
          </Button>
        }
        dismissIcon="minimize"
        dialogProps={{ maxWidth: 'md' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Stack width={{ xs: 1 / 2, md: 1 }} direction="row">
              {!isErrorLayerImage && isWithinUpdateWindow ? (
                <img
                  src={currentLayerImage.data}
                  style={{ width: '100%' }}
                  alt="current-layer"
                />
              ) : (
                <Stack width={1} pt={1} position="relative" alignItems="center">
                  {isErrorLayerImage ? (
                    <BrokenImageIcon
                      sx={{ position: 'absolute', top: '50%' }}
                    />
                  ) : (
                    <CircularProgress
                      sx={{ position: 'absolute', top: '50%' }}
                    />
                  )}
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack height={1} px={4} spacing={1}>
              <Stack flex={1} justifyContent="space-between" spacing={1}>
                {layerInfo.map(({ name, value }) =>
                  isLoading ? (
                    <Skeleton height="21.6px" />
                  ) : (
                    <Typography key={name}>
                      {t(name, { arg: value ?? '-' })}
                    </Typography>
                  ),
                )}
              </Stack>
              <Stack sx={{ flex: 1 }} />
              <ProjectLayerImageMap />
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ position: 'relative', mt: 4 }}>
            <LinearProgress
              value={generateState.progressPercentage}
              variant={progressVariant}
              color={progressColor}
            />
            {progressVariant === 'indeterminate' && (
              <Typography
                sx={{
                  width: 1,
                  position: 'absolute',
                  top: 10,
                  fontWeight: 100,
                }}
                align="center"
                variant="h5"
                color="textSecondary"
              >
                {t('in_queue')}
              </Typography>
            )}
            {progressVariant === 'determinate' && (
              <Typography
                sx={{
                  width: 1,
                  position: 'absolute',
                  top: 10,
                  fontWeight: 100,
                }}
                align="center"
                variant="h5"
                color="textSecondary"
              >
                {generateState.progressPercentage}%
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="right">
              {t('project_state_refetch_interval')}
            </Typography>
          </Grid>
        </Grid>
      </AppDialog>
      <AppConfirmationDialog
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        title={t('cancel_generate')}
        text={t('cancel_generate_confirmation')}
        onConfirm={() => {
          if (projectId) onStopGenerate(projectId);
        }}
        confirmDisabled={!projectId || stopDisabled}
      />
    </>
  );
};
