import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Button, Typography } from '@mui/material';
import { PlayArrowRounded as StartIcon } from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { useSaveProject, useSnackbar } from 'hooks';
import { BuildSettings, startGenerate } from 'api';
import { validateNumber } from 'helpers';
// Redux
import { useSelector } from 'store';
import { selectPartitions } from 'slices/partitionSettingsSlice';
import { selectSavedValues } from 'slices/meltSettingsSlice';
import {
  buildSettingsValidations,
  selectBuildSettings,
} from 'slices/buildSettingsSlice';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const StartGenerateDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const partitions = useSelector(selectPartitions);
  const meltSettingsValues = useSelector(selectSavedValues);
  const buildSettingsValues = useSelector(selectBuildSettings);
  const { onSaveProject, isLoading: isSavingProject } = useSaveProject();

  const validationMessage = useMemo<
    { info: string; severity: 'warning' | 'error' } | undefined
  >(() => {
    if (!buildSettingsValues.absolutePath) {
      return {
        info: 'project_generate_validation_error_no_absolute_path',
        severity: 'error',
      };
    }
    if (
      Object.entries(buildSettingsValidations).some(
        ([key, options]) =>
          !validateNumber(
            buildSettingsValues[key as keyof BuildSettings],
            options,
          ),
      )
    ) {
      return {
        info: 'project_generate_validation_warning_invalid_values',
        severity: 'error',
      };
    }
    if (
      buildSettingsValues.absolutePath.charAt(
        buildSettingsValues.absolutePath.length - 1,
      ) === '/'
    ) {
      return {
        info: 'project_generate_validation_warning_tailing_slash',
        severity: 'warning',
      };
    }
    return;
  }, [buildSettingsValues]);

  const { mutate: onStartGenerate, isLoading: isStartingGenerate } =
    useMutation<void, AxiosError<string>>(
      () => {
        if (
          partitions.some(
            ({ partitionID }) =>
              !meltSettingsValues.some((v) => v.partitionID === partitionID),
          )
        )
          throw new Error('some partitions are missing melt settings');
        return startGenerate(projectId);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['project', { projectId }]);
          queryClient.invalidateQueries(['project-settings', { projectId }]);
          queryClient.invalidateQueries(['generate', { projectId }]);
          enqueueSnackbar({
            key: `start_generate_success_${Date.now()}`,
            message: t('start_generate_success'),
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar({
            key: `start_generate_fail_${Date.now()}`,
            message: t('start_generate_fail'),
            variant: 'error',
            persist: true,
          });
        },
      },
    );

  const handleStartGenerate = useCallback<() => void>(() => {
    onSaveProject().then(() => onStartGenerate());
  }, [onSaveProject, onStartGenerate]);

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title={t('generating_build_files')}
        actions={
          <Button
            variant="contained"
            startIcon={<StartIcon />}
            onClick={handleStartGenerate}
            fullWidth
            disabled={isStartingGenerate || isSavingProject}
          >
            {t('start')}
          </Button>
        }
        dismissIcon="minimize"
        dialogProps={{ maxWidth: 'md' }}
      >
        <Typography paragraph>{t('start_generate_confirmation')}</Typography>
        {validationMessage && (
          <Typography color={`${validationMessage.severity}.main`}>
            {t(validationMessage.severity)}: {t(validationMessage.info)}
          </Typography>
        )}
      </AppDialog>
    </>
  );
};
