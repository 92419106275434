/**
 * @summary Function to return correct addition value of two float numbers
 * @param {number}  dec number of decimals
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const addWithDecimals = (dec: number, n1: number, n2: number): number =>
  Number((n1 + n2).toFixed(dec));

/**
 * @summary Function to return correct subtraction value of two float numbers
 * @param {number}  dec number of decimals
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const subtractWithDecimals = (
  dec: number,
  n1: number,
  n2: number,
): number => Number((n1 - n2).toFixed(dec));

/**
 * @summary Function to return correct multiplication value of two float numbers
 * @param {number}  dec number of decimals
 * @param {number}  n1  first number
 * @param {number}  n2  second number
 * @returns {number}
 */
export const multiplyWithDecimals = (
  dec: number,
  n1: number,
  n2: number,
): number => Number((n1 * n2).toFixed(dec));
