import { ChangeEvent, FC, useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Switch,
} from '@mui/material';
import { InfoRounded as InfoIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import AppDialog from 'components/AppDialog';
// Redux
import { useSelector, useDispatch } from 'store';
import { selectKeyboardShortcuts, setKeyboardShortcuts } from 'slices/appSlice';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AppKeyboardShortcutsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const keyboardShortcuts = useSelector(selectKeyboardShortcuts);

  const items = useMemo<
    { title: string; combination: string; description?: string }[]
  >(() => Object.values(t('shortcuts:items', { returnObjects: true })), [t]);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t('keyboard_shortcuts')}
      headerAction={
        <Switch
          checked={keyboardShortcuts}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            dispatch(setKeyboardShortcuts(event.target.checked));
          }}
        />
      }
      actions={null}
      dialogProps={{
        PaperProps: { sx: { height: '80vh', overflow: 'hidden' } },
        maxWidth: 'sm',
      }}
    >
      <TableContainer sx={{ position: 'relative' }}>
        <Table aria-label="keyboard-shortcuts-table">
          <TableHead>
            <TableRow>
              <TableCell>{t('title')}</TableCell>
              <TableCell align="right">{t('combination')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ title, combination, description }, key) => (
              <TableRow key={key}>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                  {title}
                  {description && (
                    <Tooltip title={description} placement="right" arrow>
                      <InfoIcon sx={{ pl: 1 }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="right">{combination}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AppDialog>
  );
};
