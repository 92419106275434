import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button, IconButton, Skeleton } from '@mui/material';
import {
  CheckRounded as SubmitIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppToggleButtonForm } from 'components/AppFormControl';
import {
  updateUser,
  ErrorResponse,
  getUserDetails,
  UserDetailsWithEtag,
  UserRole,
} from 'api';
import { useSnackbar } from 'hooks';

const SELECTABLE_ROLES: string[] = [UserRole.OrganizationAdmin, UserRole.User];

const isUserRole = (arg: unknown): arg is UserRole =>
  typeof arg === 'string' && SELECTABLE_ROLES.includes(arg);

interface Props {
  userId: string | undefined;
  onClose: () => void;
}

export const UpdateUserDialog: FC<Props> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [role, setRole] = useState<UserRole>();

  const handleClose = useCallback<() => void>(() => {
    setRole(undefined);
    onClose();
  }, [onClose]);

  const {
    data: details,
    isLoading: isDetailsLoading,
    isRefetching: isDetailsRefetching,
  } = useQuery<UserDetailsWithEtag, AxiosError<ErrorResponse>>(
    ['user', { userId }],
    () => {
      if (!userId) throw new Error('user id does not exist');
      return getUserDetails(userId);
    },
    {
      enabled: !!userId,
      onError: () => {
        enqueueSnackbar({
          key: `get_user_details_fail_${Date.now()}`,
          message: t('get_user_details_fail'),
          variant: 'error',
        });
      },
    },
  );

  const { mutate: onUpdateUser, isLoading: isUpdating } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    ['user', { userId }],
    () => {
      if (!details) throw new Error('user details does not exist');
      if (!role) throw new Error('role cannot be undefined');
      return updateUser(
        { email: details.data.email, type: role },
        details.etag,
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `update_user_success_${Date.now()}`,
          message: t('update_user_success', {
            email: details?.data.email,
            role: t(`userRole:${role}`),
          }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['user', { userId }]);
        handleClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'update_user_decrepit',
            message: t('update_user_decrepit', {
              email: details?.data.email,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient.refetchQueries(['user', { userId }]).then(() => {
                    closeSnackbar('update_user_decrepit');
                  });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `update_user_fail_${Date.now()}`,
            message: t('update_user_fail', { email: details?.data.email }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  const handleSubmit = useCallback<() => void>(() => {
    if (role !== details?.data.type) {
      onUpdateUser();
    } else {
      handleClose();
    }
  }, [details?.data.type, handleClose, onUpdateUser, role]);

  useEffect(() => {
    if (details?.data.type) setRole(details?.data.type);
  }, [details?.data.type]);

  return (
    <AppDialog
      open={!!userId}
      onClose={handleClose}
      title={t('update_user')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          startIcon={<SubmitIcon />}
          disabled={isUpdating || isDetailsLoading || isDetailsRefetching}
          fullWidth
        >
          {t('update')}
        </Button>
      }
    >
      <Stack spacing={3}>
        {role ? (
          <AppToggleButtonForm
            label={t('role')}
            value={role}
            onChange={(value) => {
              if (isUserRole(value)) setRole(value);
            }}
            buttons={SELECTABLE_ROLES.map((value) => ({
              value,
              text: t(`userRole:${value}`),
            }))}
            helperText={t('update_user_role_helper')}
          />
        ) : (
          <Skeleton />
        )}
      </Stack>
    </AppDialog>
  );
};
