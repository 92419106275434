import { FC, useMemo } from 'react';
import { Box } from '@mui/material';

import { PanelMenus } from 'components/ProjectModelPreview';
import Project3DView from 'components/Project3DView';
import { ModelDetails, Partition } from 'api';
import { countDecimals, subtractWithDecimals } from 'helpers';
// Redux
import { useSelector } from 'store';
import {
  selectPartitions,
  selectSelectedPartitions,
} from 'slices/partitionSettingsSlice';

interface Props {
  model: ModelDetails | undefined;
  layerThickness: number;
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const ProjectModelPreview: FC<Props> = ({
  model,
  layerThickness,
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const partition = useSelector(selectPartitions);
  const selectedPartitions = useSelector(selectSelectedPartitions);

  const highlights = useMemo<Partition[]>(() => {
    const numberOfDecimals = countDecimals(layerThickness);
    return partition
      .filter(({ partitionID }) => selectedPartitions.includes(partitionID))
      .map((selectPartition) => ({
        ...selectPartition,
        startZ: subtractWithDecimals(
          numberOfDecimals,
          selectPartition.startZ,
          layerThickness,
        ),
      }));
  }, [partition, layerThickness, selectedPartitions]);

  return (
    <Box height={1} width={1}>
      <PanelMenus
        userHasWritePermission={userHasWritePermission}
        generateHasInitiated={generateHasInitiated}
      />
      <Project3DView
        model={model}
        highlights={highlights}
        settings={{
          settingsAnchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          settingsTransformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          settingsFabStyle: {
            right: '50%',
          },
        }}
      />
    </Box>
  );
};
