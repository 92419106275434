import { FC, MutableRefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { List, Popover, Typography } from '@mui/material';
import {
  PersonRounded as UserIcon,
  KeyboardRounded as ShortcutIcon,
  LogoutRounded as LogoutIcon,
  ContactSupportRounded as ContactIcon,
} from '@mui/icons-material';

import AppKeyboardShortcutsDialog from 'components/AppKeyboardShortcutsDialog';
import AppContactUsDialog from 'components/AppContactUsDialog';
import AppButton from 'components/AppButton';
import { AppButtonListItem, AppDividerListItem } from 'components/AppListItem';
import { useAuthentication } from 'hooks';
// Redux
import { useSelector } from 'store';
import { selectUser, selectState } from 'slices/userSlice';

interface Props {
  anchor: MutableRefObject<HTMLDivElement | null>;
}

export const AppUserQuickSettings: FC<Props> = ({ anchor }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [keyboardShortcuts, setKeyboardShortcuts] = useState<boolean>(false);
  const [contactUs, setContactUs] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const { isLoading } = useSelector(selectState);
  const { logout } = useAuthentication();

  const onLogout = useCallback<() => void>(() => {
    queryClient.clear();
    logout();
  }, [logout, queryClient]);

  return (
    <>
      <AppButton
        title={t('user_settings')}
        buttonProps={{
          onClick: () => setAnchorEl(anchor.current),
        }}
      >
        <UserIcon />
      </AppButton>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { minWidth: 250 } }}
        sx={{ mt: 2 }}
      >
        <List>
          <Typography
            align="center"
            component="li"
            sx={{ p: 3, fontWeight: 'bold', textTransform: 'none' }}
          >
            {user && user.email}
          </Typography>
          <AppDividerListItem />
          <AppButtonListItem
            onClick={() => {
              setKeyboardShortcuts(true);
            }}
            icon={<ShortcutIcon />}
            text={t('keyboard_shortcuts')}
          />
          <AppButtonListItem
            onClick={() => {
              setContactUs(true);
            }}
            icon={<ContactIcon />}
            text={t('contact_us')}
          />
          <AppDividerListItem />
          <AppButtonListItem
            onClick={onLogout}
            icon={<LogoutIcon />}
            text={t('sign_out')}
            disabled={isLoading}
          />
        </List>
      </Popover>
      <AppKeyboardShortcutsDialog
        open={keyboardShortcuts}
        onClose={() => {
          setKeyboardShortcuts(false);
        }}
      />
      <AppContactUsDialog
        open={contactUs}
        onClose={() => {
          setContactUs(false);
        }}
      />
    </>
  );
};
