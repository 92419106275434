import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, SvgIcon, Typography } from '@mui/material';

import { MenuItem } from 'components/ProjectMenuPanel';

interface Props extends Omit<MenuItem, 'Panel'> {
  active: boolean;
  onClick: () => void;
}

export const ProjectMenuItem: FC<Props> = ({
  Icon,
  name,
  disabled,
  active,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      sx={{
        color: active ? 'primary.main' : 'text.primary',
        p: 2,
        '&:disabled': {
          color: 'text.disabled',
        },
        '&:hover, &:focus, &:active': {
          color: active ? 'primary.main' : 'primary.light',
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Box>
        <SvgIcon fontSize="large">
          <Icon />
        </SvgIcon>
        <Typography variant="body2">{t(name)}</Typography>
      </Box>
    </ButtonBase>
  );
};
