import { ChangeEvent, FC } from 'react';
import { Box, Typography, Checkbox, CheckboxProps } from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

interface Props extends AppFormControlProps {
  value: boolean;
  setValue: (value: boolean) => void;
  componentProps?: CheckboxProps;
}

export const AppCheckboxForm: FC<Props> = ({
  label,
  value,
  setValue,
  componentProps,
  ...formControlProps
}) => {
  return (
    <AppFormControl label={label} {...formControlProps} hideFormLabel>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{label}</Typography>
        <Box sx={{ flex: 1 }} />
        <Checkbox
          checked={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.checked)
          }
          {...componentProps}
        />
      </Box>
    </AppFormControl>
  );
};
