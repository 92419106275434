import {
  useEffect,
  useMemo,
  useCallback,
  FC,
  useState,
  createRef,
  RefObject,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import {
  Dialog,
  Grid,
  Stack,
  Typography,
  Tooltip,
  Button,
  IconButton,
  useMediaQuery,
  Theme,
} from '@mui/material';
import {
  CloseRounded as CloseIcon,
  CheckRounded as SubmitIcon,
} from '@mui/icons-material';
import { v4 as uuid } from 'uuid';

import { AppConfirmationDialog } from 'components/AppDialog';
import Project3DView from 'components/Project3DView';
import { AppCheckboxForm, AppTextForm } from 'components/AppFormControl';
import AppModelForm from 'components/AppModelForm';
import ProjectPartitionForm from 'components/ProjectPartitionForm';
import ProjectMeltOrder from 'components/ProjectMeltOrder';
import {
  createProject,
  CreateProject,
  ErrorResponse,
  getModelDetails,
  ModelDetailsWithEtag,
  Partition,
  ProjectSettingsResponseWithEtag,
} from 'api';
import { useSearchParams, useSnackbar } from 'hooks';
import {
  countDecimals,
  getReduxProjectSettings,
  subtractWithDecimals,
} from 'helpers';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  setMeltAllObjectsSimultaneously,
  setTransform,
  selectMeltAllObjectsSimultaneously,
  selectTransform,
  resetPartitionSettings,
  setPartitions,
  setSelection,
  setObjectMeltOrder,
} from 'slices/partitionSettingsSlice';
import {
  selectBuildSettings,
  setAbsolutePath,
  setBuildPistonDistance,
  setPowderPistonDistance,
} from 'slices/buildSettingsSlice';

type CreateProjectFields = Exclude<keyof CreateProject, 'settings'>;
interface Props {
  open: boolean;
  onClose: () => void;
}

export const CreateProjectDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.only('xs'),
  );
  const [, { updateSearchParam }] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [closeDialog, setCloseDialog] = useState(false);
  const dispatch = useDispatch();
  const meltAllObjectsSimultaneously = useSelector(
    selectMeltAllObjectsSimultaneously,
  );
  const transform = useSelector(selectTransform);
  const { absolutePath } = useSelector(selectBuildSettings);
  const [projectName, setProjectName] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [modelId, setModelId] = useState<string>();
  const [highlights, setHighlights] =
    useState<Omit<Partition, 'partitionID'>[]>();

  const [validationError, setValidationError] = useState<
    (keyof CreateProject)[]
  >([]);
  const refs = useMemo<
    Record<CreateProjectFields, RefObject<HTMLInputElement | HTMLButtonElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      notes: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      cadModelId: createRef<HTMLButtonElement>(),
    }),
    [],
  );

  const handleResetForm = useCallback<() => void>(() => {
    dispatch(resetPartitionSettings());
    setProjectName('');
    setMaterial('');
    setNotes('');
    setModelId(undefined);
    setHighlights([]);
  }, [dispatch]);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    setValidationError([]);
    onClose();
  }, [handleResetForm, onClose]);

  const { data: model, isLoading: isLoadingModel } = useQuery<
    ModelDetailsWithEtag | undefined,
    AxiosError<string>
  >(
    ['model', { modelId }],
    () => {
      if (modelId) return getModelDetails(modelId);
    },
    {
      enabled: !!modelId,
      onError: () => {
        enqueueSnackbar({
          key: `get_model_fail_${Date.now()}`,
          message: t('get_model_fail'),
          variant: 'error',
        });
        onClose();
      },
    },
  );

  const { mutate: onCreateProject, isLoading: isLoadingCreate } = useMutation<
    ProjectSettingsResponseWithEtag,
    AxiosError<ErrorResponse>
  >(
    () =>
      createProject({
        name: projectName,
        material,
        notes,
        settings: getReduxProjectSettings(),
        cadModelId: model?.data.id ?? '',
      }),
    {
      onSuccess: ({ data }) => {
        enqueueSnackbar({
          key: `create_project_success_${Date.now()}`,
          message: t('create_project_success'),
          variant: 'success',
        });
        queryClient.invalidateQueries(['projects']);
        updateSearchParam(['rowId', data.id]);
        handleClose();
      },
      onError: ({ response }) => {
        enqueueSnackbar({
          key: `create_project_fail_${Date.now()}`,
          message: t('create_project_fail'),
          variant: 'error',
          persist: true,
        });
        if (response?.status === 400) {
          if (response.data.errors) {
            const errorKeys = Object.keys(response.data.errors)
              .map<CreateProjectFields | undefined>((key) => {
                if (key === '$.cadModelId' || key === 'CadModelId')
                  return 'cadModelId';
                if (key === 'Name') return 'name';
                if (key === 'Material') return 'material';
                if (key === 'Notes') return 'notes';
                return undefined;
              })
              .filter((key): key is CreateProjectFields => !!key);
            setValidationError(errorKeys);
            refs[errorKeys[0]]?.current?.focus();
          }
        }
      },
    },
  );

  const handleHighlightObject = useCallback<(objectID?: number) => void>(
    (objectID) => {
      const targetObject = model?.data.fileInfo.objects.find(
        (object) => object.objectID === objectID,
      );
      setHighlights(
        targetObject && objectID !== undefined
          ? [
              {
                startZ: subtractWithDecimals(
                  countDecimals(targetObject.layerThickness),
                  targetObject.startZ,
                  targetObject.layerThickness,
                ),
                endZ: targetObject.endZ,
                objectID,
              },
            ]
          : undefined,
      );
    },
    [model?.data.fileInfo.objects],
  );

  useEffect(() => {
    setValidationError([]);
  }, [projectName, material, notes, modelId]);

  useEffect(() => {
    dispatch(setTransform({ x: 0, y: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (model) {
      dispatch(setSelection('model'));
      dispatch(
        setPartitions([
          {
            partitionID: uuid(),
            startZ: model.data.fileInfo.startZ,
            endZ: model.data.fileInfo.endZ,
            objectID: -1,
          },
        ]),
      );
      dispatch(
        setObjectMeltOrder(
          model.data.fileInfo.objects.map(({ objectID }) => objectID),
        ),
      );
      dispatch(
        setBuildPistonDistance(String(model.data.fileInfo.layerThickness)),
      );
      dispatch(
        setPowderPistonDistance(String(model.data.fileInfo.layerThickness * 2)),
      );
    }
    dispatch(
      setAbsolutePath(`/home/freemelt/${projectName}`.replaceAll(' ', '_')),
    );
  }, [absolutePath, dispatch, model, projectName]);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: { height: 1, minHeight: '80vh', overflow: 'hidden' },
        }}
        fullScreen={smallScreenSize}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          onClick={() => setCloseDialog(true)}
          sx={{
            zIndex: 1,
            position: 'absolute',
            right: (theme) => theme.spacing(2),
            top: (theme) => theme.spacing(2),
          }}
          disabled={isLoadingCreate || isLoadingModel}
        >
          <CloseIcon />
        </IconButton>
        <Grid container sx={{ height: 1 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              height: 1,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              py: 4,
              px: 2,
            }}
          >
            <Stack height={1} justifyContent="space-between">
              <Stack spacing={3} sx={{ pl: 2, overflowY: 'scroll' }}>
                <Typography variant="h4">{t('new_project')}</Typography>
                <Typography variant="h5">{t('project_info')}</Typography>
                <AppTextForm
                  label={t('project_name')}
                  value={projectName}
                  onChange={setProjectName}
                  componentProps={{
                    placeholder: model?.data.name,
                    inputRef: refs.name,
                  }}
                  helperText={t('project_info_name_helper')}
                  error={validationError.includes('name')}
                  errorText={t('validations:create_project.name')}
                />
                <AppTextForm
                  label={t('material')}
                  value={material}
                  onChange={setMaterial}
                  componentProps={{ inputRef: refs.material }}
                  helperText={t('project_info_material_helper')}
                  error={validationError.includes('material')}
                  errorText={t('validations:create_project.material')}
                />
                <AppTextForm
                  label={t('notes')}
                  value={notes}
                  onChange={setNotes}
                  componentProps={{ multiline: true, inputRef: refs.notes }}
                  helperText={t('project_info_notes_helper')}
                  error={validationError.includes('notes')}
                  errorText={t('validations:create_project.notes')}
                />
                <Typography variant="h5">{t('model')}</Typography>
                <AppModelForm
                  ref={refs.cadModelId as RefObject<HTMLButtonElement>}
                  data={model?.data}
                  onChangeModelId={setModelId}
                  error={validationError.includes('cadModelId')}
                  errorText={t('validations:create_project.cadModelId')}
                />
                <ProjectPartitionForm
                  modelInfo={model?.data.fileInfo}
                  modelName={model?.data.name}
                  loading={isLoadingModel}
                />
                <AppCheckboxForm
                  label={t('transform_model')}
                  helperText={t('transform_model_helper', { transform: 50 })}
                  value={transform}
                  setValue={(v) => {
                    dispatch(
                      setTransform(v ? { x: 50, y: 50 } : { x: 0, y: 0 }),
                    );
                  }}
                />
                <AppCheckboxForm
                  label={t('simultaneous_melting')}
                  helperText={t('simultaneous_melting_helper')}
                  value={meltAllObjectsSimultaneously}
                  setValue={(v) => {
                    dispatch(setMeltAllObjectsSimultaneously(v));
                  }}
                />
                <ProjectMeltOrder
                  modelInfo={model?.data.fileInfo}
                  onHighlightObject={handleHighlightObject}
                />
              </Stack>
              <Stack direction="row" spacing={2} mx={2} mt={2}>
                <Tooltip title={t('create_project_dialog_cancel') as string}>
                  <span style={{ flex: 1 }}>
                    <Button
                      onClick={() => setCloseDialog(true)}
                      variant="outlined"
                      color="inherit"
                      disabled={isLoadingCreate || isLoadingModel}
                      startIcon={<CloseIcon />}
                      sx={{ height: 1 }}
                      fullWidth
                    >
                      {t('cancel')}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={t('create_project_helper') as string}>
                  <span style={{ flex: 2 }}>
                    <Button
                      onClick={() => onCreateProject()}
                      variant="contained"
                      startIcon={<SubmitIcon />}
                      disabled={isLoadingCreate || isLoadingModel}
                      fullWidth
                    >
                      {t('create_project')}
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={6} md={8}>
            <Project3DView
              model={model?.data}
              highlights={highlights}
              settings={
                smallScreenSize
                  ? undefined
                  : {
                      settingsAnchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      settingsTransformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      settingsFabStyle: {
                        right: (theme) => theme.spacing(2),
                      },
                    }
              }
            />
          </Grid>
        </Grid>
      </Dialog>
      <AppConfirmationDialog
        open={closeDialog}
        onClose={() => setCloseDialog(false)}
        title={t('cancel')}
        text={t('create_project_dialog_cancel_confirmation')}
        onConfirm={() => {
          handleClose();
          setCloseDialog(false);
        }}
      />
    </>
  );
};
