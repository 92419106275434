import { ComponentProps, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, Tooltip, useMediaQuery, Theme } from '@mui/material';
import { ConstructionRounded as GenerateIcon } from '@mui/icons-material';

import { GenerateState, ProjectState } from 'api';
import { useKeyboardShortcut, useSnackbar } from 'hooks';
// Redux
import { useSelector } from 'store';
import { selectPartitions } from 'slices/partitionSettingsSlice';
import { selectSavedValues } from 'slices/meltSettingsSlice';

interface Props {
  onOpen: () => void;
}

export const ProjectBuildFilesButton: FC<Props> = ({ onOpen }) => {
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'));
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const partitions = useSelector(selectPartitions);
  const meltSettingsValues = useSelector(selectSavedValues);

  const { data: generateState } = useQuery<GenerateState>([
    'generate',
    { projectId },
  ]);

  const disabled = useMemo<boolean>(
    () =>
      !meltSettingsValues.length ||
      partitions.some(
        ({ partitionID }) =>
          !meltSettingsValues.some((v) => v.partitionID === partitionID),
      ),
    [meltSettingsValues, partitions],
  );

  const color = useMemo<ComponentProps<typeof Button>['color']>(() => {
    if (generateState) {
      if (generateState.projectState === ProjectState.GenerationFailed)
        return 'error';
      if (generateState.projectState === ProjectState.GenerationCanceled)
        return 'warning';
      if (
        generateState.projectState === ProjectState.Generating ||
        generateState.projectState === ProjectState.GenerationQueued
      )
        return 'secondary';
    }
    return 'primary';
  }, [generateState]);

  useKeyboardShortcut({ key: 'g', ctrl: true }, () => {
    if (disabled) {
      enqueueSnackbar({
        key: 'missing_melt_settings',
        message: t('missing_melt_settings'),
        variant: 'info',
      });
    } else {
      onOpen();
    }
  });

  if (!generateState) return null;

  return (
    <Tooltip
      title={
        t(
          disabled ? 'missing_melt_settings' : 'generate_build_files_helper',
        ) as string
      }
      placement="bottom"
    >
      <span>
        <Button
          variant="contained"
          color={color}
          size="small"
          startIcon={!xs && <GenerateIcon />}
          onClick={onOpen}
          disabled={disabled}
          sx={{ minWidth: { xs: 32, sm: 160 }, width: { xs: 32, sm: 'auto' } }}
        >
          {xs ? (
            <GenerateIcon />
          ) : (
            t(`generate_build_files_${generateState.projectState}`, {
              progress: generateState.progressPercentage,
            })
          )}
        </Button>
      </span>
    </Tooltip>
  );
};
