import { FC, useMemo } from 'react';
import { SnackbarProvider as Provider, SnackbarProviderProps } from 'notistack';

export const SnackbarProvider: FC = ({ children }) => {
  const snackbarProviderProps = useMemo<
    Omit<SnackbarProviderProps, 'children'>
  >(
    () => ({
      maxSnack: 4,
      preventDuplicate: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      hideIconVariant: true,
    }),
    [],
  );

  return <Provider {...snackbarProviderProps}>{children}</Provider>;
};
