import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Button, Stack } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppTextForm, AppToggleButtonForm } from 'components/AppFormControl';
import {
  addUserToOrganization,
  CreateUser,
  ErrorResponse,
  UserDetailsWithEtag,
  UserRole,
} from 'api';
import { useSnackbar } from 'hooks';

const SELECTABLE_ROLES: string[] = [UserRole.OrganizationAdmin, UserRole.User];

const isUserRole = (arg: unknown): arg is UserRole =>
  typeof arg === 'string' && SELECTABLE_ROLES.includes(arg);

interface Props {
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

export const AddUserToOrganizationDialog: FC<Props> = ({
  open,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [role, setRole] = useState<UserRole>(UserRole.User);

  const [validationError, setValidationError] = useState<(keyof CreateUser)[]>(
    [],
  );
  const refs = useMemo<Record<keyof CreateUser, RefObject<HTMLInputElement>>>(
    () => ({
      email: createRef<HTMLInputElement>(),
      password: createRef<HTMLInputElement>(),
      type: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const handleResetForm = useCallback<() => void>(() => {
    setEmail('');
    setPassword('');
    setRole(UserRole.User);
  }, []);

  const handleClose = useCallback<() => void>(() => {
    handleResetForm();
    setValidationError([]);
    onClose();
  }, [handleResetForm, onClose]);

  const { mutate: onAddUserToOrganization, isLoading: isCreating } =
    useMutation<UserDetailsWithEtag, AxiosError<ErrorResponse>>(
      () =>
        addUserToOrganization(organizationId, { email, password, type: role }),
      {
        onSuccess: ({ data }) => {
          enqueueSnackbar({
            key: `add_user_to_organization_success_${Date.now()}`,
            message: t('add_user_to_organization_success', {
              email: data.email,
            }),
            variant: 'success',
          });
          queryClient.invalidateQueries(['users']);
          handleClose();
        },
        onError: ({ response }) => {
          enqueueSnackbar({
            key: `add_user_to_organization_fail_${Date.now()}`,
            message: t('add_user_to_organization_fail'),
            variant: 'error',
            persist: true,
          });
          if (response?.status === 400) {
            if (response.data.errors) {
              const errorKeys = Object.keys(response.data.errors)
                .map<keyof CreateUser | undefined>((key) => {
                  if (key === 'Email') return 'email';
                  if (key === 'Password') return 'password';
                  if (key === 'Type') return 'type';
                  return undefined;
                })
                .filter((key): key is keyof CreateUser => !!key);
              setValidationError(errorKeys);
              refs[errorKeys[0]]?.current?.focus();
            }
          }
        },
      },
    );

  useEffect(() => {
    setValidationError([]);
  }, [email, password, role]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('new_user')}
      actions={
        <Button
          onClick={() => onAddUserToOrganization()}
          variant="contained"
          disabled={isCreating}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {t('create')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <AppToggleButtonForm
          ref={refs.type}
          label={t('role')}
          value={role}
          onChange={(value) => {
            if (isUserRole(value)) setRole(value);
          }}
          buttons={SELECTABLE_ROLES.map((value) => ({
            value,
            text: t(`userRole:${value}`),
          }))}
          helperText={t('create_user_role_helper')}
          error={validationError.includes('type')}
          errorText={t('validations:create_user.role')}
        />
        <AppTextForm
          label={t('email')}
          value={email}
          onChange={setEmail}
          helperText={t('create_user_email_helper')}
          error={validationError.includes('email')}
          errorText={t('validations:create_user.email')}
          componentProps={{ inputRef: refs.password }}
        />
        <AppTextForm
          label={t('password')}
          value={password}
          onChange={setPassword}
          helperText={t('create_user_password_helper')}
          error={validationError.includes('password')}
          errorText={t('validations:create_user.password')}
          componentProps={{
            type: 'password',
            autoComplete: 'new-password',
            inputRef: refs.password,
          }}
        />
      </Stack>
    </AppDialog>
  );
};
