import { FC, ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { List, ListItemProps } from '@mui/material';

import { AppDraggableListItem } from 'components/AppDraggableList';

interface Props {
  id: string;
  children: {
    component: ReactNode;
    listItemProps?: ListItemProps;
    tooltip?: string;
  }[];
  onDragEnd: OnDragEndResponder;
  disabled?: boolean;
}

export const AppDraggableList: FC<Props> = ({
  id,
  children,
  onDragEnd,
  disabled,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id} isDropDisabled={disabled}>
        {({ innerRef, droppableProps, placeholder }) => (
          <List ref={innerRef} {...droppableProps}>
            {children.map(({ component, ...props }, index) => (
              <AppDraggableListItem
                listId={id}
                index={index}
                key={index}
                disabled={disabled}
                {...props}
              >
                {component}
              </AppDraggableListItem>
            ))}
            {placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
