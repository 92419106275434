import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ExpandMoreRounded as ExpandIcon,
  AddCircleOutlineRounded as AdditionIcon,
  RemoveCircleOutlineRounded as DeletionIcon,
  AdjustRounded as ImprovementIcon,
} from '@mui/icons-material';

import { AppButtonListItem } from 'components/AppListItem';
import AppButton from 'components/AppButton';

interface Releases {
  [patch: string]: Date;
}
interface PatchNotes {
  additions?: { primary: string; secondary?: string }[];
  deletions?: { primary: string; secondary?: string }[];
  improvements?: { primary: string; secondary?: string }[];
}

const releases: Releases = {
  '1.0.0': new Date('2022/04/29'),
};

export const ReleaseNotes: FC = () => {
  const { t } = useTranslation();
  const { palette, breakpoints } = useTheme();
  const smallScreenSize = useMediaQuery(breakpoints.down('md'), {
    noSsr: true,
  });
  const { hash } = useLocation();
  const [expanded, setExpanded] = useState(true);

  return (
    <Stack
      height={1}
      overflow="hidden"
      direction={smallScreenSize ? 'column' : 'row'}
      m={2}
    >
      <Stack
        component={Paper}
        sx={{
          width: { xs: 1, md: 250 },
          maxHeight: { xs: expanded ? 1 : 54, md: 1 },
          height: { xs: expanded ? 1 : 54, md: 1 },
          mr: { xs: 0, md: 2 },
          mb: { xs: expanded ? 0 : 2, md: 0 },
          transition: 'all 0.5s',
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" justifyContent="space-between" px={5} py={2}>
          <Typography variant="h4">{t('release-notes')}</Typography>
          <AppButton
            title={t('expand')}
            buttonProps={{
              onClick: () => setExpanded((prevState) => !prevState),
            }}
            buttonSx={{ display: { xs: 'block', md: 'none' } }}
          >
            <ExpandIcon
              sx={{
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </AppButton>
        </Stack>
        <List
          sx={{
            overflowX: 'hidden',
            overflowY: { xs: expanded ? 'auto' : 'hidden', md: 'auto' },
          }}
        >
          {Object.entries(releases).map(([patch, date]) => (
            <Fragment key={patch}>
              <Link href={`#${encodeURI(patch)}`}>
                <AppButtonListItem
                  text={t('release_version', { patch, date })}
                  onClick={() => setExpanded(false)}
                  color={
                    hash === `#${encodeURI(patch)}`
                      ? palette.primary.main
                      : palette.text.primary
                  }
                />
              </Link>
            </Fragment>
          ))}
        </List>
      </Stack>
      <Paper
        sx={{
          flex: 1,
          height: { xs: expanded ? 0 : 1, md: 1 },
          maxHeight: { xs: expanded ? 0 : 1, md: 1 },
          p: { xs: expanded ? 0 : 4, md: 4 },
          transition: 'all 0.5s',
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
      >
        {Object.entries(releases).map(([patch, date]) => {
          const {
            additions = [],
            improvements = [],
            deletions = [],
          } = t<string, PatchNotes>(`releaseNotes:${patch}`, {
            returnObjects: true,
          });

          return (
            <Stack key={patch} alignItems="flex-start">
              <Link
                component={Button}
                id={encodeURI(patch)}
                href={`#${encodeURI(patch)}`}
                sx={{ textTransform: 'none', minWidth: 0 }}
                variant="h4"
              >
                {t('release_version', { patch, date })}
              </Link>
              {!!additions.length && (
                <Stack px={4}>
                  <Typography variant="h6">{t('additions')}</Typography>
                  <List>
                    {additions.map(({ primary, secondary }, key) => (
                      <ListItem key={key}>
                        <ListItemIcon>
                          <AdditionIcon />
                        </ListItemIcon>
                        <ListItemText primary={primary} secondary={secondary} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
              {!!improvements.length && (
                <Stack px={4}>
                  <Typography variant="h6">{t('improvements')}</Typography>
                  <List>
                    {improvements.map(({ primary, secondary }, key) => (
                      <ListItem key={key}>
                        <ListItemIcon>
                          <ImprovementIcon />
                        </ListItemIcon>
                        <ListItemText primary={primary} secondary={secondary} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
              {!!deletions.length && (
                <Stack px={4}>
                  <Typography variant="h6">{t('deletions')}</Typography>
                  <List>
                    {deletions.map(({ primary, secondary }, key) => (
                      <ListItem key={key}>
                        <ListItemIcon>
                          <DeletionIcon />
                        </ListItemIcon>
                        <ListItemText primary={primary} secondary={secondary} />
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              )}
            </Stack>
          );
        })}
      </Paper>
    </Stack>
  );
};
