import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import {
  SaveRounded as SaveIcon,
  DoNotDisturbRounded as DontSaveIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppButtonForm } from 'components/AppFormControl';
import { useKeyboardShortcut, useSaveProject } from 'hooks';
import { GenerateState, ProjectState } from 'api';
// Redux
import { useDispatch } from 'store';
import { resetProject } from 'slices/projectSlice';
import { resetPartitionSettings } from 'slices/partitionSettingsSlice';
import { resetMeltSettings } from 'slices/meltSettingsSlice';
import { resetBuildSettings } from 'slices/buildSettingsSlice';

interface Props {
  open: boolean;
  onStay: () => void;
  onLeave: () => void;
}

export const CloseProjectDialog: FC<Props> = ({ open, onStay, onLeave }) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const dispatch = useDispatch();
  const { onSaveProject, isLoading: isSavingProject } = useSaveProject();

  const { data: generateState } = useQuery<GenerateState>([
    'generate',
    { projectId },
  ]);

  const notGenerated = useMemo<boolean>(
    () => generateState?.projectState === ProjectState.NotGenerated,
    [generateState?.projectState],
  );

  const keyboardShortcutsDisabled = useMemo<boolean>(
    () => !open || !notGenerated,
    [open, notGenerated],
  );

  const handleCloseProject = useCallback<() => void>(() => {
    dispatch(resetPartitionSettings());
    dispatch(resetMeltSettings());
    dispatch(resetBuildSettings());
    dispatch(resetProject());
    onLeave();
  }, [dispatch, onLeave]);

  const handleSaveAndCloseProject = useCallback<() => void>(() => {
    if (!isSavingProject) onSaveProject().then(handleCloseProject);
  }, [handleCloseProject, isSavingProject, onSaveProject]);

  useKeyboardShortcut(
    { key: 'n' },
    handleCloseProject,
    keyboardShortcutsDisabled,
  );
  useKeyboardShortcut(
    { key: 'y' },
    handleSaveAndCloseProject,
    keyboardShortcutsDisabled,
  );

  return (
    <AppDialog
      open={open}
      onClose={onStay}
      title={t('close_project')}
      actions={
        notGenerated ? (
          <>
            <AppButtonForm
              label={t('close_project_without_saving')}
              helperText={t('close_project_without_saving_helper')}
              componentProps={{
                onClick: handleCloseProject,
                startIcon: <DontSaveIcon />,
                disabled: isSavingProject,
              }}
            />
            <AppButtonForm
              label={t('close_project_and_saving')}
              helperText={t('close_project_and_saving_helper')}
              componentProps={{
                onClick: handleSaveAndCloseProject,
                startIcon: <SaveIcon />,
                variant: 'contained',
                color: 'primary',
                disabled: isSavingProject,
              }}
            />
          </>
        ) : (
          <AppButtonForm
            label={t('close_project')}
            helperText={t('close_project_helper')}
            componentProps={{
              onClick: handleCloseProject,
              startIcon: <CloseIcon />,
              variant: 'contained',
              color: 'primary',
              autoFocus: true,
            }}
          />
        )
      }
    >
      <Typography>
        {t(
          notGenerated
            ? 'close_project_confirmation_unsaved'
            : 'close_project_confirmation',
        )}
      </Typography>
    </AppDialog>
  );
};
