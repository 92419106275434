import { FC } from 'react';
import { Stack, Toolbar, Typography } from '@mui/material';

import { TableActionsProps } from 'components/AppTable';
import {
  AddActionButton,
  FilterActionButton,
  SearchActionButton,
} from 'components/AppTable/ToolbarActions';

interface Props extends TableActionsProps {
  tableName: string;
}

export const AppTableHeader: FC<Props> = ({
  tableName,
  add,
  filter,
  search,
}) => {
  return (
    <Toolbar sx={{ px: 2 }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
        {tableName}
      </Typography>
      <Stack direction="row-reverse" spacing={2} alignItems="center">
        {add && <AddActionButton {...add} />}
        {filter && <FilterActionButton {...filter} />}
        {search && <SearchActionButton {...search} />}
      </Stack>
    </Toolbar>
  );
};
