import { QueryOptions } from 'odata-query';

interface SuccessResponse {
  readonly createdDate: string;
  readonly createdBy: User;
  readonly updatedDate: string;
  readonly updatedBy: User;
}

interface ResponseWithEtag<T> {
  readonly etag: string;
  readonly data: T;
}

type ODataRequestParams<T> = Partial<QueryOptions<T> & { count: true }>;

interface ODataResponse<T> {
  readonly '@odata.context': string;
  readonly '@odata.count': number;
  readonly value: T[];
}

interface FilteredResponse<T> {
  readonly count: number;
  readonly values: T[];
}

/**
 * @example {
    type: 'https://tools.ietf.org/html/rfc7231#section-6.5.1',
    title: 'One or more validation errors occurred.',
    status: 400,
    traceId: '00-120aaf2fac463819e1910200437b174e-56cb4dd52f24d210-00',
    errors: { Name: ['The Name field is required.'] },
  }
 */
export interface ErrorResponse {
  readonly type?: string;
  readonly title?: string;
  readonly status?: number;
  readonly traceId?: string;
  readonly errors?: Record<string, string[]>;
}

export enum UserRole {
  User = 'User',
  OrganizationAdmin = 'OrganizationAdmin',
  SuperAdmin = 'SuperAdmin',
}

export type CognitoStatus =
  | 'UNCONFIRMED'
  | 'CONFIRMED'
  | 'ARCHIVED'
  | 'COMPROMISED'
  | 'UNKNOWN'
  | 'RESET_REQUIRED'
  | 'FORCE_CHANGE_PASSWORD';

export interface User {
  readonly id: string;
  readonly email: string;
  readonly type: UserRole;
  readonly cognitoStatus: CognitoStatus;
}

export type UserRequestParams = ODataRequestParams<User>;
export type ODataUsers = ODataResponse<User>;
export type FilteredUsers = FilteredResponse<User>;

export interface UserDetails extends User {
  readonly organizationId: string;
  readonly cognitoId: string;
}

export type UserDetailsWithEtag = ResponseWithEtag<UserDetails>;

export interface CreateUser {
  email: string;
  password: string;
  type: UserRole;
}

export interface UpdateUser {
  email: string;
  type: UserRole;
}

export interface Organization {
  readonly id: string;
  readonly name: string;
}

export type OrganizationRequestParams = ODataRequestParams<Organization>;
export type ODataOrganizations = ODataResponse<Organization>;
export type FilteredOrganizations = FilteredResponse<Organization>;

export type OrganizationWithEtag = ResponseWithEtag<Organization>;

export interface CreateOrganization {
  name: string;
}

export interface UpdateOrganization {
  name: string;
}

export enum ProjectState {
  NotGenerated = 'NotGenerated',
  GeneratedSuccessfully = 'GeneratedSuccessfully',
  Generating = 'Generating',
  GenerationFailed = 'GenerationFailed',
  GenerationCanceled = 'GenerationCanceled',
  GenerationQueued = 'GenerationQueued',
}
export interface Model {
  readonly id: string;
  readonly name: string;
}

export type ModelRequestParams = ODataRequestParams<Model>;
export type ODataModels = ODataResponse<Model>;
export type FilteredModels = FilteredResponse<Model>;

export interface ModelObjectInfo {
  readonly startZ: number;
  readonly endZ: number;
  readonly name: string;
  readonly layerThickness: number;
  readonly objectID: number;
}

export interface ModelFileInfo {
  readonly startZ: number;
  readonly endZ: number;
  readonly layerThickness: number;
  readonly objects: ModelObjectInfo[];
}

export interface ModelDetails extends Model {
  readonly data: string;
  readonly fileInfo: ModelFileInfo;
}

export type ModelDetailsWithEtag = ResponseWithEtag<ModelDetails>;

export interface CreateModel {
  name: string;
  data: string;
}

export interface Project extends SuccessResponse {
  readonly id: string;
  readonly name: string;
  readonly state: ProjectState;
  readonly cadModelName: string;
}

export type ProjectRequestParams = ODataRequestParams<Project>;
export type ODataProjects = ODataResponse<Project>;
export type FilteredProjects = FilteredResponse<Project>;

export interface ProjectDetails extends SuccessResponse {
  readonly id: string;
  readonly name: string;
  readonly state: ProjectState;
  readonly cadModel: ModelDetails;
  readonly notes: string;
  readonly material: string;
  readonly progressPercentage: number;
  readonly totalS3StorageSize: number;
}
export type ProjectDetailsWithEtag = ResponseWithEtag<ProjectDetails>;

export type SelectionType = 'model' | 'objects';

export interface Partition {
  partitionID: string;
  objectID: number;
  startZ: number;
  endZ: number;
}

export interface TransformCoordination {
  x: number;
  y: number;
}

export interface PartitionSettings {
  selection: SelectionType;
  partitions: Partition[];
  selectedPartitions: string[];
  objectMeltOrder: number[];
  meltAllObjectsSimultaneously: boolean;
  transform: TransformCoordination;
}

export interface MeltSetting {
  pointSpreadAlgName: string;
  pointSpreadSettings: string[];
  dwellTimeAlgName: string;
  dwellTimeSettings: string[];
  meshSize: string;
  beamPower: string;
  shift: string;
  spotSize: string;
  seeds: string[];
}

export interface MeltSettingWithPartitionId extends MeltSetting {
  partitionID: string;
}

export interface MeltSettings {
  draft: MeltSettingWithPartitionId[];
  saved: MeltSettingWithPartitionId[];
}

export interface BuildSettings {
  absolutePath: string;
  startHeatTemp: string;
  preheatRepetitions: string;
  postheatRepetitions: string;
  buildPistonDistance: string;
  powderPistonDistance: string;
  recoaterSpeed: string;
}

export interface ProjectSettings {
  partitionSettings: PartitionSettings;
  meltSettings: MeltSettings;
  buildSettings: BuildSettings;
  buildPlateSize: number;
  plateShape: 'circle' | 'square';
}

export interface ProjectSettingsResponse extends SuccessResponse {
  readonly id: string;
  readonly name: string;
  readonly state: ProjectState;
  readonly cadModel: ModelDetails;
  readonly settings: ProjectSettings;
  readonly notes: string;
  readonly material: string;
}

export type ProjectSettingsResponseWithEtag =
  ResponseWithEtag<ProjectSettingsResponse>;

export interface CreateProject {
  name: string;
  notes: string;
  material: string;
  settings: ProjectSettings;
  cadModelId: string;
}

export interface UpdateProject {
  name: string;
  notes: string;
  material: string;
  settings: ProjectSettings;
}

export interface AlgorithmSetting {
  readonly settingsName: string | null;
  readonly defaultValue: string | null;
  readonly unit: string | null;
  readonly settingDescription: string | null;
  readonly type: string | null;
  readonly minValue: string | null;
  readonly maxValue: string | null;
  readonly required: boolean;
}

export interface Algorithm {
  readonly name: string | null;
  readonly description: string | null;
  readonly descriptionID: number;
  readonly algorithmSettings: AlgorithmSetting[] | null;
}

export interface SpotSpreadAlgorithms {
  readonly dwellTimeAlgorithms: Algorithm[];
  readonly pointSpreadAlgorithms: Algorithm[];
}

export interface PartitionEnergyRequest {
  beamPower: number;
  meshSize: number;
  dwellTimeAlgName: string;
  dwellTimeSettings: string[];
  partitionID: string;
}

export interface PartitionEnergyResponse {
  readonly energyPerArea: number;
  readonly partitionID: string;
}

export interface PartitionMeltSetting {
  pointSpreadAlgName: string;
  pointSpreadSettings: string[];
  dwellTimeAlgName: string;
  dwellTimeSettings: string[];
  meshSize: number;
  beamPower: number;
  shift: number;
  spotSize: number;
  objectID: number;
  startZ: number;
  endZ: number;
  seeds: number[];
}

export interface MeltSettingPreset
  extends Readonly<MeltSetting>,
    SuccessResponse {
  readonly id: string;
  readonly name: string;
  readonly material: string;
}

export type MeltSettingPresetWithEtag = ResponseWithEtag<MeltSettingPreset>;

export type MeltSettingPresetRequestParams =
  ODataRequestParams<MeltSettingPreset>;
export type ODataMeltSettingPresets = ODataResponse<MeltSettingPreset>;
export type FilteredMeltSettingPresets = FilteredResponse<MeltSettingPreset>;

export interface CreateMeltSettingPreset extends MeltSetting {
  name: string;
  material: string;
}

export interface UpdateMeltSettingPreset extends MeltSetting {
  name: string;
  material: string;
}

export interface LayerInfo {
  energyOfLayer: number;
  timeOfLayer: number;
  layerZTop: number;
  timeAccumulated: number;
}

export interface GenerateState {
  projectState: ProjectState;
  layers: LayerInfo[];
  totalNumberOfLayers: number;
  progressPercentage: number;
}
