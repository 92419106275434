type KnownExtensions = '3mf';
type MIMETypes = Record<KnownExtensions, string>;
const mimeTypes: MIMETypes = {
  '3mf': 'data:application/octet-stream',
};

/**
 * Convert base64 to Blob
 * @param {string} data
 * @param {string} [mediaType='']
 * @param {number} [sliceSize=512]
 * @returns {Blob}
 */
export const base64TOBlob = (
  data: string,
  mediaType = '',
  sliceSize = 512,
): Blob => {
  const byteCharacters: string = atob(data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers: number[] = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: mediaType });
};

/**
 * Convert base64 to dataUrl
 * @param {string} data
 * @param {KnownExtensions} extension
 * @returns {string}
 */
export const base64ToDataUrl = (
  data: string,
  extension: KnownExtensions,
): string => {
  return `${mimeTypes[extension]};base64,${data}`;
};

/**
 * Convert dataUrl to base64
 * @param {string} data
 * @param {KnownExtensions} extension
 * @returns {string}
 */
export const dataUrlToBase64 = (
  data: string,
  extension: KnownExtensions,
): string => {
  if (!data.includes(mimeTypes[extension]))
    throw new Error('Incorrect mediaType.');
  return data.replace(`${mimeTypes[extension]};base64,`, '');
};
