/**
 * Validates if the password has all the requirements
 * @description WS Cognito password requirement:
    - 8-character minimum length
    - Contains at least 1 number
    - Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + = )
    - Contains at least 1 lowercase letter
    - Contains at least 1 uppercase letter
 * @param {string} password 
 * @returns {boolean}
 */
export const validateNewPassword = (password: string): boolean => {
  return !!password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
  );
};
