import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  FolderOpenRounded as LoadIcon,
  SaveRounded as SaveIcon,
  HistoryRounded as DiscardIcon,
} from '@mui/icons-material';

import ProjectMenuPanel from 'components/ProjectMenuPanel';
import AppMeltSettingsForm from 'components/AppMeltSettingsForm';
import AppLoadPresetDialog from 'components/AppLoadPresetDialog';
import { CreatePresetDialog } from 'containers/Presets';
import { AppButtonForm } from 'components/AppFormControl';
import { useKeyboardShortcut, useSnackbar } from 'hooks';
import { MeltSetting, MeltSettingWithPartitionId } from 'api';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  setDraft,
  saveDraft,
  discardDraft,
  selectDraftValues,
  selectSavedValues,
  selectPartitionMeltSettings,
  initialMeltSetting,
} from 'slices/meltSettingsSlice';
import { selectSelectedPartitions } from 'slices/partitionSettingsSlice';
import { AppConfirmationDialog } from 'components/AppDialog';

interface Props {
  userHasWritePermission: boolean;
  generateHasInitiated: boolean;
}

export const MeltPanel: FC<Props> = ({
  userHasWritePermission,
  generateHasInitiated,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loadPresetDialog, setLoadPresetDialog] = useState(false);
  const [savePresetDialog, setSavePresetDialog] = useState(false);
  const [discardDraftDialog, setDiscardDraftDialog] = useState(false);
  const dispatch = useDispatch();
  const selectedPartitions = useSelector(selectSelectedPartitions);
  const draftValues = useSelector((state) =>
    selectDraftValues(state, selectedPartitions),
  );
  const savedValues = useSelector((state) =>
    selectSavedValues(state, selectedPartitions),
  );
  const partitionValues = useSelector((state) =>
    selectPartitionMeltSettings(state, selectedPartitions[0]),
  );

  const currentValues = useMemo<(MeltSettingWithPartitionId | undefined)[]>(
    () =>
      selectedPartitions.map(
        (id) =>
          draftValues.find(({ partitionID }) => id === partitionID) ??
          savedValues.find(({ partitionID }) => id === partitionID),
      ),
    [draftValues, savedValues, selectedPartitions],
  );

  const values = useMemo<MeltSetting>(() => {
    if (partitionValues.draft) return partitionValues.draft;
    if (partitionValues.saved) return partitionValues.saved;
    return initialMeltSetting;
  }, [partitionValues]);

  const noPartitionSelected = useMemo<boolean>(
    () => selectedPartitions.length < 1,
    [selectedPartitions.length],
  );

  const disabled = useMemo<boolean>(
    () =>
      noPartitionSelected || !userHasWritePermission || generateHasInitiated,
    [noPartitionSelected, userHasWritePermission, generateHasInitiated],
  );

  const saveDraftDisabled = useMemo<boolean>(
    () =>
      selectedPartitions.length < 1 ||
      draftValues.length !== selectedPartitions.length ||
      !draftValues.every(
        (draftValue) =>
          draftValue.pointSpreadAlgName.length &&
          draftValue.dwellTimeAlgName.length,
      ),
    [draftValues, selectedPartitions.length],
  );

  const discardDraftDisabled = useMemo<boolean>(
    () =>
      selectedPartitions.length < 1 ||
      draftValues.length !== selectedPartitions.length,
    [draftValues, selectedPartitions.length],
  );

  const isVarying = useCallback<
    (key: keyof MeltSetting, positionInArray?: number) => boolean
  >(
    (key, position) => {
      const values = currentValues
        .filter(
          (value, index) =>
            currentValues.findIndex((v) => v === value) === index,
        )
        .map((value) => (value ? value[key] : undefined));
      return !values.every((value) => {
        const first = values.at(0);
        return typeof first === 'object' && typeof value === 'object'
          ? position
            ? first.at(position) === value.at(position)
            : value.every((v, i) => first[i] === v)
          : first === value;
      });
    },
    [currentValues],
  );

  const handleUpdateDraft = useCallback<(settings: MeltSetting) => void>(
    (settings) => {
      dispatch(
        setDraft({
          partitions: selectedPartitions,
          settings,
        }),
      );
    },
    [dispatch, selectedPartitions],
  );

  const handleSaveDraft = useCallback<() => void>(() => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (saveDraftDisabled) {
      enqueueSnackbar({
        key: 'save_melt_settings_disabled',
        message: t(
          selectedPartitions.length < 1
            ? 'no_partition_selected'
            : 'apply_melt_settings_failed',
        ),
        variant: 'info',
      });
    } else {
      dispatch(saveDraft(selectedPartitions));
    }
  }, [
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    saveDraftDisabled,
    selectedPartitions,
    t,
    userHasWritePermission,
  ]);

  const handleDiscardDraft = useCallback<() => void>(() => {
    if (!userHasWritePermission) {
      enqueueSnackbar({
        key: 'no_write_permission',
        message: t('no_write_permission'),
        variant: 'info',
      });
    } else if (generateHasInitiated) {
      enqueueSnackbar({
        key: 'project_settings_blocked',
        message: t('project_settings_blocked'),
        variant: 'info',
      });
    } else if (discardDraftDisabled) {
      enqueueSnackbar({
        key: 'discard_melt_settings_disabled',
        message: 'discard_melt_settings_disabled',
        variant: 'info',
      });
    } else {
      dispatch(discardDraft(selectedPartitions));
    }
  }, [
    discardDraftDisabled,
    dispatch,
    enqueueSnackbar,
    generateHasInitiated,
    selectedPartitions,
    t,
    userHasWritePermission,
  ]);

  useKeyboardShortcut({ key: 'm', ctrl: true }, handleSaveDraft);
  useKeyboardShortcut({ key: 'z', ctrl: true }, () =>
    setDiscardDraftDialog(true),
  );

  return (
    <>
      <ProjectMenuPanel
        title={t('melt_settings')}
        action={{
          primary: {
            title: t('apply_settings'),
            desc: t(
              saveDraftDisabled
                ? 'apply_melt_settings_disabled'
                : 'apply_melt_settings_helper',
            ),
            icon: <SaveIcon />,
            onClick: handleSaveDraft,
            disabled:
              !userHasWritePermission ||
              generateHasInitiated ||
              saveDraftDisabled,
          },
          secondary: {
            title: t('discard_changes'),
            desc: t(
              discardDraftDisabled
                ? 'discard_changes_disabled'
                : 'discard_changes_helper',
            ),
            icon: <DiscardIcon />,
            onClick: () => setDiscardDraftDialog(true),
            disabled:
              !userHasWritePermission ||
              generateHasInitiated ||
              discardDraftDisabled,
          },
        }}
        options={
          <Stack spacing={2}>
            <AppButtonForm
              label={t('load_preset')}
              helperText={t('load_preset_helper')}
              componentProps={{
                onClick: () => setLoadPresetDialog(true),
                startIcon: <LoadIcon />,
                disabled,
              }}
            />
            <AppButtonForm
              label={t('save_preset')}
              helperText={t('save_preset_helper')}
              componentProps={{
                onClick: () => setSavePresetDialog(true),
                startIcon: <SaveIcon />,
              }}
            />
          </Stack>
        }
        rootProps={{
          onClick: () => {
            if (!userHasWritePermission) {
              enqueueSnackbar({
                key: 'no_write_permission',
                message: t('no_write_permission'),
                variant: 'info',
              });
            } else if (generateHasInitiated) {
              enqueueSnackbar({
                key: 'project_settings_blocked',
                message: t('project_settings_blocked'),
                variant: 'info',
              });
            } else if (noPartitionSelected) {
              enqueueSnackbar({
                key: 'no_partition_selected',
                message: t('no_partition_selected'),
                variant: 'info',
              });
            }
          },
        }}
      >
        <AppMeltSettingsForm
          values={values}
          onChangeValues={handleUpdateDraft}
          disabled={disabled}
          isVarying={isVarying}
        />
      </ProjectMenuPanel>
      <AppLoadPresetDialog
        open={loadPresetDialog}
        onClose={() => setLoadPresetDialog(false)}
        onSubmit={handleUpdateDraft}
      />
      <CreatePresetDialog
        open={savePresetDialog}
        onClose={() => setSavePresetDialog(false)}
        initialSetting={values}
      />
      <AppConfirmationDialog
        open={discardDraftDialog}
        onClose={() => setDiscardDraftDialog(false)}
        onConfirm={() => {
          handleDiscardDraft();
          setDiscardDraftDialog(false);
        }}
        title={t('discard_changes')}
        text={t('discard_changes_confirmation', {
          count: selectedPartitions.length,
        })}
      />
    </>
  );
};
