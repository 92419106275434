import {
  FC,
  useMemo,
  useState,
  createRef,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button } from '@mui/material';
import { CheckRounded as ConfirmIcon } from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import AppMeltSettingsForm from 'components/AppMeltSettingsForm';
import ProjectMenuPanelGroup from 'components/ProjectMenuPanelGroup';
import { AppTextForm } from 'components/AppFormControl';
import {
  createMeltSettingPreset,
  CreateMeltSettingPreset,
  ErrorResponse,
  MeltSetting,
  MeltSettingPresetWithEtag,
  ProjectSettingsResponseWithEtag,
} from 'api';
import { useSnackbar } from 'hooks';
// Redux
import { initialMeltSetting } from 'slices/meltSettingsSlice';

interface Props {
  open: boolean;
  onClose: () => void;
  initialSetting?: MeltSetting;
}

export const CreatePresetDialog: FC<Props> = ({
  open,
  onClose,
  initialSetting = initialMeltSetting,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<'projectId'>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState<string>('');
  const [material, setMaterial] = useState<string>('');
  const [values, setValues] = useState<MeltSetting>(initialSetting);

  const [validationError, setValidationError] = useState<
    (keyof CreateMeltSettingPreset)[]
  >([]);
  const refs = useMemo<
    Record<keyof CreateMeltSettingPreset, RefObject<HTMLInputElement>>
  >(
    () => ({
      name: createRef<HTMLInputElement>(),
      material: createRef<HTMLInputElement>(),
      pointSpreadAlgName: createRef<HTMLInputElement>(),
      pointSpreadSettings: createRef<HTMLInputElement>(),
      dwellTimeAlgName: createRef<HTMLInputElement>(),
      dwellTimeSettings: createRef<HTMLInputElement>(),
      meshSize: createRef<HTMLInputElement>(),
      beamPower: createRef<HTMLInputElement>(),
      shift: createRef<HTMLInputElement>(),
      spotSize: createRef<HTMLInputElement>(),
      seeds: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const { data: project } = useQuery<ProjectSettingsResponseWithEtag>(
    ['project-settings', { projectId }],
    { enabled: !!projectId },
  );

  useEffect(() => {
    if (project) setMaterial(project.data.material);
  }, [project]);

  const handleResetForm = useCallback<() => void>(() => {
    setName('');
    setMaterial('');
    setValues(initialSetting);
  }, [initialSetting]);

  const handleClose = useCallback<() => void>(() => {
    onClose();
    setValidationError([]);
    handleResetForm();
  }, [handleResetForm, onClose]);

  const { mutate: onCreatePreset, isLoading: isCreating } = useMutation<
    MeltSettingPresetWithEtag,
    AxiosError<ErrorResponse>
  >(() => createMeltSettingPreset({ name, material, ...values }), {
    onSuccess: ({ data }) => {
      enqueueSnackbar({
        key: `create_preset_success_${Date.now()}`,
        message: t('create_preset_success', { name: data.name }),
        variant: 'success',
      });
      queryClient.invalidateQueries(['melt-setting-presets']);
      handleClose();
    },
    onError: ({ response }) => {
      enqueueSnackbar({
        key: `create_preset_fail_${Date.now()}`,
        message: t('create_preset_fail'),
        variant: 'error',
        persist: true,
      });
      if (response?.status === 400) {
        if (response.data.errors) {
          const errorKeys = Object.keys(response.data.errors)
            .map<keyof CreateMeltSettingPreset | undefined>((key) => {
              if (key === 'Name') return 'name';
              if (key === 'Material') return 'material';
              if (key === 'pointSpreadAlgName') return 'pointSpreadAlgName';
              if (key === 'pointSpreadSettings') return 'pointSpreadSettings';
              if (key === 'dwellTimeAlgName') return 'dwellTimeAlgName';
              if (key === 'dwellTimeSettings') return 'dwellTimeSettings';
              if (key === 'meshSize') return 'meshSize';
              if (key === 'beamPower') return 'beamPower';
              if (key === 'shift') return 'shift';
              if (key === 'spotSize') return 'spotSize';
              if (key === 'seeds') return 'seeds';
              return undefined;
            })
            .filter((key): key is keyof CreateMeltSettingPreset => !!key);
          setValidationError(errorKeys);
          refs[errorKeys[0]]?.current?.focus();
        }
      }
    },
  });

  useEffect(() => {
    setValidationError([]);
  }, [name, material, values]);

  useEffect(() => {
    setValues(initialSetting);
  }, [initialSetting]);

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title={t('new_preset')}
      actions={
        <Button
          onClick={() => onCreatePreset()}
          variant="contained"
          disabled={isCreating}
          startIcon={<ConfirmIcon />}
          fullWidth
        >
          {t('create')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <ProjectMenuPanelGroup title={t('info')}>
          <Stack spacing={2}>
            <AppTextForm
              ref={refs.name}
              label={t('name')}
              value={name}
              onChange={setName}
              helperText={t('preset_info_name_helper')}
              error={validationError.includes('name')}
              errorText={t('validations:create_preset.name')}
            />
            <AppTextForm
              ref={refs.material}
              label={t('material')}
              value={material}
              onChange={setMaterial}
              helperText={t('preset_info_material_helper')}
              error={validationError.includes('material')}
              errorText={t('validations:create_preset.material')}
            />
          </Stack>
        </ProjectMenuPanelGroup>
        <AppMeltSettingsForm
          values={values}
          onChangeValues={setValues}
          refs={refs}
          errors={validationError.filter(
            (key): key is keyof MeltSetting =>
              key !== 'name' && key !== 'material',
          )}
        />
      </Stack>
    </AppDialog>
  );
};
