import { FC, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Box, Paper, Typography, Skeleton } from '@mui/material';

import ProjectMenuPanel from 'components/ProjectMenuPanel';
import { useSnackbar } from 'hooks';
import {
  PartitionEnergyRequest,
  getEnergiesPerAreas,
  PartitionEnergyResponse,
  ErrorResponse,
  ProjectSettingsResponseWithEtag,
  Partition,
} from 'api';
// Redux
import { useSelector } from 'store';
import {
  selectSelectedPartitions,
  selectPartitions,
  selectSelection,
} from 'slices/partitionSettingsSlice';
import { selectSavedValues } from 'slices/meltSettingsSlice';

export const InfoPanel: FC = () => {
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const { enqueueSnackbar } = useSnackbar();
  const savedValues = useSelector(selectSavedValues);
  const selectedPartitions = useSelector(selectSelectedPartitions);
  const partitions = useSelector(selectPartitions);
  const selection = useSelector(selectSelection);

  const { data: projectSettings } = useQuery<ProjectSettingsResponseWithEtag>([
    'project-settings',
    { projectId },
  ]);

  const {
    mutate: onGetEnergies,
    data: energies = [],
    isLoading: isEnergiesLoading,
  } = useMutation<
    PartitionEnergyResponse[],
    AxiosError<ErrorResponse>,
    PartitionEnergyRequest[]
  >(['energies-per-areas', { projectId }], getEnergiesPerAreas, {
    onError: () => {
      enqueueSnackbar({
        key: `energy_per_area_fail_${Date.now()}`,
        message: t('energy_per_area_fail'),
        variant: 'error',
        persist: true,
      });
    },
  });

  const energyOfSelected = useMemo<
    (Partition & { energyPerArea: number | string })[]
  >(
    () =>
      partitions
        .filter(({ partitionID: id }) => selectedPartitions.includes(id))
        .map((partition) => ({
          ...partition,
          energyPerArea:
            energies.find(
              ({ partitionID }) => partitionID === partition.partitionID,
            )?.energyPerArea ?? '-',
        })),
    [energies, partitions, selectedPartitions],
  );

  const getPartitionName = useCallback<(partitionID: string) => string>(
    (partitionID) => {
      const partition = partitions.find(
        ({ partitionID: id }) => partitionID === id,
      );
      const objectName: string | undefined =
        projectSettings?.data.cadModel.fileInfo.objects.find(
          ({ objectID }) => partition?.objectID === objectID,
        )?.name;
      if (selection === 'model') {
        return t('partition_{{id}}', {
          id: partitions.findIndex((p) => partition === p) + 1,
        });
      }
      if (selection === 'objects') {
        return `${
          objectName ??
          t('object_{{id}}', {
            id: partition?.objectID as number,
          })
        } - ${t('partition_{{id}}', {
          id:
            partitions
              .filter(({ objectID }) => partition?.objectID === objectID)
              .findIndex((p) => partition === p) + 1,
        })}`;
      }
      return '';
    },
    [partitions, projectSettings?.data.cadModel.fileInfo.objects, selection, t],
  );

  useEffect(() => {
    if (savedValues.length) {
      onGetEnergies(
        savedValues
          .map((value) => ({
            beamPower: parseFloat(value.beamPower),
            meshSize: parseFloat(value.meshSize),
            dwellTimeAlgName: value.dwellTimeAlgName,
            dwellTimeSettings: value.dwellTimeSettings,
            partitionID: value.partitionID,
          }))
          .filter((value): value is PartitionEnergyRequest => {
            if (isNaN(value.beamPower)) return false;
            if (isNaN(value.meshSize)) return false;
            return true;
          }),
      );
    }
  }, [savedValues, onGetEnergies]);

  return (
    <ProjectMenuPanel flexShrink={2} title={t('info')}>
      <Paper
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(2)})`,
          pl: 2,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">{t('energy_per_area')}</Typography>
            <Typography variant="h6">
              {t('unit:joule_per_square_millimeter')}
            </Typography>
          </Box>
          {energyOfSelected.map(({ partitionID, energyPerArea }) =>
            isEnergiesLoading ? (
              <Skeleton key={partitionID} variant="text" />
            ) : (
              <Box
                key={partitionID}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography>{getPartitionName(partitionID)}</Typography>
                <Typography>
                  {typeof energyPerArea === 'number'
                    ? Math.floor(energyPerArea * 10e2) / 10e2
                    : energyPerArea}
                </Typography>
              </Box>
            ),
          )}
        </>
      </Paper>
    </ProjectMenuPanel>
  );
};
