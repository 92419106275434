import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  FormControl,
  FormLabel,
  Tooltip,
  LinearProgress,
  LinearProgressProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';

export interface Props {
  label: string;
  helperText?: string;
  hideFormLabel?: boolean;
  varies?: boolean;
  loading?: boolean;
  loadingText?: string;
  error?: boolean;
  errorText?: string;
  linearProgressProps?: LinearProgressProps;
}

export const AppFormControl: FC<Props> = ({
  label,
  helperText = '',
  hideFormLabel,
  children,
  varies,
  loading,
  loadingText,
  error,
  errorText,
  linearProgressProps,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallScreenSize = useMediaQuery(theme.breakpoints.down('md'));

  const tooltip = useMemo<string>(() => {
    if (loading && loadingText) return loadingText;
    return helperText;
  }, [helperText, loading, loadingText]);

  return (
    <Tooltip
      title={tooltip}
      placement={smallScreenSize ? 'top' : 'right'}
      arrow
    >
      <FormControl variant="standard" error={error} fullWidth>
        {!hideFormLabel && (
          <FormLabel id={`${label}-label`} htmlFor={`${label}-form`}>
            {label}
            {varies && (
              <Typography
                sx={{
                  color: 'warning.main',
                  fontWeight: 100,
                  display: 'inline',
                }}
              >
                {` (${t('varies')})`}
              </Typography>
            )}
          </FormLabel>
        )}
        {!loading ? children : <LinearProgress {...linearProgressProps} />}
        {error && errorText && (
          <Typography variant="body2" color="error">
            {errorText}
          </Typography>
        )}
      </FormControl>
    </Tooltip>
  );
};
