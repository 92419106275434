import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Autocomplete,
  TextField,
  TableRow,
  TableCell,
  Stack,
  Divider,
} from '@mui/material';

import ProjectRemovePartitionForm from 'components/ProjectRemovePartitionForm';
import ProjectPartitionStatus from 'components/ProjectPartitionStatus';
import {
  addWithDecimals,
  subtractWithDecimals,
  countDecimals,
  getSelectionMode,
} from 'helpers';
import { ModelFileInfo, ModelObjectInfo, Partition } from 'api';
// Redux
import { useSelector } from 'store';
import { SelectPartitionPayload } from 'slices/partitionSettingsSlice';
import { selectPartitionMeltSettings } from 'slices/meltSettingsSlice';

const ROW_HEIGHT = 52;

interface Props {
  index: number;
  parent: ModelFileInfo | ModelObjectInfo;
  partitions: Partition[];
  selected?: boolean;
  extraIndent?: boolean;
  onRemovePartition?: (id: string, effect: 'before' | 'after') => void;
  onUpdatePartition?: (id: string, breakpoint: [number, number]) => void;
  onSelectPartition?: (payload: SelectPartitionPayload) => void;
  onChangeMeltSettingsPreset?: () => void;
}

export const TreeItemPartition: FC<Props> = ({
  index,
  parent,
  partitions,
  selected,
  extraIndent,
  onRemovePartition,
  onUpdatePartition,
  onSelectPartition,
  onChangeMeltSettingsPreset,
}) => {
  const { t } = useTranslation();

  const partition = useMemo<Partition>(
    () => partitions[index],
    [partitions, index],
  );

  const partitionValues = useSelector((state) =>
    selectPartitionMeltSettings(state, partition.partitionID),
  );

  const numberOfDecimals = useMemo<number>(() => {
    const { startZ, endZ, layerThickness } = parent;
    return Math.max(
      ...[startZ, endZ, layerThickness].map((n) => countDecimals(n)),
    );
  }, [parent]);

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = parent.startZ;
    while (i <= parent.endZ) {
      result.push(i);
      i = addWithDecimals(numberOfDecimals, i, parent.layerThickness);
    }
    return result;
  }, [parent, numberOfDecimals]);

  const handleRemovePartition = useCallback<
    (effect: 'before' | 'after') => void
  >(
    (effect) => {
      if (onRemovePartition) {
        onRemovePartition(partition.partitionID, effect);
      }
    },
    [onRemovePartition, partition],
  );

  const handleChangeValue = useCallback<
    (event: SyntheticEvent, value: number) => void
  >(
    (_event, value) => {
      if (onUpdatePartition) {
        onUpdatePartition(partition.partitionID, [
          subtractWithDecimals(numberOfDecimals, value, parent.layerThickness),
          value,
        ]);
      }
    },
    [onUpdatePartition, partition, parent, numberOfDecimals],
  );

  const handleSelectPartition = useCallback<
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  >(
    (e) => {
      e.stopPropagation();
      if (onSelectPartition) {
        onSelectPartition({
          target: partition.partitionID,
          mode: getSelectionMode(e),
        });
      }
    },
    [onSelectPartition, partition],
  );

  return (
    <TableRow
      hover={!!onSelectPartition}
      selected={selected}
      sx={{
        cursor: onSelectPartition ? 'pointer' : 'default',
        border: 'none',
        height: ROW_HEIGHT,
      }}
      onClick={handleSelectPartition}
    >
      <TableCell colSpan={2} sx={{ py: 0 }}>
        <Stack direction="row" alignItems="center" spacing={2} ml={2}>
          {extraIndent && (
            <Divider
              orientation="vertical"
              sx={{ height: ROW_HEIGHT, mr: 2 }}
            />
          )}
          <Divider orientation="vertical" sx={{ height: ROW_HEIGHT }} />
          <Typography variant="body2">
            {t('partition_{{id}}', { id: index + 1 })}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {onUpdatePartition ? (
          <Autocomplete
            value={partition.startZ}
            onChange={handleChangeValue}
            options={options}
            renderInput={(params) => <TextField {...params} />}
            size="small"
            getOptionDisabled={(option) =>
              option > partition.endZ || option <= partitions[index - 1].startZ
            }
            getOptionLabel={(option) =>
              String(
                subtractWithDecimals(
                  numberOfDecimals,
                  option,
                  parent.layerThickness,
                ),
              )
            }
            disabled={index === 0}
            disableClearable
            fullWidth
          />
        ) : (
          <Typography variant="body2">
            {subtractWithDecimals(
              numberOfDecimals,
              partition.startZ,
              parent.layerThickness,
            )}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2">{partition.endZ}</Typography>
      </TableCell>
      {onChangeMeltSettingsPreset && <TableCell />}
      <TableCell align="right">
        {onRemovePartition ? (
          <ProjectRemovePartitionForm
            iconButtonProps={{
              disabled: partitions.length < 2,
            }}
            onRemovePartition={handleRemovePartition}
            disabled={{
              before: partition.objectID !== partitions.at(index - 1)?.objectID,
              after: partition.objectID !== partitions.at(index - 1)?.objectID,
            }}
          />
        ) : (
          <ProjectPartitionStatus
            sx={{
              ml: partition.objectID === -1 ? -2 : -4,
            }}
            {...partitionValues}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
