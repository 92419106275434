import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  Stack,
  useMediaQuery,
  Theme,
  Button,
  Typography,
} from '@mui/material';

import { ReactComponent as FreemeltPixelmeltLogo } from 'assets/logo/freemelt-pixelmelt.svg';
import { AppTextForm } from 'components/AppFormControl';
import { useAuthentication, useSearchParams, useSnackbar } from 'hooks';
import { validateEmail, validateNewPassword } from 'helpers';

type ResetPasswordKeys = 'verificationCode' | 'newPassword' | 'confirmPassword';

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'), {
    noSsr: true,
  });
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { forgotPassword, resetPassword } = useAuthentication();
  const [{ email: targetEmail }, { updateSearchParam }] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [validationError, setValidationError] = useState<ResetPasswordKeys[]>(
    [],
  );
  const refs = useMemo<Record<ResetPasswordKeys, RefObject<HTMLInputElement>>>(
    () => ({
      verificationCode: createRef<HTMLInputElement>(),
      newPassword: createRef<HTMLInputElement>(),
      confirmPassword: createRef<HTMLInputElement>(),
    }),
    [],
  );

  const returnToLoginPage = useCallback<() => void>(
    () => navigate(`/?email=${email}`),
    [email, navigate],
  );

  const handleForgotPassword = useCallback<() => void>(() => {
    if (validateEmail(email)) {
      setIsLoading(true);
      forgotPassword(email)
        .then(() => {
          enqueueSnackbar({
            key: 'forgot_password_success',
            message: t('forgot_password_success', { email }),
            variant: 'success',
            persist: true,
          });
          setIsLoading(false);
          updateSearchParam(['email', email]);
        })
        .catch(() => {
          closeSnackbar('forgot_password_success');
          enqueueSnackbar({
            key: 'forgot_password_fail',
            message: t('forgot_password_fail', { email }),
            variant: 'error',
            persist: true,
          });
          setIsLoading(false);
        });
    }
  }, [
    closeSnackbar,
    email,
    enqueueSnackbar,
    forgotPassword,
    t,
    updateSearchParam,
  ]);

  const handleResetPassword = useCallback<() => void>(() => {
    if (!verificationCode) {
      setValidationError(['verificationCode']);
      refs.verificationCode.current?.focus();
    } else if (!validateNewPassword(newPassword)) {
      setValidationError(['newPassword']);
      refs.newPassword.current?.focus();
    } else if (newPassword !== confirmPassword) {
      setValidationError(['confirmPassword']);
      refs.confirmPassword.current?.focus();
    } else if (targetEmail) {
      setIsLoading(true);
      resetPassword(targetEmail, verificationCode, newPassword)
        .then(() => {
          closeSnackbar('reset_password_fail');
          closeSnackbar('forgot_password_success');
          enqueueSnackbar({
            key: 'reset_password_success',
            message: t('reset_password_success'),
            variant: 'success',
            persist: true,
          });
          setIsLoading(false);
          returnToLoginPage();
        })
        .catch(() => {
          enqueueSnackbar({
            key: 'reset_password_fail',
            message: t('reset_password_fail', { email }),
            variant: 'error',
            persist: true,
          });
          setIsLoading(false);
        });
    }
  }, [
    closeSnackbar,
    confirmPassword,
    email,
    enqueueSnackbar,
    newPassword,
    refs,
    resetPassword,
    returnToLoginPage,
    t,
    targetEmail,
    verificationCode,
  ]);

  return (
    <Dialog open maxWidth="sm" fullWidth fullScreen={xs}>
      <Stack
        height={1}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        py={10}
      >
        <Stack width={0.6} spacing={2}>
          <Typography variant="h5">{t('forgot_password')}</Typography>
          {!targetEmail ? (
            <>
              <Typography sx={{ pb: 4 }}>
                {t('forgot_password_dialog')}
              </Typography>
              <AppTextForm
                label={t('email')}
                value={email}
                onChange={setEmail}
                componentProps={{ sx: { mb: 4 }, type: 'email' }}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={returnToLoginPage}
                  variant="outlined"
                  color="inherit"
                  sx={{ flex: 1 }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={handleForgotPassword}
                  variant="contained"
                  disabled={isLoading || !validateEmail(email)}
                  sx={{ flex: 2 }}
                >
                  {t('send_code')}
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Typography sx={{ pb: 4 }}>
                {t('reset_password_dialog', { email: targetEmail })}
              </Typography>
              <AppTextForm
                label={t('verification_code')}
                helperText={t('verification_code_helper')}
                value={verificationCode}
                onChange={setVerificationCode}
                componentProps={{ inputRef: refs.verificationCode }}
                error={validationError.includes('verificationCode')}
                errorText={t('validations:forgot_password.verificationCode')}
              />
              <AppTextForm
                label={t('new_password')}
                helperText={t('new_password_helper')}
                value={newPassword}
                onChange={setNewPassword}
                componentProps={{
                  type: 'password',
                  autoComplete: 'new-password',
                  inputRef: refs.newPassword,
                }}
                error={validationError.includes('newPassword')}
                errorText={t('validations:forgot_password.password')}
              />
              <AppTextForm
                label={t('confirm_password')}
                helperText={t('confirm_password_helper')}
                value={confirmPassword}
                onChange={setConfirmPassword}
                componentProps={{
                  sx: { mb: 4 },
                  type: 'password',
                  autoComplete: 'new-password',
                  inputRef: refs.confirmPassword,
                }}
                error={validationError.includes('confirmPassword')}
                errorText={t('validations:forgot_password.confirmPassword')}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={returnToLoginPage}
                  variant="outlined"
                  color="inherit"
                  sx={{ flex: 1 }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  onClick={handleResetPassword}
                  variant="contained"
                  disabled={isLoading}
                  sx={{ flex: 2 }}
                >
                  {t('set_new_password')}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
        <FreemeltPixelmeltLogo style={{ width: '50%', alignSelf: 'center' }} />
      </Stack>
    </Dialog>
  );
};
