import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { BuildSettings } from 'api';
import { ValidateNumberOptions } from 'helpers';

export const initialState: BuildSettings = {
  absolutePath: '',
  startHeatTemp: '900',
  preheatRepetitions: '10',
  postheatRepetitions: '10',
  buildPistonDistance: '0',
  powderPistonDistance: '0',
  recoaterSpeed: '200',
};

export const buildSettingsValidations: Partial<
  Record<keyof BuildSettings, ValidateNumberOptions>
> = {
  startHeatTemp: { min: 0, max: 2000 },
  preheatRepetitions: { min: 0, noDecimal: true },
  postheatRepetitions: { min: 0, noDecimal: true },
  buildPistonDistance: { min: 0 },
  powderPistonDistance: { min: 0 },
  recoaterSpeed: { min: 14 },
};

const buildSettingsSlice = createSlice({
  name: 'buildSettings',
  initialState,
  reducers: {
    loadBuildSettings: (state, { payload }: { payload: BuildSettings }) => {
      state.absolutePath = payload.absolutePath;
      state.startHeatTemp = payload.startHeatTemp;
      state.preheatRepetitions = payload.preheatRepetitions;
      state.postheatRepetitions = payload.postheatRepetitions;
      state.buildPistonDistance = payload.buildPistonDistance;
      state.powderPistonDistance = payload.powderPistonDistance;
      state.recoaterSpeed = payload.recoaterSpeed;
    },
    resetBuildSettings: (state) => {
      state.absolutePath = initialState.absolutePath;
      state.startHeatTemp = initialState.startHeatTemp;
      state.preheatRepetitions = initialState.preheatRepetitions;
      state.postheatRepetitions = initialState.postheatRepetitions;
      state.buildPistonDistance = initialState.buildPistonDistance;
      state.powderPistonDistance = initialState.powderPistonDistance;
      state.recoaterSpeed = initialState.recoaterSpeed;
    },
    setAbsolutePath: (state, { payload }: { payload: string }) => {
      state.absolutePath = payload;
    },
    setStartHeatTemp: (state, { payload }: { payload: string }) => {
      state.startHeatTemp = payload;
    },
    setPreheatRepetitions: (state, { payload }: { payload: string }) => {
      state.preheatRepetitions = payload;
    },
    setPostheatRepetitions: (state, { payload }: { payload: string }) => {
      state.postheatRepetitions = payload;
    },
    setBuildPistonDistance: (state, { payload }: { payload: string }) => {
      state.buildPistonDistance = payload;
    },
    setPowderPistonDistance: (state, { payload }: { payload: string }) => {
      state.powderPistonDistance = payload;
    },
    setRecoaterSpeed: (state, { payload }: { payload: string }) => {
      state.recoaterSpeed = payload;
    },
  },
});

export const {
  loadBuildSettings,
  resetBuildSettings,
  setStartHeatTemp,
  setPreheatRepetitions,
  setPostheatRepetitions,
  setAbsolutePath,
  setBuildPistonDistance,
  setPowderPistonDistance,
  setRecoaterSpeed,
} = buildSettingsSlice.actions;

export const selectBuildSettings = (state: RootState): BuildSettings =>
  state.buildSettings;

export default buildSettingsSlice.reducer;
