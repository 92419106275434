import { FC, ReactNode, ChangeEvent } from 'react';
import {
  Select,
  NativeSelect,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';

type OptionLabel =
  | {
      mobile: string;
      desktop: string | ReactNode;
    }
  | string;

interface Props extends SelectProps {
  value: number | string;
  onChange: (value: unknown) => void;
  options: { value: number | string; label: OptionLabel }[];
}

export const AppSelect: FC<Props> = ({
  value,
  onChange,
  options,
  ...componentProps
}) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return xs ? (
    <NativeSelect
      value={value}
      onChange={(e: ChangeEvent<{ value: unknown }>) => {
        onChange(e.target.value);
      }}
      variant="standard"
    >
      {options.map(({ value: v, label: l }) => (
        <option key={v} value={v}>
          {typeof l === 'string' ? l : l.mobile}
        </option>
      ))}
    </NativeSelect>
  ) : (
    <Select
      value={value}
      onChange={(e: SelectChangeEvent<unknown>) => {
        onChange(e.target.value);
      }}
      variant="standard"
      {...componentProps}
    >
      {options.map(({ value: v, label: l }) => (
        <MenuItem key={v} value={v}>
          {typeof l === 'string' ? l : l.desktop}
        </MenuItem>
      ))}
    </Select>
  );
};
