import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { AppDeletionDialog } from 'components/AppDialog';
import {
  deleteMeltSettingPreset,
  ErrorResponse,
  getMeltSettingPreset,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

interface Props {
  open: boolean;
  presetId: string;
  onClose: () => void;
}

export const DeletePresetDialog: FC<Props> = ({ open, presetId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, { deleteSearchParam }] = useSearchParams();

  const {
    data: details,
    isLoading: isDetailsLoading,
    isRefetching: isDetailsRefetching,
  } = useQuery(
    ['melt-setting-preset', { presetId }],
    () => (presetId ? getMeltSettingPreset(presetId) : undefined),
    {
      onError: () => {
        enqueueSnackbar({
          key: `get_preset_fail_${Date.now()}`,
          message: t('get_preset_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const { mutate: onDeletePreset, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    () => {
      if (!details) throw new Error('preset does not exist');
      return deleteMeltSettingPreset(details.data.id, details.etag);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_preset_success_${Date.now()}`,
          message: t('delete_preset_success', { name: details?.data.name }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['melt-setting-presets']);
        queryClient.removeQueries(['melt-setting-preset', { presetId }]);
        deleteSearchParam('rowId');
        onClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'delete_preset_decrepit',
            message: t('delete_preset_decrepit', {
              name: details?.data.name,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient
                    .refetchQueries(['preset', { presetId }])
                    .then(() => {
                      closeSnackbar('delete_preset_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `delete_preset_fail_${Date.now()}`,
            message: t('delete_preset_fail', { name: details?.data.name }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  return (
    <AppDeletionDialog
      open={open}
      onClose={onClose}
      title={t('delete_preset')}
      text={t('delete_preset_confirmation', { name: details?.data.name })}
      onConfirm={onDeletePreset}
      confirmDisabled={isDeleting || isDetailsLoading || isDetailsRefetching}
    />
  );
};
