import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  TreeItemModel,
  TreeItemObject,
  TreeItemPartition,
} from 'components/ProjectTreeView';
import { ModelFileInfo, ModelObjectInfo, Partition, SelectionType } from 'api';
// Redux
import { SelectPartitionPayload } from 'slices/partitionSettingsSlice';

interface Props {
  modelInfo: ModelFileInfo | undefined;
  modelName: string | undefined;
  partitions: Partition[];
  selection: SelectionType;
  selectedPartitions?: string[];
  objectMeltOrder: number[];
  onAddPartition?: (breakpoint: [number, number], objectID?: number) => void;
  onRemovePartition?: (id: string, effect: 'before' | 'after') => void;
  onUpdatePartition?: (id: string, breakpoint: [number, number]) => void;
  onSelectPartition?: (payload: SelectPartitionPayload) => void;
  onChangeObjectOrder?: (id: number, steps: number) => void;
  onChangeMeltSettingsPreset?: () => void;
}

export const ProjectTreeView: FC<Props> = ({
  modelInfo,
  modelName,
  partitions,
  selection,
  selectedPartitions,
  objectMeltOrder,
  onAddPartition,
  onRemovePartition,
  onUpdatePartition,
  onSelectPartition,
  onChangeObjectOrder,
  onChangeMeltSettingsPreset,
}) => {
  const { t } = useTranslation();

  const sortedObjects = useMemo<ModelObjectInfo[] | undefined>(
    () =>
      modelInfo?.objects
        .slice()
        .sort(
          ({ objectID: a }, { objectID: b }) =>
            objectMeltOrder.indexOf(a) - objectMeltOrder.indexOf(b),
        ),
    [modelInfo?.objects, objectMeltOrder],
  );

  if (!modelInfo) return null;

  return (
    <TableContainer sx={{ overflowX: 'hidden' }}>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow sx={{ border: 'none' }}>
            <TableCell colSpan={2}>
              <Typography variant="body2">{t('name')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('start_z')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t('end_z')}</Typography>
            </TableCell>
            {onChangeMeltSettingsPreset && (
              <TableCell>
                <Typography variant="body2">{t('melt_settings')}</Typography>
              </TableCell>
            )}
            <TableCell align="right" sx={{ width: 50 }}>
              {onSelectPartition && (
                <Typography variant="body2">{t('state')}</Typography>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TreeItemModel
            modelName={modelName}
            {...modelInfo}
            partitions={partitions.filter(({ objectID }) => objectID === -1)}
            selected={
              selectedPartitions &&
              partitions.every(({ partitionID }) =>
                selectedPartitions.includes(partitionID),
              )
            }
            onAddPartition={selection === 'model' ? onAddPartition : undefined}
            onSelectPartition={onSelectPartition}
          >
            {selection === 'model' &&
              partitions
                .filter(({ objectID }) => objectID === -1)
                .map((partition, index) => (
                  <TreeItemPartition
                    key={partition.partitionID}
                    index={index}
                    parent={modelInfo}
                    partitions={partitions.filter(
                      ({ objectID }) => objectID === -1,
                    )}
                    selected={
                      selectedPartitions &&
                      selectedPartitions.includes(partition.partitionID)
                    }
                    onRemovePartition={onRemovePartition}
                    onUpdatePartition={onUpdatePartition}
                    onSelectPartition={onSelectPartition}
                  />
                ))}
            {selection === 'objects' &&
              sortedObjects?.map((parent, key) => {
                const filteredPartitions = partitions.filter(
                  (partition) => partition.objectID === parent.objectID,
                );
                return (
                  <TreeItemObject
                    key={key}
                    {...parent}
                    partitions={filteredPartitions}
                    selected={
                      selectedPartitions &&
                      filteredPartitions.every(({ partitionID }) =>
                        selectedPartitions.includes(partitionID),
                      )
                    }
                    objectMeltOrder={objectMeltOrder}
                    onAddPartition={onAddPartition}
                    onSelectPartition={onSelectPartition}
                    onChangeObjectOrder={onChangeObjectOrder}
                  >
                    {partitions
                      .filter(
                        (partition) => partition.objectID === parent.objectID,
                      )
                      .map((partition, index) => (
                        <TreeItemPartition
                          key={partition.partitionID}
                          index={index}
                          parent={parent}
                          partitions={partitions.filter(
                            (partition) =>
                              partition.objectID === parent.objectID,
                          )}
                          selected={
                            selectedPartitions &&
                            selectedPartitions.includes(partition.partitionID)
                          }
                          onRemovePartition={onRemovePartition}
                          onUpdatePartition={onUpdatePartition}
                          onSelectPartition={onSelectPartition}
                          extraIndent
                        />
                      ))}
                  </TreeItemObject>
                );
              })}
          </TreeItemModel>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
