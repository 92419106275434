import { FC } from 'react';
import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import Login from 'containers/Login';
import VerifyAccount from 'containers/VerifyAccount';
import SetPassword from 'containers/SetPassword';
import ForgotPassword from 'containers/ForgotPassword';

export const Public: FC = () => {
  return (
    <Stack
      sx={{
        backgroundColor: 'background.default',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <BrowserRouter>
        <ReactRoutes>
          <Route path="/" element={<Login />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </ReactRoutes>
      </BrowserRouter>
    </Stack>
  );
};
