import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContentText, Button } from '@mui/material';
import { CheckRounded as SubmitIcon } from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { useKeyboardShortcut } from 'hooks';

interface Props {
  open: boolean;
  title: string;
  text: string;
  confirmDisabled?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const AppConfirmationDialog: FC<Props> = ({
  open,
  title,
  text,
  confirmDisabled,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();

  useKeyboardShortcut({ key: 'enter' }, () => {
    if (onConfirm && open) onConfirm();
  });

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <Button
          variant="contained"
          startIcon={<SubmitIcon />}
          onClick={onConfirm}
          disabled={confirmDisabled}
          autoFocus
          fullWidth
        >
          {t('confirm')}
        </Button>
      }
    >
      <DialogContentText>{text}</DialogContentText>
    </AppDialog>
  );
};
