import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { RefreshRounded as RefetchIcon } from '@mui/icons-material';

import { TableQueryProps } from 'components/AppTable';
import AppButton from 'components/AppButton';
import AppPagination from 'components/AppPagination';

export const AppTableFooter: FC<TableQueryProps> = ({
  pagination,
  fetchDate,
  isError,
  isRefetchError,
  isLoading,
  isRefetching,
  refetchData,
}) => {
  const { t } = useTranslation();
  const [formatedDate, setFormatedDate] = useState<string>('');
  const xs = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.only('xs'), {
    noSsr: true,
  });

  const updateTimeDistance = useCallback<() => void>(() => {
    if (fetchDate)
      setFormatedDate(t('updated_relativeTime_ago', { date: fetchDate }));
  }, [fetchDate, t]);

  const stateText = useMemo<string>(() => {
    if (isError) return t('table_data_error');
    if (isRefetchError) return t('table_data_refetch_error');
    if (isLoading) return t('table_data_loading');
    if (isRefetching) return t('table_data_refetching');
    return formatedDate;
  }, [formatedDate, isError, isLoading, isRefetchError, isRefetching, t]);

  useEffect(() => {
    updateTimeDistance();
    const interval = setInterval(updateTimeDistance, 60000);
    if (!fetchDate) clearInterval(interval);
    return () => {
      clearInterval(interval);
    };
  }, [fetchDate, updateTimeDistance]);

  return (
    <Stack
      component={Toolbar}
      direction="row"
      justifyContent="space-between"
      px={2}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {refetchData && (
          <AppButton
            title={t('update')}
            tooltipProps={{ placement: 'top' }}
            buttonProps={{
              onClick: refetchData,
              disabled: isLoading || isRefetching,
            }}
          >
            <RefetchIcon
              sx={{
                animation:
                  isLoading || isRefetching
                    ? 'spin 4s linear infinite'
                    : undefined,
              }}
            />
          </AppButton>
        )}
        {!xs && (
          <Typography
            variant="body2"
            color={isError || isRefetchError ? 'error' : 'textPrimary'}
          >
            {stateText}
          </Typography>
        )}
      </Stack>
      {pagination && <AppPagination {...pagination} />}
    </Stack>
  );
};
