import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { IconButton } from '@mui/material';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';

import { useSnackbar } from 'hooks';
import { getReduxProjectSettings } from 'helpers';
import {
  ErrorResponse,
  ProjectSettingsResponseWithEtag,
  updateProjectSettings,
} from 'api';
// Redux
import { useSelector } from 'store';
import { selectMaterial, selectNotes } from 'slices/projectSlice';

interface UseSaveProject {
  onSaveProject: () => Promise<ProjectSettingsResponseWithEtag>;
  isLoading: boolean;
}

export const useSaveProject = (): UseSaveProject => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { projectId } = useParams<'projectId'>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const material = useSelector(selectMaterial);
  const notes = useSelector(selectNotes);

  const { data: projectSettings, isFetching: isFetchingProjectSettings } =
    useQuery<ProjectSettingsResponseWithEtag>(
      ['project-settings', { projectId }],
      { enabled: !!projectId },
    );

  const { mutateAsync, isLoading } = useMutation<
    ProjectSettingsResponseWithEtag,
    AxiosError<ErrorResponse>
  >(
    ['project-settings', { projectId }],
    () => {
      if (!projectSettings) throw new Error('project settings does not exist');
      const { data, etag } = projectSettings;
      return updateProjectSettings(
        data.id,
        {
          name: data.name,
          material,
          notes,
          settings: getReduxProjectSettings(),
        },
        etag,
      );
    },
    {
      onSuccess: ({ data, etag }) => {
        enqueueSnackbar({
          key: `save_project_success_${Date.now()}`,
          message: t('save_project_success'),
          variant: 'success',
        });
        queryClient.invalidateQueries(['project', { projectId: data.id }]);
        queryClient.setQueryData<ProjectSettingsResponseWithEtag>(
          ['project-settings', { projectId: data.id }],
          { data, etag },
        );
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'save_project_decrepit',
            message: t('save_project_decrepit'),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient
                    .refetchQueries(['project-settings', { projectId }])
                    .then(() => {
                      closeSnackbar('save_project_decrepit');
                    });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `save_project_fail_${Date.now()}`,
            message: t('save_project_fail'),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  const onSaveProject = useCallback<
    () => Promise<ProjectSettingsResponseWithEtag>
  >(
    () =>
      new Promise<ProjectSettingsResponseWithEtag>((resolve, reject) => {
        if (isLoading || isFetchingProjectSettings) {
          enqueueSnackbar({
            key: 'save_project_pending',
            message: t('save_project_pending'),
            variant: 'info',
          });
          reject();
        } else {
          mutateAsync().then(resolve).catch(reject);
        }
      }),
    [enqueueSnackbar, isFetchingProjectSettings, isLoading, mutateAsync, t],
  );

  return useMemo<UseSaveProject>(
    () => ({
      onSaveProject,
      isLoading,
    }),
    [isLoading, onSaveProject],
  );
};
