import { useCallback, useMemo } from 'react';
import { CognitoIdentityCredentials, config } from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { region, userPoolId, identityPoolId, userPool } from 'configs/aws';
// Redux
import { useDispatch } from 'store';
import { initAuth, onAuthSuccess, onAuthFail } from 'slices/userSlice';

type UserAttributeKey = 'sub' | 'email_verified' | 'email';
type UserAttributes = Partial<Record<UserAttributeKey, string>>;

type AuthenticateCurrentUser = (
  password: string,
) => Promise<{ user: CognitoUser; session: CognitoUserSession }>;
type GetUserAttributes = (user: CognitoUser) => Promise<UserAttributes>;
type GetAccessToken = (user: CognitoUser) => Promise<string>;
type ChangePassword = (
  currentPassword: string,
  newPassword: string,
) => Promise<void>;
type SetPassword = (
  username: string,
  password: string,
  newPassword: string,
) => Promise<void>;
type ConfirmRegistration = (username: string, code: string) => Promise<void>;
type GetSession = () => void;
type Login = (
  username: string,
  password: string,
) => Promise<CognitoUserSession>;
type Logout = () => void;
type RefreshSession = () => NodeJS.Timeout;
type ForgotPassword = (username: string) => Promise<void>;
type ResetPassword = (
  username: string,
  verificationCode: string,
  newPassword: string,
) => Promise<void>;
type ResendConfirmationCode = (username: string) => Promise<void>;

interface UseAuthentication {
  authenticateCurrentUser: AuthenticateCurrentUser;
  changePassword: ChangePassword;
  confirmRegistration: ConfirmRegistration;
  getSession: GetSession;
  login: Login;
  logout: Logout;
  refreshSession: RefreshSession;
  resendConfirmationCode: ResendConfirmationCode;
  forgotPassword: ForgotPassword;
  resetPassword: ResetPassword;
  setPassword: SetPassword;
}

const ACCESS_TOKEN_EXPIRATION = 60; // expiration time of access token in minutes
const EXPIRATION_MARGIN = 5; // minutes before access token needs to be refreshed

export const useAuthentication = (): UseAuthentication => {
  const dispatch = useDispatch();

  const getAccessToken = useCallback<GetAccessToken>(async (user) => {
    return await new Promise<string>((resolve, reject) => {
      user.getSession(
        async (error: Error | null, session: CognitoUserSession | null) => {
          if (error || !session) reject(error);
          else resolve(session.getAccessToken().getJwtToken());
        },
      );
    });
  }, []);

  const getUserAttributes = useCallback<GetUserAttributes>(async (user) => {
    return await new Promise<UserAttributes>((resolve, reject) => {
      user.getUserAttributes((error, attributes) => {
        if (error) reject(error);
        if (!attributes) reject('No user attributes found');
        else {
          const result: UserAttributes = {};
          for (const { Name, Value } of attributes) {
            if (Name === 'sub') result.sub = Value;
            if (Name === 'email_verified') result.email_verified = Value;
            if (Name === 'email') result.email = Value;
          }
          resolve(result);
        }
      });
    });
  }, []);

  const handleAuthFail = useCallback<() => void>(() => {
    dispatch(onAuthFail());
  }, [dispatch]);

  const handleAuthSuccess = useCallback<(user: CognitoUser | null) => void>(
    (user) => {
      if (!user) dispatch(onAuthSuccess({ user: null }));
      else {
        getUserAttributes(user)
          .then(async (attributes) => {
            dispatch(
              onAuthSuccess({
                user: {
                  id: attributes.sub ?? '',
                  email: attributes.email ?? '',
                  verified: attributes.email_verified === 'true',
                },
                accessToken: await getAccessToken(user),
              }),
            );
          })
          .catch(handleAuthFail);
      }
    },
    [dispatch, getAccessToken, getUserAttributes, handleAuthFail],
  );

  const authenticateCurrentUser = useCallback<AuthenticateCurrentUser>(
    async (Password) => {
      return await new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (user) {
          user.authenticateUser(
            new AuthenticationDetails({
              Username: user.getUsername(),
              Password,
            }),
            {
              onFailure: (error) => {
                reject(error);
              },
              onSuccess: (session) => {
                resolve({ user, session });
              },
            },
          );
        }
      });
    },
    [],
  );

  const refreshCredentials = useCallback<
    (session: CognitoUserSession) => Promise<void>
  >((session) => {
    return new Promise<void>((resolve, reject) => {
      const loginId = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
      config.region = region;
      config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: {
          [loginId]: session.getIdToken().getJwtToken(),
        },
      });
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (<CognitoIdentityCredentials>config.credentials).refresh(
        async (error) => {
          if (error) reject(error);
          else resolve();
        },
      );
    });
  }, []);

  const refreshSession = useCallback<RefreshSession>(() => {
    const callback = () =>
      new Promise<void>((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (!user) reject();
        else {
          user.getSession(
            (error: Error | null, session: CognitoUserSession | null) => {
              if (error || !session) reject(error);
              else {
                const refreshToken = session.getRefreshToken();
                user.refreshSession(refreshToken, (e: Error | null) => {
                  if (e) reject(e);
                  else refreshCredentials(session).then(resolve).catch(reject);
                });
              }
            },
          );
        }
      }).catch(handleAuthFail);
    callback();
    return setInterval(
      callback,
      (ACCESS_TOKEN_EXPIRATION - EXPIRATION_MARGIN) * 60 * 1000,
    );
  }, [handleAuthFail, refreshCredentials]);

  const getSession = useCallback<GetSession>(() => {
    dispatch(initAuth());
    const user = userPool.getCurrentUser();
    if (!user) handleAuthSuccess(null);
    else {
      user.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error || !session) handleAuthFail();
          else handleAuthSuccess(user);
        },
      );
    }
  }, [dispatch, handleAuthFail, handleAuthSuccess]);

  const forgotPassword = useCallback<ForgotPassword>(async (Username) => {
    const user = new CognitoUser({ Username, Pool: userPool });
    return await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  }, []);

  const resetPassword = useCallback<ResetPassword>(
    async (Username, verificationCode, newPassword) => {
      const user = new CognitoUser({ Username, Pool: userPool });
      return await new Promise((resolve, reject) => {
        user.confirmPassword(verificationCode, newPassword, {
          onSuccess: () => {
            resolve();
          },
          onFailure: (error) => {
            reject(error);
          },
        });
      });
    },
    [],
  );

  const resendConfirmationCode = useCallback<ResendConfirmationCode>(
    async (Username) => {
      const user = new CognitoUser({ Username, Pool: userPool });
      return await new Promise((resolve, reject) => {
        user.resendConfirmationCode((error: Error | undefined, result) => {
          if (error) {
            reject(error);
          }
          resolve(result);
        });
      });
    },
    [],
  );

  const changePassword = useCallback<ChangePassword>(
    async (currentPassword, newPassword) => {
      return await new Promise((resolve, reject) => {
        authenticateCurrentUser(currentPassword)
          .then(({ user }) => {
            user.changePassword(
              currentPassword,
              newPassword,
              (error, result) => {
                if (result === 'SUCCESS') resolve();
                else reject(error);
              },
            );
          })
          .catch(reject);
      });
    },
    [authenticateCurrentUser],
  );

  const setPassword = useCallback<SetPassword>(
    async (Username, Password, newPassword) => {
      dispatch(initAuth());
      return await new Promise<void>((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool: userPool });
        user.authenticateUser(
          new AuthenticationDetails({ Username, Password }),
          {
            onSuccess: () => {
              changePassword(Password, newPassword)
                .then(() => {
                  handleAuthSuccess(user);
                  resolve();
                })
                .catch((error) => {
                  handleAuthSuccess(user);
                  reject({
                    type: 'change-password-failed',
                    data: error,
                  });
                });
            },
            onFailure: (error) => {
              handleAuthFail();
              reject({ type: 'auth-failed', data: error });
            },
            newPasswordRequired: (userAttributes: {
              email: string;
              email_verified: 'true' | 'false';
            }) => {
              user.completeNewPasswordChallenge(
                newPassword,
                { email: userAttributes.email },
                {
                  onSuccess: () => {
                    handleAuthSuccess(user);
                    resolve();
                  },
                  onFailure: (error) => {
                    handleAuthFail();
                    reject({
                      type: 'password-challenge-failed',
                      data: error,
                    });
                  },
                },
              );
            },
          },
        );
      });
    },
    [changePassword, dispatch, handleAuthFail, handleAuthSuccess],
  );

  const confirmRegistration = useCallback<ConfirmRegistration>(
    async (Username, code) => {
      dispatch(initAuth());
      return await new Promise<void>((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool: userPool });
        user.confirmRegistration(code, true, (error: Error | undefined) => {
          if (error) {
            handleAuthFail();
            reject(error);
          }
          handleAuthSuccess(null);
          resolve();
        });
      });
    },
    [dispatch, handleAuthFail, handleAuthSuccess],
  );

  const login = useCallback<Login>(
    async (Username, Password) => {
      dispatch(initAuth());
      return await new Promise<CognitoUserSession>(
        (
          resolve,
          reject: (error: { type: string; data?: unknown }) => void,
        ) => {
          const user = new CognitoUser({ Username, Pool: userPool });
          user.authenticateUser(
            new AuthenticationDetails({ Username, Password }),
            {
              onSuccess: (session) => {
                refreshCredentials(session)
                  .then(() => {
                    handleAuthSuccess(user);
                    resolve(session);
                  })
                  .catch((error) => {
                    handleAuthSuccess(user);
                    reject({ type: 'refresh-credentials_failed', data: error });
                  });
              },
              onFailure: (error) => {
                handleAuthFail();
                reject({ type: 'auth-failed', data: error });
              },
              newPasswordRequired: (userAttributes: {
                email: string;
                email_verified: 'true' | 'false';
              }) => {
                handleAuthFail();
                reject({
                  type: 'new-password-required',
                  data: userAttributes.email,
                });
              },
            },
          );
        },
      );
    },
    [dispatch, handleAuthFail, handleAuthSuccess, refreshCredentials],
  );

  const logout = useCallback<Logout>(() => {
    const user = userPool.getCurrentUser();
    if (user) {
      dispatch(initAuth());
      user.signOut(() => {
        localStorage.clear();
        handleAuthSuccess(null);
      });
    }
  }, [dispatch, handleAuthSuccess]);

  return useMemo<UseAuthentication>(
    () => ({
      authenticateCurrentUser,
      changePassword,
      setPassword,
      confirmRegistration,
      getSession,
      login,
      logout,
      refreshSession,
      forgotPassword,
      resetPassword,
      resendConfirmationCode,
    }),
    [
      authenticateCurrentUser,
      changePassword,
      setPassword,
      confirmRegistration,
      getSession,
      login,
      logout,
      refreshSession,
      forgotPassword,
      resetPassword,
      resendConfirmationCode,
    ],
  );
};
