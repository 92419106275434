import { useMemo, useCallback, ReactNode, FC } from 'react';
import { Object3D, Group } from 'three';
import { useLoader, Vector3 } from '@react-three/fiber';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader';

import Mesh from 'components/ProjectMesh';
import { base64ToDataUrl, countDecimals, subtractWithDecimals } from 'helpers';
import { ModelValidationCallbacks, useValidate3DModel } from 'hooks';
import { ModelObjectInfo, Partition } from 'api';

interface Props {
  data: string;
  transformValue: [number, number];
  objects: ModelObjectInfo[] | undefined;
  selectedLayers: Omit<Partition, 'partitionID'>[];
  highlightSelected: boolean;
  highlightUnsliced: boolean;
  modelValidationCallbacks: ModelValidationCallbacks;
}

export const Project3DObject: FC<Props> = ({
  data,
  transformValue,
  objects,
  selectedLayers,
  highlightSelected,
  highlightUnsliced,
  modelValidationCallbacks,
}) => {
  const model = useLoader<Group, string>(
    ThreeMFLoader,
    base64ToDataUrl(data, '3mf'),
  );

  useValidate3DModel({ model, ...modelValidationCallbacks });

  const objectIds = useMemo<number[]>(
    () => objects?.map(({ objectID }) => objectID) ?? [],
    [objects],
  );

  const meshIds = useMemo<number[]>(() => {
    const result: number[] = [];
    const extractMeshIds = (target: Object3D): void => {
      const { children, type, id } = target;
      if (type === 'Mesh') {
        result.push(id);
      }
      if (type === 'Group') {
        children.length ? children.forEach(extractMeshIds) : result.push(id);
      }
    };
    extractMeshIds(model);
    return result.sort((a, b) => a - b);
  }, [model]);

  const getMeshHeight = useCallback<
    (object: ModelObjectInfo | undefined) => { startZ: number; endZ: number }
  >(
    (object) =>
      highlightUnsliced && object
        ? {
            startZ: subtractWithDecimals(
              Math.max(
                ...[object.startZ, object.endZ, object.layerThickness].map(
                  (n) => countDecimals(n),
                ),
              ),
              object.startZ,
              object.layerThickness,
            ),
            endZ: object.endZ,
          }
        : { startZ: -Infinity, endZ: Infinity },
    [highlightUnsliced],
  );

  const getMeshHighlights = useCallback<
    (meshId: number | undefined) => {
      startZ: number[];
      endZ: number[];
      size: number;
    }
  >(
    (meshId) => {
      if (highlightSelected && meshId && objectIds.length === meshIds.length) {
        const meshHighlights = selectedLayers.filter(
          ({ objectID }) => objectID === meshId || objectID === -1,
        );
        return {
          startZ: meshHighlights.map(({ startZ }) => startZ),
          endZ: meshHighlights.map(({ endZ }) => endZ),
          size: meshHighlights.length,
        };
      }
      return { startZ: [], endZ: [], size: 0 };
    },
    [highlightSelected, objectIds.length, meshIds.length, selectedLayers],
  );

  const getPosition = useCallback<(target: Object3D) => Vector3>(
    (target) =>
      !target.parent
        ? (Object.values(target.position).map((point, index) =>
            index !== 2 ? point + transformValue[index] - 50 : point,
          ) as Vector3)
        : target.position,
    [transformValue],
  );

  const meshObjects = useMemo<ReactNode>(() => {
    const extractObjects = (target: Object3D): ReactNode => {
      const { children, type, ...props } = target;
      if (type === 'Mesh') {
        const index = meshIds.indexOf(target.id);
        return (
          <Mesh
            key={target.uuid}
            mesh={target}
            height={getMeshHeight(objects?.at(index))}
            highlights={getMeshHighlights(objectIds.at(index))}
          />
        );
      }
      if (type === 'Group') {
        return (
          <group key={target.uuid} {...props} position={getPosition(target)}>
            {children.map((child: Object3D) => extractObjects(child))}
          </group>
        );
      }
    };
    return extractObjects(model);
  }, [
    getMeshHeight,
    getMeshHighlights,
    getPosition,
    meshIds,
    model,
    objectIds,
    objects,
  ]);

  return <>{meshObjects}</>;
};
