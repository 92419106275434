import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  alpha,
} from '@mui/material';
import { ArrowDropDownRounded as ExpandMoreIcon } from '@mui/icons-material';
import { DropResult } from 'react-beautiful-dnd';

import AppDraggableList from 'components/AppDraggableList';
import { ModelFileInfo } from 'api';
import { reorder } from 'helpers';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  selectObjectMeltOrder,
  selectSelection,
  selectMeltAllObjectsSimultaneously,
  setObjectMeltOrder,
} from 'slices/partitionSettingsSlice';

interface Props {
  modelInfo: ModelFileInfo | undefined;
  onHighlightObject: (objectID?: number) => void;
}

export const ProjectMeltOrder: FC<Props> = ({
  modelInfo,
  onHighlightObject,
}) => {
  const { t } = useTranslation();
  const { palette, shape, transitions } = useTheme();
  const dispatch = useDispatch();
  const objectMeltOrder = useSelector(selectObjectMeltOrder);
  const selection = useSelector(selectSelection);
  const meltAllObjectsSimultaneously = useSelector(
    selectMeltAllObjectsSimultaneously,
  );
  const [selectedObject, setSelectedObject] = useState<number>();

  const list = useMemo<{ id: number; value: string; tooltip: string }[]>(() => {
    const objects =
      (selection === 'model'
        ? modelInfo?.objects
        : modelInfo?.objects
            .slice()
            .sort(
              ({ objectID: a }, { objectID: b }) =>
                objectMeltOrder.indexOf(a) - objectMeltOrder.indexOf(b),
            )) ?? [];
    return objects.map(({ objectID, name }) => ({
      id: objectID,
      value: name ?? t('object_{{id}}', { id: objectID }),
      tooltip: name ? t('object_{{id}}', { id: objectID }) : '',
    }));
  }, [modelInfo?.objects, objectMeltOrder, selection, t]);

  const handleHighlightObject = useCallback<(objectID: number) => void>(
    (objectID: number) => {
      const arg: number | undefined =
        objectID !== selectedObject ? objectID : undefined;
      onHighlightObject(arg);
      setSelectedObject(arg);
    },
    [onHighlightObject, selectedObject],
  );

  const handleReorder = useCallback<(result: DropResult) => void>(
    ({ destination, source }) => {
      if (destination && selection !== 'model') {
        dispatch(
          setObjectMeltOrder(
            reorder<number>(objectMeltOrder, source.index, destination.index),
          ),
        );
      }
    },
    [dispatch, objectMeltOrder, selection],
  );

  useEffect(() => {
    if (meltAllObjectsSimultaneously) {
      onHighlightObject(undefined);
      setSelectedObject(undefined);
    }
  }, [meltAllObjectsSimultaneously, onHighlightObject]);

  return !meltAllObjectsSimultaneously ? (
    <Accordion sx={{ background: 'none', boxShadow: 'none' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
        <Typography>{t('melt_order')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <AppDraggableList
          id="object-melt-order"
          onDragEnd={handleReorder}
          disabled={selection === 'model'}
        >
          {list.map(({ id, value, tooltip }) => ({
            tooltip,
            listItemProps: {
              onClick: () => handleHighlightObject(id),
              sx: {
                transition: transitions.create('background-color', {
                  duration: transitions.duration.short,
                }),
                borderRadius: `${shape.borderRadius}px`,
                backgroundColor:
                  selectedObject === id ? 'primary.dark' : undefined,
                cursor: 'pointer',
                '&:hover, &:focus, &:active': {
                  backgroundColor: () =>
                    selectedObject === id
                      ? 'primary.dark'
                      : alpha(palette.primary.dark, 0.5),
                },
              },
            },
            component: <Typography>{value}</Typography>,
          }))}
        </AppDraggableList>
      </AccordionDetails>
    </Accordion>
  ) : null;
};
