import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { Box, Stack, Typography } from '@mui/material';
import { DeleteRounded as DeleteIcon } from '@mui/icons-material';

import { DeleteModelDialog } from 'containers/Models';
import AppDetailsDrawer from 'components/AppDetailsDrawer';
import Project3DView from 'components/Project3DView';
import { AppButtonForm } from 'components/AppFormControl';
import {
  ErrorResponse,
  getModelDetails,
  getUnusedModels as getStaledModels,
  Model,
  ModelDetailsWithEtag,
} from 'api';
import { useSnackbar, useSearchParams } from 'hooks';
import { countDecimals, subtractWithDecimals } from 'helpers';

export const AppModelDetails: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [{ rowId: modelId }, { deleteSearchParam }] = useSearchParams();
  const [deleteDialog, setDeleteDialog] = useState(false);

  const { data: details, isLoading } = useQuery<
    ModelDetailsWithEtag | void,
    AxiosError<ErrorResponse>
  >(
    ['model', { modelId }],
    () => {
      if (modelId) return getModelDetails(modelId);
    },
    {
      enabled: !!modelId,
      onError: () => {
        enqueueSnackbar({
          key: `get_model_details_fail_${Date.now()}`,
          message: t('get_model_details_fail'),
          variant: 'error',
        });
        deleteSearchParam('rowId');
      },
    },
  );

  const { data: staleModels } = useQuery<Model[], AxiosError<ErrorResponse>>(
    'stale-models',
    getStaledModels,
    {
      enabled: !!modelId,
      keepPreviousData: true,
      staleTime: 0,
      onError: () => {
        enqueueSnackbar({
          key: `get_stale_models_fail_${Date.now()}`,
          message: t('get_stale_models_fail'),
          variant: 'error',
        });
      },
    },
  );

  const userHasDeletePermission = useMemo<boolean>(
    () => Boolean(staleModels?.some((model) => model.id === modelId)),
    [modelId, staleModels],
  );

  const onClose = useCallback<() => void>(() => {
    deleteSearchParam('rowId');
  }, [deleteSearchParam]);

  if (isLoading) return <AppDetailsDrawer open onClose={onClose} isLoading />;

  if (details)
    return (
      <>
        <AppDetailsDrawer
          open
          onClose={onClose}
          title={details.data.name}
          actions={
            <AppButtonForm
              label={t('delete')}
              helperText={
                userHasDeletePermission
                  ? t('delete_model_helper')
                  : t('model_not_stale')
              }
              componentProps={{
                onClick: () => setDeleteDialog(true),
                color: 'error',
                startIcon: <DeleteIcon />,
                disabled: !userHasDeletePermission,
              }}
            />
          }
        >
          <Typography variant="h5">{t('info')}</Typography>
          <Stack spacing={1}>
            <Typography>
              {t('details_layerThickness', {
                value: details.data.fileInfo.layerThickness,
              })}
            </Typography>
            <Typography>
              {t('details_height', {
                value:
                  details.data.fileInfo.endZ -
                  subtractWithDecimals(
                    Math.max(
                      ...[
                        details.data.fileInfo.startZ,
                        details.data.fileInfo.endZ,
                        details.data.fileInfo.layerThickness,
                      ].map((n) => countDecimals(n)),
                    ),
                    details.data.fileInfo.startZ,
                    details.data.fileInfo.layerThickness,
                  ),
              })}
            </Typography>
            <Typography>
              {t('details_objects', {
                value: details.data.fileInfo.objects.length,
              })}
            </Typography>
          </Stack>
          <Typography variant="h5">{t('preview')}</Typography>
          <Box
            flex={1}
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'text.primary',
              borderRadius: ({ shape }) => `${shape.borderRadius}px`,
              boxSizing: 'border-box',
            }}
          >
            <Project3DView model={details.data} />
          </Box>
        </AppDetailsDrawer>
        {userHasDeletePermission && (
          <DeleteModelDialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            modelId={details.data.id}
          />
        )}
      </>
    );

  return null;
};
