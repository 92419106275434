import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate, formatDistanceToNow } from 'date-fns';

import * as resources from 'assets/locales';
import { getFnsLocale } from 'helpers';

const debug = process.env.NODE_ENV === 'development';
const supportedLngs = ['en'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    ns: [
      'algorithms',
      'common',
      'projectState',
      'releaseNotes',
      'shortcuts',
      'unit',
      'userGuide',
      'userRole',
      'userStatus',
      'validations',
    ],
    fallbackLng: 'en',
    debug,
    supportedLngs,
    load: 'currentOnly',
    defaultNS: 'common',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
      cookieMinutes: 10,
      cookieDomain: 'myDomain',
      htmlTag: document.documentElement,
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        const locale = getFnsLocale(lng);
        if (format === 'uppercase') {
          return value.toUpperCase();
        }
        if (format === 'date' && value instanceof Date) {
          return formatDate(value, 'P', { locale });
        }
        if (format === 'dateTime' && value instanceof Date) {
          return formatDate(value, 'Pp', { locale });
        }
        if (format === 'relativeTime' && value instanceof Date) {
          return formatDistanceToNow(value, { locale });
        }
        return value;
      },
    },
  });

export default i18n;
