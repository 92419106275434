import { ReactNode, useMemo } from 'react';
import { AlertColor } from '@mui/material';
import {
  useSnackbar as useNotistack,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';

import AppSnackbar, { SnackbarProps } from 'components/AppSnackbar';

interface ProviderContext {
  enqueueSnackbar: (args: {
    key: SnackbarKey;
    message: SnackbarMessage;
    variant: AlertColor;
    persist?: boolean;
    action?: ReactNode;
  }) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

export const useSnackbar = (): ProviderContext => {
  const { enqueueSnackbar, closeSnackbar } = useNotistack();

  return useMemo<ProviderContext>(
    () => ({
      enqueueSnackbar: ({ key, message, variant, persist, action }) =>
        enqueueSnackbar(message, {
          key,
          variant,
          persist,
          content: (_key, message) =>
            SnackBar({ snackbarKey: key, message, severity: variant, action }),
        }),
      closeSnackbar: closeSnackbar,
    }),
    [closeSnackbar, enqueueSnackbar],
  );
};

const SnackBar = (props: SnackbarProps) => <AppSnackbar {...props} />;
