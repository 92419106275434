import type { Transition } from 'history';
import { useCallback, useContext } from 'react';

import { useBlocker } from 'hooks';
import { PromptContext } from 'providers';

export const usePrompt = (active = true): void => {
  const onShowPrompt = useContext(PromptContext);

  const blocker = useCallback(
    async (transition: Transition) => {
      try {
        await onShowPrompt();
        transition.retry();
      } catch (error) {
        console.error(error);
      }
    },
    [onShowPrompt],
  );

  return useBlocker(blocker, active);
};
