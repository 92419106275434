/**
 * @summary Reorders an array based on the new position of an item
 * @param {T[]}  list list of items
 * @param {number}  prevIndex previous position of the item
 * @param {number}  newIndex new position of the item
 * @returns {T[]} reordered list
 */
export const reorder = <T>(
  list: T[],
  prevIndex: number,
  newIndex: number,
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(prevIndex, 1);
  result.splice(newIndex, 0, removed);

  return result;
};
