import { createContext, FC, useCallback, useRef, useState } from 'react';

import { CloseProjectDialog } from 'components/ProjectQuickSettings';

export const PromptContext = createContext<() => Promise<void>>(() =>
  Promise.resolve(),
);

export const PromptProvider: FC = ({ children }) => {
  const rejectRef = useRef<() => void>();
  const resolveRef = useRef<() => void>();
  const [open, setOpen] = useState(false);

  const onShowPrompt = useCallback<() => Promise<void>>(
    () =>
      new Promise<void>((resolve, reject) => {
        rejectRef.current = reject;
        resolveRef.current = resolve;
        setOpen(true);
      }),
    [],
  );

  const onStay = useCallback<() => void>(() => {
    if (rejectRef.current) {
      rejectRef.current();
    }
    setOpen(false);
  }, []);

  const onLeave = useCallback<() => void>(() => {
    if (resolveRef.current) {
      resolveRef.current();
    }
    setOpen(false);
  }, []);

  return (
    <PromptContext.Provider value={onShowPrompt}>
      {children}
      <CloseProjectDialog open={open} onStay={onStay} onLeave={onLeave} />
    </PromptContext.Provider>
  );
};
