import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Box, useMediaQuery, Stack, Theme } from '@mui/material';

import ProjectHeader from 'components/ProjectHeader';
import ProjectViewMenu from 'components/ProjectViewMenu';
import ProjectModelPreview from 'components/ProjectModelPreview';
import ProjectBuildPreview from 'components/ProjectBuildPreview';
import AppTransition from 'components/AppTransition';
import {
  ErrorResponse,
  GenerateState,
  getGenerateState,
  getProjectSettings,
  ProjectState,
  UserDetailsWithEtag,
} from 'api';
import { useSnackbar, usePrompt } from 'hooks';
import { checkWritePermission } from 'helpers';
// Redux
import { useSelector, useDispatch } from 'store';
import { View, selectView, setMaterial, setNotes } from 'slices/projectSlice';
import { loadPartitionSettings } from 'slices/partitionSettingsSlice';
import { loadMeltSettings } from 'slices/meltSettingsSlice';
import { loadBuildSettings } from 'slices/buildSettingsSlice';

const REFETCH_INTERVAL = 60000; // in milliseconds

export const Project: FC = () => {
  const smallScreenSize = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  );
  const { t } = useTranslation();
  const { projectId = '' } = useParams<'projectId'>();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const view = useSelector(selectView);
  const [isInitialState, setIsInitialState] = useState(true);
  const [isRefetchingState, setIsRefetchingState] = useState(false);

  const { data: project } = useQuery(
    ['project-settings', { projectId }],
    () => getProjectSettings(projectId),
    {
      onError: () => {
        enqueueSnackbar({
          key: `get_project_settings_fail_${Date.now()}`,
          message: t('get_project_settings_fail'),
          variant: 'error',
          persist: true,
        });
        navigate('/projects');
      },
    },
  );

  const { data: generateState } = useQuery<
    GenerateState,
    AxiosError<ErrorResponse>
  >(['generate', { projectId }], () => getGenerateState(projectId as string), {
    enabled: !!projectId,
    refetchInterval: isRefetchingState ? REFETCH_INTERVAL : false,
    refetchIntervalInBackground: true,
    onSuccess: ({ projectState }) => {
      closeSnackbar('generate_state_fail');
      if (!isInitialState) {
        if (projectState === ProjectState.GeneratedSuccessfully) {
          enqueueSnackbar({
            key: 'generate_complete',
            message: t('generate_complete'),
            variant: 'success',
            persist: true,
          });
        }
        if (projectState === ProjectState.GenerationFailed) {
          enqueueSnackbar({
            key: 'generate_fail',
            message: t('generate_fail'),
            variant: 'error',
            persist: true,
          });
        }
      }
      setIsInitialState(false);
    },
    onError: () => {
      enqueueSnackbar({
        key: 'generate_state_fail',
        message: t('generate_state_fail'),
        variant: 'error',
      });
    },
  });

  const generateHasInitiated = useMemo<boolean>(
    () => generateState?.projectState !== ProjectState.NotGenerated,
    [generateState?.projectState],
  );

  const { data: currentUser } = useQuery<UserDetailsWithEtag>('current-user');

  const userHasWritePermission = useMemo<boolean>(
    () => checkWritePermission(currentUser?.data, project?.data.createdBy),
    [currentUser?.data, project?.data.createdBy],
  );

  useEffect(() => {
    setIsRefetchingState(
      generateState?.projectState === ProjectState.GenerationQueued ||
        generateState?.projectState === ProjectState.Generating,
    );
  }, [generateState?.projectState]);

  useEffect(() => {
    if (project) {
      const { partitionSettings, meltSettings, buildSettings } =
        project.data.settings;
      dispatch(loadPartitionSettings(partitionSettings));
      dispatch(loadMeltSettings(meltSettings));
      dispatch(loadBuildSettings(buildSettings));
      dispatch(setMaterial(project.data.material));
      dispatch(setNotes(project.data.notes));
    }
  }, [dispatch, navigate, project]);

  usePrompt();

  if (!project) return <AppTransition />;

  return (
    <>
      <Stack
        sx={{
          backgroundColor: 'background.default',
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <ProjectHeader
          projectState={generateState?.projectState}
          userHasWritePermission={userHasWritePermission}
          generateHasInitiated={generateHasInitiated}
        />
        <Box flex={1} position="relative" overflow="hidden">
          <ProjectViewMenu smallScreenSize={smallScreenSize} />
          {view === View.ModelPreview && (
            <ProjectModelPreview
              model={project.data.cadModel}
              layerThickness={project.data.cadModel.fileInfo.layerThickness}
              userHasWritePermission={userHasWritePermission}
              generateHasInitiated={generateHasInitiated}
            />
          )}
          {view === View.BuildPreview && <ProjectBuildPreview />}
        </Box>
      </Stack>
    </>
  );
};
