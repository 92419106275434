import { ChangeEvent, FC, ReactNode, useCallback, useState } from 'react';
import {
  Select,
  NativeSelect,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import AppFormControl, { AppFormControlProps } from 'components/AppFormControl';

type OptionLabel =
  | {
      mobile: string;
      desktop: string | ReactNode;
    }
  | string;

interface Props extends AppFormControlProps {
  defaultValue?: number | string;
  value: number | string;
  onChange: (value: unknown) => void;
  options: { value: number | string; label: OptionLabel; tooltip?: string }[];
  componentProps?: SelectProps;
}

export const AppSelectForm: FC<Props> = ({
  label,
  helperText,
  defaultValue,
  value,
  onChange,
  options,
  componentProps,
  ...formControlProps
}) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const [openTooltip, setOpenTooltip] = useState<string | undefined>(undefined);

  const optionProps = useCallback<
    (
      v: string | number,
      t: string | undefined,
    ) => {
      value: string | number;
      onMouseEnter: () => void;
      onMouseLeave: () => void;
      onClick: () => void;
    }
  >(
    (v, t) => ({
      value: v,
      onMouseEnter: () => setOpenTooltip(t),
      onMouseLeave: () => setOpenTooltip(undefined),
      onClick: () => setOpenTooltip(undefined),
    }),
    [],
  );

  return (
    <AppFormControl
      label={label}
      helperText={openTooltip ?? helperText}
      {...formControlProps}
    >
      {xs ? (
        <NativeSelect
          defaultValue={defaultValue}
          value={value}
          onChange={(e: ChangeEvent<{ value: unknown }>) => {
            onChange(e.target.value);
          }}
          inputProps={{
            name: label,
            id: `${label}-form`,
          }}
        >
          <option {...optionProps('', '')} />
          {options.map(({ value: v, label: l, tooltip: t }) => (
            <option key={v} {...optionProps(v, t)}>
              {typeof l === 'string' ? l : l.mobile}
            </option>
          ))}
        </NativeSelect>
      ) : (
        <Select
          labelId={`${label}-label`}
          id={`${label}-form`}
          defaultValue={defaultValue}
          value={value}
          onChange={(e: SelectChangeEvent<unknown>) => {
            onChange(e.target.value);
          }}
          label={label}
          {...componentProps}
        >
          {options.map(({ value: v, label: l, tooltip: t }) => (
            <MenuItem key={v} {...optionProps(v, t)}>
              {typeof l === 'string' ? l : l.desktop}
            </MenuItem>
          ))}
        </Select>
      )}
    </AppFormControl>
  );
};
