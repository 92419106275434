import { FC, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Stack, Button, Typography, IconButton } from '@mui/material';
import {
  DeleteRounded as DeleteIcon,
  RefreshRounded as RefreshIcon,
} from '@mui/icons-material';

import AppDialog from 'components/AppDialog';
import { AppTextForm } from 'components/AppFormControl';
import {
  deleteUser,
  ErrorResponse,
  getUserDetails,
  UserDetailsWithEtag,
} from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  userId: string | undefined;
  onClose: () => void;
}

export const DeleteUserDialog: FC<Props> = ({ userId, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const emailRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>('');
  const [incorrectEmail, setIncorrectEmail] = useState<boolean>(false);

  const handleClose = useCallback<() => void>(() => {
    setEmail('');
    onClose();
  }, [onClose]);

  const {
    data: details,
    isLoading: isDetailsLoading,
    isRefetching: isDetailsRefetching,
  } = useQuery<UserDetailsWithEtag, AxiosError<ErrorResponse>>(
    ['user', { userId }],
    () => {
      if (!userId) throw new Error('user id does not exist');
      return getUserDetails(userId);
    },
    {
      enabled: !!userId,
      onError: () => {
        enqueueSnackbar({
          key: `get_user_details_fail_${Date.now()}`,
          message: t('get_user_details_fail'),
          variant: 'error',
        });
      },
    },
  );

  const { mutate: onDeleteUser, isLoading: isDeleting } = useMutation<
    void,
    AxiosError<ErrorResponse>
  >(
    ['user', { userId }],
    () => {
      if (!details) throw new Error('user details does not exist');
      return deleteUser(details.data.id, details.etag);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          key: `delete_user_success_${Date.now()}`,
          message: t('delete_user_success', { email: details?.data.email }),
          variant: 'success',
        });
        queryClient.invalidateQueries(['users']);
        queryClient.removeQueries(['user', { userId }]);
        handleClose();
      },
      onError: ({ response }) => {
        if (response?.status === 412) {
          enqueueSnackbar({
            key: 'delete_user_decrepit',
            message: t('delete_user_decrepit', {
              email: details?.data.email,
            }),
            variant: 'error',
            persist: true,
            action: (
              <IconButton
                color="inherit"
                onClick={() => {
                  queryClient.refetchQueries(['user', { userId }]).then(() => {
                    closeSnackbar('delete_user_decrepit');
                  });
                }}
              >
                <RefreshIcon />
              </IconButton>
            ),
          });
        } else {
          enqueueSnackbar({
            key: `delete_user_fail_${Date.now()}`,
            message: t('delete_user_fail', { email: details?.data.email }),
            variant: 'error',
            persist: true,
          });
        }
      },
    },
  );

  const handleSubmit = useCallback<() => void>(() => {
    if (email !== details?.data.email) {
      setIncorrectEmail(true);
    } else {
      onDeleteUser();
    }
  }, [details?.data.email, email, onDeleteUser]);

  return (
    <AppDialog
      open={!!userId}
      onClose={handleClose}
      title={t('delete_user')}
      actions={
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          disabled={isDeleting || isDetailsLoading || isDetailsRefetching}
          fullWidth
        >
          {t('delete')}
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography>
          {t('delete_user_confirmation', { email: details?.data.email })}
        </Typography>
        <AppTextForm
          ref={emailRef}
          label={t('confirm_email')}
          value={email}
          onChange={setEmail}
          helperText={t('delete_user_email_helper')}
          error={incorrectEmail}
          errorText={t('delete_user_email_not_match')}
        />
      </Stack>
    </AppDialog>
  );
};
