import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TableRow, TableCell } from '@mui/material';

import ProjectAddPartitionForm from 'components/ProjectAddPartitionForm';
import {
  addWithDecimals,
  subtractWithDecimals,
  countDecimals,
  getSelectionMode,
} from 'helpers';
import { ModelFileInfo, Partition } from 'api';
// Redux
import { SelectPartitionPayload } from 'slices/partitionSettingsSlice';

const ROW_HEIGHT = 52;

interface Props extends ModelFileInfo {
  modelName: string | undefined;
  partitions: Partition[];
  selected?: boolean;
  onAddPartition?: (breakpoint: [number, number]) => void;
  onSelectPartition?: (payload: SelectPartitionPayload) => void;
  onChangeMeltSettingsPreset?: () => void;
}

export const TreeItemModel: FC<Props> = ({
  modelName,
  startZ,
  endZ,
  layerThickness,
  partitions,
  selected,
  onAddPartition,
  onSelectPartition,
  onChangeMeltSettingsPreset,
  children,
}) => {
  const { t } = useTranslation();

  const numberOfDecimals = useMemo<number>(
    () => Math.max(...[startZ, endZ].map((n) => countDecimals(n))),
    [startZ, endZ],
  );

  const options = useMemo<number[]>(() => {
    const result = [];
    let i = startZ;
    while (i < endZ) {
      result.push(i);
      i = addWithDecimals(numberOfDecimals, i, layerThickness);
    }
    return result;
  }, [startZ, endZ, numberOfDecimals, layerThickness]);

  const handleAddPartition = useCallback<(breakpoint: number) => void>(
    (breakpoint: number) => {
      if (onAddPartition) {
        onAddPartition([
          breakpoint,
          addWithDecimals(numberOfDecimals, breakpoint, layerThickness),
        ]);
      }
    },
    [onAddPartition, numberOfDecimals, layerThickness],
  );

  const handleSelectPartition = useCallback<
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  >(
    (e) => {
      e.stopPropagation();
      if (onSelectPartition) {
        onSelectPartition({ target: -1, mode: getSelectionMode(e) });
      }
    },
    [onSelectPartition],
  );

  return (
    <>
      <TableRow
        hover={!!onSelectPartition}
        sx={{
          cursor: onSelectPartition ? 'pointer' : 'default',
          border: 'none',
          height: ROW_HEIGHT,
        }}
        onClick={handleSelectPartition}
        selected={selected}
      >
        <TableCell colSpan={2}>
          <Typography variant="body2">{modelName || t('model')}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {subtractWithDecimals(numberOfDecimals, startZ, layerThickness)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{endZ}</Typography>
        </TableCell>
        {onChangeMeltSettingsPreset && <TableCell />}
        <TableCell align="right">
          {onAddPartition && (
            <ProjectAddPartitionForm
              iconButtonProps={{ disabled: !onAddPartition }}
              onAddPartition={handleAddPartition}
              options={options}
              getOptionDisabled={(option) =>
                partitions.map(({ endZ }) => endZ).includes(option)
              }
              getOptionLabel={(option) => String(option)}
            />
          )}
        </TableCell>
      </TableRow>
      {children}
    </>
  );
};
