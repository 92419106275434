import { FC, Suspense, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProgress } from '@react-three/drei/core/useProgress';
import { Box, LinearProgress, Typography } from '@mui/material';

import {
  Project3DViewSettings,
  Project3DViewSettingsProps,
} from 'components/Project3DView';
import Project3DCanvas from 'components/Project3DCanvas';
import { ModelDetails, Partition } from 'api';
import { useSnackbar } from 'hooks';

interface Props {
  model: ModelDetails | undefined;
  highlights?: Omit<Partition, 'partitionID'>[];
  settings?: Omit<Project3DViewSettingsProps, 'onResetCamera'>;
}

export const Project3DView: FC<Props> = ({ model, highlights, settings }) => {
  const { progress, loaded, total } = useProgress();
  const canvasRef = useRef<{ resetCamera: () => void }>(null);
  const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onModelValidationFail = useCallback(
    () =>
      enqueueSnackbar({
        key: 'model_loaded_with_warnings',
        message: t('model_loaded_with_warnings'),
        variant: 'warning',
        persist: true,
      }),
    [enqueueSnackbar, t],
  );

  const onModelValidationFailCleanup = useCallback(
    () => closeSnackbar('model_loaded_with_warnings'),
    [closeSnackbar],
  );

  return (
    <Box
      sx={{ height: 1, width: 1, cursor }}
      onMouseEnter={() => setCursor('grab')}
      onMouseUp={() => setCursor('grab')}
      onMouseDown={() => setCursor('grabbing')}
    >
      <Suspense
        fallback={
          <Box
            sx={{
              width: 1,
              height: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: 3 / 4, position: 'relative' }}>
              <LinearProgress value={progress} variant="determinate" />
              <Typography
                sx={{
                  width: 1,
                  position: 'absolute',
                  top: 10,
                  fontWeight: 100,
                }}
                align="center"
                variant="h5"
                color="textSecondary"
              >
                {progress}% ({loaded}/{total})
              </Typography>
            </Box>
          </Box>
        }
      >
        <>
          <Project3DCanvas
            data={model?.data}
            objects={model?.fileInfo.objects}
            highlights={highlights}
            ref={canvasRef}
            modelValidationCallbacks={{
              onFail: onModelValidationFail,
              onFailCleanup: onModelValidationFailCleanup,
            }}
            defaultSettings={!settings}
          />
          {!!settings && (
            <Project3DViewSettings
              onResetCamera={() => {
                if (canvasRef.current) {
                  canvasRef.current.resetCamera();
                }
              }}
              {...settings}
            />
          )}
        </>
      </Suspense>
    </Box>
  );
};
