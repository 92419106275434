import { forwardRef, ReactNode } from 'react';
import {
  ButtonBase,
  ButtonBaseProps,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
} from '@mui/material';

export interface Props {
  children: ReactNode;
  title: string;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  buttonProps?: Omit<ButtonBaseProps, 'sx'>;
  buttonSx?: SxProps<Theme>;
}

export const AppButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, title, tooltipProps, buttonProps, buttonSx }, ref) => {
    return (
      <Tooltip {...tooltipProps} title={title}>
        <span>
          <ButtonBase
            ref={ref}
            sx={{
              width: 30,
              height: 30,
              borderWidth: 2,
              borderRadius: ({ shape }) => `${shape.borderRadius}px`,
              borderColor: 'text.primary',
              borderStyle: 'solid',
              '&:disabled': {
                color: 'text.disabled',
                borderColor: 'text.disabled',
              },
              '&:hover, &:focus, &:active': {
                color: 'primary.light',
                borderColor: 'primary.light',
              },
              ...buttonSx,
            }}
            {...buttonProps}
          >
            {children}
          </ButtonBase>
        </span>
      </Tooltip>
    );
  },
);
AppButton.displayName = 'AppButton';
