import { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Slider, SliderProps, Stack } from '@mui/material';

import { useKeyboardShortcut, KeyboardShortcut } from 'hooks';

interface CustomProps {
  value: number;
  onChange: (v: number) => void;
  icons: Record<'increment' | 'decrement', ReactNode>;
  keyboardShortcuts: Record<'increment' | 'decrement', KeyboardShortcut>;
  min?: number;
  max?: number;
  step?: number;
}
type Props = CustomProps & Omit<SliderProps, keyof CustomProps>;

export const AppVerticalSlider = forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      onChange,
      icons,
      keyboardShortcuts,
      min = 1,
      max = 100,
      step = 1,
      ...componentProps
    },
    ref,
  ) => {
    const [state, setState] = useState<{ value: number; hasChanged: boolean }>({
      value,
      hasChanged: false,
    });

    const handleChange = useCallback<(e: Event, v: number | number[]) => void>(
      (_e, v) => {
        if (typeof v === 'number') {
          setState({ value: v, hasChanged: true });
        }
      },
      [setState],
    );

    const handleIncrement = useCallback<() => void>(() => {
      const result = value + step;
      if (result <= max) onChange(result);
    }, [max, onChange, step, value]);

    const handleDecrement = useCallback<() => void>(() => {
      const result = value - step;
      if (result >= min) onChange(result);
    }, [min, onChange, step, value]);

    useEffect(() => {
      const debounce = setTimeout(() => {
        if (state.hasChanged) {
          onChange(state.value);
          setState((prevState) => ({ ...prevState, hasChanged: false }));
        }
      }, 50);
      if (state.value !== value && !state.hasChanged) {
        setState({ value, hasChanged: false });
      }
      return () => {
        clearTimeout(debounce);
      };
    }, [onChange, state, value]);

    useKeyboardShortcut(keyboardShortcuts.increment, handleIncrement);
    useKeyboardShortcut(keyboardShortcuts.decrement, handleDecrement);

    return (
      <Stack ref={ref} minWidth={45} py={4} spacing={4} alignItems="center">
        <Button onClick={handleIncrement} color="inherit" sx={{ minWidth: 36 }}>
          {icons.increment}
        </Button>
        <Slider
          orientation="vertical"
          valueLabelDisplay="auto"
          value={state.value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          sx={{ flex: 1 }}
          {...componentProps}
        />
        <Button onClick={handleDecrement} color="inherit" sx={{ minWidth: 36 }}>
          {icons.decrement}
        </Button>
      </Stack>
    );
  },
);
AppVerticalSlider.displayName = 'AppVerticalSlider';
