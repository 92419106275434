import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import {
  TuneRounded as PartitionIcon,
  CheckRounded as SubmitIcon,
  CloseRounded as CloseIcon,
} from '@mui/icons-material';
import { v4 as uuid } from 'uuid';

import AppDialog, { AppConfirmationDialog } from 'components/AppDialog';
import { AppButtonForm, AppToggleButtonForm } from 'components/AppFormControl';
import ProjectTreeView from 'components/ProjectTreeView';
import { ModelFileInfo, Partition, SelectionType } from 'api';
// Redux
import { useSelector, useDispatch } from 'store';
import {
  selectSelection,
  selectPartitions,
  setSelection,
  setPartitions,
  setObjectMeltOrder,
  selectObjectMeltOrder,
} from 'slices/partitionSettingsSlice';
import { resetMeltSettings } from 'slices/meltSettingsSlice';

interface Props {
  modelInfo: ModelFileInfo | undefined;
  modelName: string | undefined;
  loading?: boolean;
  disabled?: boolean;
}

export const ProjectPartitionForm: FC<Props> = ({
  modelInfo,
  modelName,
  loading,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selection = useSelector(selectSelection);
  const partitions = useSelector(selectPartitions);
  const objectMeltOrder = useSelector(selectObjectMeltOrder);
  const [dialog, setDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [changeSelection, setChangeSelection] = useState<SelectionType | null>(
    null,
  );
  const [partitionsDraft, setPartitionsDraft] =
    useState<Partition[]>(partitions);
  const [selectionDraft, setSelectionDraft] =
    useState<SelectionType>(selection);
  const [objectMeltOrderDraft, setObjectMeltOrderDraft] =
    useState<number[]>(objectMeltOrder);

  const handleChangeSelection = useCallback<(value: SelectionType) => void>(
    (value) => {
      setSelectionDraft(value);
      if (value === 'model' && modelInfo) {
        setPartitionsDraft([
          {
            partitionID: uuid(),
            startZ: modelInfo.startZ,
            endZ: modelInfo.endZ,
            objectID: -1,
          },
        ]);
      }
      if (value === 'objects' && modelInfo) {
        setPartitionsDraft(
          modelInfo.objects.map(({ objectID, startZ, endZ }) => ({
            partitionID: uuid(),
            startZ,
            endZ,
            objectID,
          })),
        );
      }
      dispatch(resetMeltSettings());
      setChangeSelection(null);
    },
    [dispatch, modelInfo],
  );

  const handleAddPartition = useCallback<
    (breakpoint: [number, number], objectID?: number) => void
  >(
    (breakpoint, objectID) => {
      const index = partitionsDraft.findIndex(
        ({ startZ, endZ, objectID: parentId }) =>
          (objectID === undefined || parentId === objectID) &&
          startZ <= breakpoint[0] &&
          endZ >= breakpoint[1],
      );
      if (index >= 0) {
        const result = [...partitionsDraft];
        result.splice(
          index,
          1,
          { ...partitionsDraft[index], endZ: breakpoint[0] },
          {
            partitionID: uuid(),
            startZ: breakpoint[1],
            endZ: partitionsDraft[index].endZ,
            objectID: objectID ?? -1,
          },
        );
        setPartitionsDraft(result);
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleRemovePartition = useCallback<
    (id: string, effect: 'before' | 'after') => void
  >(
    (id, effect) => {
      if (partitionsDraft.length > 1) {
        const index = partitionsDraft.findIndex(
          ({ partitionID }) => partitionID === id,
        );
        if (index >= 0) {
          if (
            effect === 'before' &&
            index > 0 &&
            partitionsDraft[index].objectID ===
              partitionsDraft[index - 1].objectID
          ) {
            setPartitionsDraft((prevState) =>
              prevState
                .map((p, i) =>
                  i === index - 1
                    ? {
                        ...p,
                        endZ: partitionsDraft[index].endZ,
                      }
                    : p,
                )
                .filter((_p, i) => i !== index),
            );
          }
          if (
            effect === 'after' &&
            index + 1 < partitionsDraft.length &&
            partitionsDraft[index].objectID ===
              partitionsDraft[index + 1].objectID
          ) {
            setPartitionsDraft((prevState) =>
              prevState
                .map((p, i) =>
                  i === index + 1
                    ? {
                        ...p,
                        startZ: prevState[index].startZ,
                      }
                    : p,
                )
                .filter((_p, i) => i !== index),
            );
          }
        }
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleUpdatePartition = useCallback<
    (id: string, breakpoint: [number, number]) => void
  >(
    (id, breakpoint) => {
      const index = partitionsDraft.findIndex(
        ({ partitionID }) => partitionID === id,
      );
      if (index >= 0) {
        setPartitionsDraft((prevState) =>
          prevState.map((p, i) =>
            i === index ? { ...p, startZ: breakpoint[1] } : p,
          ),
        );
        if (
          partitionsDraft[index - 1]?.objectID ===
          partitionsDraft[index].objectID
        ) {
          setPartitionsDraft((prevState) =>
            prevState.map((p, i) =>
              i === index - 1
                ? {
                    ...p,
                    endZ: breakpoint[0],
                  }
                : p,
            ),
          );
        }
      }
    },
    [partitionsDraft, setPartitionsDraft],
  );

  const handleChangeObjectOrder = useCallback<
    (id: number, steps: number) => void
  >(
    (id, steps) => {
      const index: number = objectMeltOrderDraft.indexOf(id);
      if (index >= 0) {
        setObjectMeltOrderDraft((prevState) => {
          const result: number[] = prevState.slice();
          result.splice(index, 1);
          result.splice(index + steps, 0, id);
          return result;
        });
      }
    },
    [objectMeltOrderDraft],
  );

  const handleSubmit = useCallback<() => void>(() => {
    dispatch(setPartitions(partitionsDraft));
    dispatch(setObjectMeltOrder(objectMeltOrderDraft));
    if (selection !== selectionDraft) {
      dispatch(setSelection(selectionDraft));
    }
    setDialog(false);
  }, [
    dispatch,
    objectMeltOrderDraft,
    partitionsDraft,
    selection,
    selectionDraft,
  ]);

  const handleCancel = useCallback<() => void>(() => {
    handleChangeSelection(selection);
    setCancelDialog(false);
    setDialog(false);
  }, [handleChangeSelection, selection]);

  useEffect(() => {
    setPartitionsDraft(partitions);
  }, [partitions]);

  useEffect(() => {
    setSelectionDraft(selection);
  }, [selection]);

  useEffect(() => {
    setObjectMeltOrderDraft(objectMeltOrder);
  }, [objectMeltOrder]);

  return (
    <>
      <AppButtonForm
        label={t('partition')}
        helperText={t('partition_helper')}
        componentProps={{
          onClick: () => setDialog(true),
          startIcon: <PartitionIcon />,
          disabled: disabled || !modelInfo,
        }}
        loading={loading}
        loadingText={t('partition_helper_loading')}
        linearProgressProps={{ color: 'secondary', sx: { height: 36 } }}
      />
      <AppDialog
        open={dialog}
        onClose={() => setCancelDialog(true)}
        title={t('partition')}
        actions={
          <Stack direction="row" spacing={2} width={1}>
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<CloseIcon />}
              onClick={() => setCancelDialog(true)}
              fullWidth
              sx={{ flex: 1 }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SubmitIcon />}
              onClick={handleSubmit}
              disabled={
                partitionsDraft.every((v, i) => partitions[i] === v) &&
                objectMeltOrderDraft.every((v, i) => objectMeltOrder[i] === v)
              }
              fullWidth
              sx={{ flex: 2 }}
            >
              {t('apply')}
            </Button>
          </Stack>
        }
        dialogProps={{
          PaperProps: {
            sx: { height: 1, minHeight: '80vh', overflow: 'hidden' },
          },
          maxWidth: 'lg',
        }}
      >
        <Stack>
          <AppToggleButtonForm
            label={t('selection_type')}
            value={selectionDraft}
            onChange={(value) => {
              setChangeSelection(value as SelectionType);
            }}
            buttons={[
              { value: 'model', text: t('model') },
              { value: 'objects', text: t('objects') },
            ]}
            helperText={t('selection_type_helper')}
          />
          <ProjectTreeView
            modelInfo={modelInfo}
            modelName={modelName}
            partitions={partitionsDraft}
            selection={selectionDraft}
            objectMeltOrder={objectMeltOrderDraft}
            onAddPartition={handleAddPartition}
            onRemovePartition={handleRemovePartition}
            onUpdatePartition={handleUpdatePartition}
            onChangeObjectOrder={handleChangeObjectOrder}
          />
        </Stack>
      </AppDialog>
      <AppConfirmationDialog
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        title={t('partition_cancel')}
        text={t('partition_cancel_helper')}
        onConfirm={handleCancel}
      />
      <AppConfirmationDialog
        open={!!changeSelection}
        title={t('change_selection', {
          selection: changeSelection || '',
        })}
        text={t('change_selection_helper')}
        onClose={() => {
          setChangeSelection(null);
        }}
        onConfirm={() => {
          if (changeSelection) handleChangeSelection(changeSelection);
        }}
      />
    </>
  );
};
