import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { CreateOrganizationDialog } from 'containers/Organizations';
import AppTable, { TableColumn, TableData } from 'components/AppTable';
import AppOrganizationDetails from 'components/AppOrganizationDetails';
import { ErrorResponse, FilteredOrganizations, getOrganizations } from 'api';
import { useSnackbar, useSearchParams } from 'hooks';

const tableColumns: TableColumn[] = [{ name: 'name', label: 'name' }];

const isColumnName = (sortBy: string | undefined): sortBy is string =>
  tableColumns.some(({ name }) => name === sortBy);
const isDirection = (sortDir: string | undefined): sortDir is 'asc' | 'desc' =>
  sortDir === 'asc' || sortDir === 'desc';

export const Organizations: FC = () => {
  const { t } = useTranslation();
  const [
    { rowId: organizationId, pageSize, currentPage, sortBy, sortDir, search },
    { updateSearchParam, deleteSearchParam, deleteSearchParams },
  ] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [createOrganizationDialog, setCreateOrganizationDialog] =
    useState(false);

  const params = useMemo(
    () => ({
      size: pageSize ? +pageSize : 25,
      page: currentPage ? +currentPage : 1,
      sort:
        isColumnName(sortBy) && isDirection(sortDir)
          ? { columnName: sortBy, direction: sortDir }
          : null,
      search: search || undefined,
    }),
    [currentPage, pageSize, search, sortBy, sortDir],
  );

  const {
    data: organizations,
    refetch,
    dataUpdatedAt,
    isLoading,
    isRefetching,
    isError,
    isRefetchError,
  } = useQuery<FilteredOrganizations, AxiosError<ErrorResponse>>(
    ['organizations', params],
    () =>
      getOrganizations({
        top: params.size,
        skip: (params.page - 1) * params.size,
        orderBy: params.sort
          ? `${params.sort.columnName} ${params.sort.direction}`
          : undefined,
        filter: {
          'tolower(name)': params.search
            ? { contains: params.search.toLowerCase() }
            : undefined,
        },
      }),
    {
      keepPreviousData: true,
      staleTime: 0,
      onError: () => {
        enqueueSnackbar({
          key: `get_organizations_fail_${Date.now()}`,
          message: t('get_organizations_fail'),
          variant: 'error',
        });
      },
    },
  );

  const tableData = useMemo<TableData>(
    () => ({
      rows:
        (organizations?.values ?? []).map(({ id, name }) => ({
          id: id,
          hover: true,
          selected: organizationId === id,
          onClick: () => updateSearchParam(['rowId', id]),
          sx: { cursor: 'pointer' },
          values: [{ value: name }],
        })) ?? [],
    }),
    [organizations, organizationId, updateSearchParam],
  );

  useEffect(() => {
    if ((sortBy || sortDir) && (!isColumnName(sortBy) || !isDirection(sortDir)))
      deleteSearchParams(['sortBy', 'sortDir']);
  }, [deleteSearchParams, sortBy, sortDir]);

  return (
    <>
      <AppTable
        columns={tableColumns}
        data={tableData}
        name={t('organizations')}
        noDataText={
          isError || isRefetchError
            ? t('organizations_table_no_data_error')
            : t('organizations_table_no_data')
        }
        sx={{ height: 1, m: 2 }}
        tableProps={{ stickyHeader: true }}
        headerProps={{
          add: {
            tooltip: t('new_organization_helper'),
            onClick: () => setCreateOrganizationDialog(true),
          },
          search: {
            searchPhrase: params.search ?? '',
            onSearch: (value) =>
              value
                ? updateSearchParam(['search', value])
                : deleteSearchParam('search'),
          },
        }}
        pagination={{
          pageSize: params.size,
          currentPage: params.page,
          totalCount: organizations?.count,
        }}
        sort={params.sort}
        fetchDate={new Date(dataUpdatedAt)}
        isError={isError}
        isRefetchError={isRefetchError}
        isLoading={isLoading}
        isRefetching={isRefetching}
        refetchData={refetch}
      />
      <AppOrganizationDetails />
      <CreateOrganizationDialog
        open={createOrganizationDialog}
        onClose={() => setCreateOrganizationDialog(false)}
      />
    </>
  );
};
